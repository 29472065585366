import dayjs from "dayjs";
import Renting from "../../../models/Renting";
import { isBelow } from "../../../utils/Viewport";
import zigzag from "../../../assets/icons/zigzag.svg";
import zagzig from "../../../assets/icons/zagzig.svg";
import LoadingDots from "../../../components/LoadingDots";
import { rentingSerializer } from "../../../models/serializers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

const RentingRowDetail = ({ row }) => {
  const renting = row.hasOwnProperty("attributes") ? rentingSerializer(row) : row;
  const hasReservation = !!renting.reservation;

  if (hasReservation) {
    const timeUnit = renting.timeUnit;
    const reservation = renting.reservation;
    const resStart = dayjs(reservation.time.startRaw);
    const reservationStatus = renting.reservation.status;
    const resEnd = !!reservation.time.endRaw ? dayjs(reservation.time.endRaw) : null;
    const rentStart = !!renting.time.startRaw ? dayjs(renting.time.startRaw) : null;
    const rentEnd = !!renting.time.endRaw ? dayjs(renting.time.endRaw) : null;

    let isRentingCancelled;
    let isRentingFinished;

    // If reservation and renting has all start/end dates then we know if renting is cancelled or not
    // Cancelled renting is only when renting is finished and reservation end date, renting start date and renting end date are all equal
    // This happens only when the reservation expires
    if (!!resEnd && !!rentStart && !!rentEnd) {
      isRentingCancelled =
        rentStart.isSame(rentEnd) &&
        rentStart.isSame(resEnd) &&
        rentEnd.isSame(resEnd) &&
        renting.isFinished;

      isRentingFinished = !resStart.isSame(rentEnd) && renting.isFinished;
    }

    const resolveRideStatus = () => {
      if (isRentingCancelled) {
        return "Cancelled";
      } else if (isRentingFinished) {
        return "Finished";
      } else {
        return "Riding";
      }
    };

    const getReservationStatusColor = () => {
      switch (reservationStatus) {
        case Renting.RESERVATION_STATUS.COMPLETED:
          return "bg-green-400";
        case Renting.RESERVATION_STATUS.CANCELED:
          return "bg-red-500";
        case Renting.RESERVATION_STATUS.EXPIRED:
          return "bg-yellow-500";
        default:
          return "bg-gray-500";
      }
    };

    let resSpan = isBelow("LG") ? 30 : 20;
    let rideSpan = isBelow("LG") ? 70 : 80;

    return (
      <div className='res-wrapper'>
        <div className={`res-row ${isRentingCancelled ? "gap-1" : "gap-0"}`}>
          <div
            style={{ width: `${resSpan}%` }}
            className={`res-box ${getReservationStatusColor()} bg-opacity-60`}
          >
            <div className='timestamp-start'>{resStart.format("HH:mm")}</div>
            <div className='strikethrough'></div>
            <span className={`label ${!!resEnd && "-mt-4 py-2"}`}>
              Reservation
              {!!resEnd && (
                <span className='absolute text-xs left-1/2 -translate-x-1/2 -bottom-1'>
                  {reservation.status}
                </span>
              )}
            </span>
            <div className='strikethrough'></div>
            <div className='timestamp-end'>
              {!!resEnd ? (
                resEnd.format("HH:mm")
              ) : (
                <div className='flex items-end'>
                  Ongoing
                  <LoadingDots />
                </div>
              )}
            </div>
            <img src={zigzag} className='zigzag' />
            {!!resEnd && !!rentStart && (
              <FontAwesomeIcon
                icon={isRentingCancelled ? faXmark : faArrowsRotate}
                className={`absolute z-10 text-gray-400 opacity-60 ${
                  !isRentingCancelled
                    ? (isBelow("MD") ? "-right-[15px]" : "-right-[25px]") + " half-icon"
                    : isBelow("MD")
                    ? "-right-[14px]"
                    : "-right-[19px]"
                }`}
                size={`${isBelow("MD") ? "2x" : "3x"}`}
              />
            )}
          </div>
          {!!resEnd && (
            <div
              style={{ width: `${rideSpan}%` }}
              className={`ride-box bg-opacity-70 ${
                !!timeUnit && Renting.getTimeUnitColor(timeUnit)
              }`}
            >
              <img src={zagzig} className='zagzig' />
              <div className='timestamp-start'>
                {!!rentStart ? rentStart.format("HH:mm") : "Not started yet"}
              </div>
              <div className='strikethrough'></div>
              <span className='label'>{resolveRideStatus()}</span>
              <div className='strikethrough'></div>
              <div className='timestamp-end'>
                {!!rentEnd ? (
                  rentEnd.format("HH:mm")
                ) : (
                  <div className='flex items-end'>
                    Ongoing
                    <LoadingDots />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default RentingRowDetail;
