import { useEffect, useState } from "react";
import Filters from "../components/Filters";
import CareModal from "../components/CareModal";
import CaresTable from "../components/CaresTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";

const CaresPageView = ({
  carId,
  cares,
  filters,
  sorting,
  isLoading,
  pagination,
  handleDelete,
  isLoadingCare,
  totalElements,
  handleSorting,
  isExternalPage,
  handlePageChange,
  handleSubmitCare,
  handleClearFilters,
  handleFilterChange,
  handlePageSizeChange,
}) => {
  const [editingCare, setEditingCare] = useState(null);
  const [isCareModalOpen, toggleCareModal] = useState(false);

  const handleEdit = (row) => {
    setEditingCare(row);
    toggleCareModal(true);
  };

  return (
    <div className='page-wrapper'>
      <div className={`page-content ${isExternalPage && "is-inner"}`}>
        {!isExternalPage && (
          <>
            <div className='page-title'>
              <span className='title'>CARES</span>
            </div>
            <Filters
              filters={filters}
              isLoading={isLoading}
              handleFilterChange={handleFilterChange}
              handleClearFilters={handleClearFilters}
            />
          </>
        )}
        <div className='table-wrapper'>
          {isExternalPage && (
            <div className='external-header'>
              <div className='title-inner'>
                <span>Cares</span>
              </div>
              <div
                className='flex items-center gap-1 text-text-main cursor-pointer'
                onClick={() => toggleCareModal(true)}
              >
                <FontAwesomeIcon icon={faSquarePlus} />
                <span className='text-sm sm:text-base'>Add Care</span>
              </div>
            </div>
          )}
          <CaresTable
            cares={cares}
            filters={filters}
            sorting={sorting}
            isLoading={isLoading}
            pagination={pagination}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleSorting={handleSorting}
            totalElements={totalElements}
            isExternalPage={isExternalPage}
            handlePageChange={handlePageChange}
            handleFilterChange={handleFilterChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
      <CareModal
        carId={carId}
        isOpen={isCareModalOpen}
        editingCare={editingCare}
        isLoading={isLoadingCare}
        toggleModal={toggleCareModal}
        setEditingCare={setEditingCare}
        handleSubmitCare={handleSubmitCare}
      />
    </div>
  );
};

export default CaresPageView;
