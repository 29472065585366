import Renting from "../../../models/Renting";

const RentingTimeUnitTag = ({ timeUnit }) => {
  return (
    <div className={`px-1 py-0 bg-opacity-70 rounded ${Renting.getTimeUnitColor(timeUnit)}`}>
      <span className='text-xs text-white font-bold tracking-tighter'>
        {Renting.getTimeUnitLabel(timeUnit)}
      </span>
    </div>
  );
};

export default RentingTimeUnitTag;
