import Utils from "../../../utils/Utils";
import Maintenance from "../../../models/Maintenance";
import ActiveStatusBlinker from "../../../components/ActiveStatusBlinker";

const Status = ({ status }) => {
  const getColor = () => {
    switch (status) {
      case Maintenance.STATUS.STARTED:
        return "bg-blue-500";
      case Maintenance.STATUS.CANCELLED:
        return "bg-red-500";
      case Maintenance.STATUS.FINISHED:
        return "bg-green-500";
      default:
        return "bg-gray-500";
    }
  };

  return (
    <>
      <div className='block'>
        <span
          className={`rounded text-white font-bold p-1 sm:p-1.5 ${getColor()} flex items-center gap-0.5 w-max mx-auto`}
        >
          {status === Maintenance.STATUS.STARTED && <ActiveStatusBlinker color='white' />}
          {Utils.textFirstOnlyUpper(status)}
        </span>
      </div>
    </>
  );
};

export default Status;
