import { useState } from "react";
import Filters from "../components/Filters";
import InvoicesTable from "../components/InvoicesTable";
import IssueInvoiceModal from "../components/IssueInvoiceModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";

const InvoicesPageView = ({
  userId,
  admins,
  filters,
  sorting,
  isOpened,
  invoices,
  isLoading,
  pagination,
  handleVoid,
  getInvoices,
  totalElements,
  handleSorting,
  isExternalPage,
  handlePageChange,
  handleFilterChange,
  handleClearFilters,
  handleRetryPayment,
  handlePageSizeChange,
  handleMarkUncollectible,
}) => {
  const [isInvoiceModalOpen, toggleInvoiceModal] = useState(false);

  return (
    <div className='page-wrapper'>
      <div className='page-content'>
        {!isExternalPage && (
          <div className='page-title'>
            <span className='title'>INVOICES</span>
          </div>
        )}
        <Filters
          admins={admins}
          filters={filters}
          isOpened={isOpened}
          isLoading={isLoading}
          isExternalPage={isExternalPage}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
        />
        <div className='table-wrapper'>
          <div className='external-header'>
            <div className='title-inner'>
              <span>Invoices</span>
            </div>
            {isExternalPage && (
              <div
                onClick={() => toggleInvoiceModal(true)}
                className='flex items-center gap-1 text-text-main cursor-pointer'
              >
                <FontAwesomeIcon icon={faSquarePlus} />
                <span className='text-sm sm:text-base'>Issue Invoice</span>
              </div>
            )}
          </div>
          <InvoicesTable
            sorting={sorting}
            invoices={invoices}
            isLoading={isLoading}
            pagination={pagination}
            handleVoid={handleVoid}
            totalElements={totalElements}
            handleSorting={handleSorting}
            handlePageChange={handlePageChange}
            handleRetryPayment={handleRetryPayment}
            handlePageSizeChange={handlePageSizeChange}
            handleMarkUncollectible={handleMarkUncollectible}
          />
        </div>
      </div>
      <IssueInvoiceModal
        userId={userId}
        getInvoices={getInvoices}
        isOpen={isInvoiceModalOpen}
        toggleModal={toggleInvoiceModal}
      />
    </div>
  );
};

export default InvoicesPageView;
