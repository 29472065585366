import User from "../../models/User";
import CaresPageState from "./state/CaresPageState";
import Authorizer from "../../components/Authorizer";

const CaresPage = (props) => {
  return (
    <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
      <CaresPageState {...props} />
    </Authorizer>
  );
};

export default CaresPage;
