import dayjs from "dayjs";
import Parse from "parse";
import { useFormik } from "formik";
import "../../../styles/global.css";
import Car from "../../../models/Car";
import Utils from "../../../utils/Utils";
import { useEffect, useMemo } from "react";
import Modal from "../../../components/Modal";
import { date, lazy, object, string } from "yup";
import { findOption } from "../../../utils/Forms";
import useToaster from "../../../hooks/useToaster";
import Button from "../../../components/form/Button";
import FormSelect from "../../../components/form/FormSelect";
import FormTextarea from "../../../components/form/FormTextarea";
import FormDatepicker from "../../../components/form/FormDatepicker";

const CareModal = ({
  carId,
  isOpen,
  isLoading,
  editingCare,
  toggleModal,
  setEditingCare,
  handleSubmitCare,
}) => {
  const toaster = useToaster();

  const validationSchema = object().shape({
    type: string().required("Type is required"),
    status: string().required("Status is required"),
    details: string().required("Details is required"),
    comments: string().nullable(),
    completedAt: lazy((value, context) => {
      return context.parent.status === Car.CARE_STATUS.COMPLETED
        ? date().required("Completed at is required")
        : date().nullable();
    }),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      car: null,
      type: null,
      details: "",
      comments: "",
      status: null,
      completedAt: null,
    },
  });

  const carDetails = useMemo(() => {
    const car = !!carId ? new Parse.Object("Car").set("objectId", carId) : null;

    return {
      carPlate: !!car ? car.attributes.carPlate : editingCare?.attributes.car.attributes.carPlate,
      brand: !!car ? car.attributes.brand : editingCare?.attributes.car.attributes.brand,
      model: !!car ? car.attributes.model : editingCare?.attributes.car.attributes.model,
    };
  }, [editingCare]);

  const initializeForm = useMemo(() => {
    const car = !!carId
      ? {
          __type: "Pointer",
          className: "Car",
          objectId: carId,
        }
      : !!editingCare
      ? {
          __type: "Pointer",
          className: "Car",
          objectId: editingCare?.attributes?.car?.id,
        }
      : null;
    return {
      car: car,
      type: !!editingCare ? String(editingCare.attributes.type).replace(/\s/g, "_") : null,
      status: !!editingCare ? editingCare.attributes.status : null,
      details: !!editingCare ? editingCare.attributes.details : null,
      completedAt: !!editingCare?.attributes?.completedAt
        ? editingCare.attributes.completedAt
        : null,
    };
  }, [editingCare]);

  const typeOptions = Object.keys(Car.CARE_TYPES).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(Utils.underscoreToSpace(t)),
  }));
  const typeDefaultOption = !Utils.isNull(formik.values.type)
    ? findOption(typeOptions, String(formik.values.type)?.toUpperCase())
    : null;

  const statusOptions = Object.keys(Car.CARE_STATUS).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(Utils.underscoreToSpace(t)),
  }));
  const statusDefaultOption = !Utils.isNull(formik.values.status)
    ? findOption(statusOptions, String(formik.values.status)?.toUpperCase())
    : null;

  const handleFormChange = (field, value) => {
    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  };

  const handleSubmit = async () => {
    Object.keys(formik.values).forEach((key) => formik.setFieldTouched(key));

    if (!formik.isValid) {
      toaster.error("Please fill all required fields.");
    } else {
      await handleSubmitCare(formik.values, editingCare?.id ?? null).then((r) => {
        if (r) {
          setTimeout(() => {
            formik.resetForm();
            toggleModal(false);
            setEditingCare(null);
            formik.validateForm();
          }, 1500);
        }
      });
    }
  };

  const handleCancel = () => {
    toggleModal(false);
    formik.resetForm();
    setEditingCare(null);
    formik.validateForm();
  };

  useEffect(() => {
    formik.setValues(initializeForm);
  }, [editingCare]);

  useEffect(() => {
    formik.validateForm();
  }, []);

  return (
    <Modal
      open={isOpen}
      title={`Car Care for ${carDetails?.carPlate} - ${carDetails?.brand} ${carDetails?.model}`}
      classes={"w-11/12 md:w-1/2 lg:w-3/5 xl:w-1/2"}
      handleToggle={() => toggleModal(!isOpen)}
    >
      <div className='grid grid-cols-2 gap-1 items-start'>
        <div className='col-span-1'>
          <FormSelect
            label='Type'
            options={typeOptions}
            disabled={!!editingCare}
            value={typeDefaultOption}
            errors={formik.touched.type && formik.errors.type}
            handleChange={(value) =>
              handleFormChange("type", String(Utils.spaceToUnderscore(value.label)).toUpperCase())
            }
          />
        </div>
        <div className='col-span-1'>
          <FormSelect
            label='Status'
            options={statusOptions}
            value={statusDefaultOption}
            errors={formik.touched.status && formik.errors.status}
            handleChange={(value) => handleFormChange("status", value.value)}
          />
        </div>
        <div className='col-span-2 mt-2'>
          <FormTextarea
            label='Details'
            value={formik.values.details}
            disabled={!carId}
            placeholder={"Add specific details about the care"}
            errors={formik.touched.details && formik.errors.details}
            handleChange={(value) => handleFormChange("details", value)}
          />
        </div>
        <div className='col-span-2 mt-2'>
          <FormTextarea
            label='Comments'
            value={formik.values.comments}
            placeholder={"Add more comments if needed"}
            handleChange={(value) => handleFormChange("comments", value)}
          />
        </div>
        {formik.values.status === Car.CARE_STATUS.COMPLETED && (
          <div className='col-span-2'>
            <FormDatepicker
              label='Completed At'
              value={
                formik.values.completedAt
                  ? dayjs(formik.values.completedAt).startOf("day").add(2, "hour").toDate()
                  : null
              }
              handleChange={(date) => handleFormChange("completedAt", date)}
              errors={formik.touched.completedAt && formik.errors.completedAt}
            />
          </div>
        )}

        <div className='col-span-2 grid grid-cols-2 gap-2 mt-2 sm:mt-4'>
          <Button classes='bg-gray-200 py-2' isLoading={isLoading} handleClick={handleCancel}>
            <span>Cancel</span>
          </Button>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            handleClick={handleSubmit}
            classes='bg-blue-500 py-2 pr-2'
          >
            <span className='text-white'>{!!editingCare ? "Save" : "Add"}</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CareModal;
