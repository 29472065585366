import { useState } from "react";
import Car from "../../../models/Car";
import { Link } from "react-router-dom";
import Utils from "../../../utils/Utils";
import Loader from "../../../components/Loader";
import { isAbove, isBelow } from "../../../utils/Viewport";
import { dateFormatter } from "../../../utils/Dates";
import { faChevronDown, faChevronUp, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PagingState, SortingState, CustomPaging, RowDetailState } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  PagingPanel,
  TableHeaderRow,
  TableRowDetail,
} from "@devexpress/dx-react-grid-material-ui";

const UsersTable = ({
  cares,
  sorting,
  isLoading,
  pagination,
  handleEdit,
  totalElements,
  handleSorting,
  isExternalPage,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const sortableColumns = ["communicationDate"];
  const [expandedRows, setExpandedRows] = useState([]);

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props} className='sortable-column-label'>
          {props.children}
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold'>{props.column.title}</span>;
    }
  };

  const resolveCarPlate = (data) => {
    return (
      <div className='flex items-center gap-2'>
        <Link className='link' to={`/cars/${data.attributes.car.id}`}>
          <span className='font-semibold'>{data?.attributes?.car?.attributes?.carPlate}</span>
        </Link>
      </div>
    );
  };

  const resolveType = (row) => {
    const type = row.attributes.type;

    return <span>{Utils.underscoreToSpace(type)}</span>;
  };

  const resolveStatus = (row) => {
    const status = row.attributes.status;
    const color = Car.getCareStatusColor(status);

    return (
      <div className={`inline-flex px-1 py-0.5 rounded ${color}`}>
        <span className='text-text-main font-bold'>{Utils.underscoreToSpace(status)}</span>
      </div>
    );
  };

  const resolveDetails = (row) => {
    return (
      <div className='inline-flex text-white px-1 rounded'>
        <span>{row.attributes.details}</span>
      </div>
    );
  };

  const resolveMileage = (row) => {
    return (
      <div>
        <span>{row.attributes.mileage}</span>
      </div>
    );
  };

  const resolveCreatedAt = (data) => {
    const isCompleted = !!data.attributes.completedAt;
    return (
      <div>
        <div>
          {isBelow("SM") && <span className='font-bold mr-1'>Created: </span>}
          {dateFormatter(data.attributes.createdAt, false)}
        </div>
        {isCompleted && (
          <div className='mt-1'>
            <span className='font-bold'>Completed:</span>{" "}
            {dateFormatter(data.attributes.completedAt, false)}
          </div>
        )}
      </div>
    );
  };

  const resolveControls = (data) => {
    return (
      <div>
        <FontAwesomeIcon
          icon={faPencil}
          onClick={() => handleEdit(data)}
          className='text-primary cursor-pointer'
        />
      </div>
    );
  };

  const resolveColumns = () => {
    if (isBelow("SM")) {
      return [
        {
          name: "care",
          title: "Care",
          getCellValue: resolveCarPlate,
        },
      ];
    } else if (isAbove("SM") && isBelow("MD")) {
      return [
        {
          name: "carPlate",
          title: "Plate",
          getCellValue: resolveCarPlate,
        },
        {
          name: "type",
          title: "Type",
          getCellValue: resolveType,
        },
        {
          name: "status",
          title: "Status",
          getCellValue: resolveStatus,
        },
        {
          name: "createdAt",
          title: "Created At",
          getCellValue: resolveCreatedAt,
        },
        {
          name: "controls",
          title: "",
          getCellValue: resolveControls,
        },
      ];
    } else if (isAbove("MD") && isBelow("LG")) {
      return [
        {
          name: "carPlate",
          title: "Plate",
          getCellValue: resolveCarPlate,
        },
        {
          name: "type",
          title: "Type",
          getCellValue: resolveType,
        },
        {
          name: "status",
          title: "Status",
          getCellValue: resolveStatus,
        },
        {
          name: "mileage",
          title: "Mileage",
          getCellValue: resolveMileage,
        },
        {
          name: "createdAt",
          title: "Created At",
          getCellValue: resolveCreatedAt,
        },
        {
          name: "controls",
          title: "",
          getCellValue: resolveControls,
        },
      ];
    } else {
      return [
        {
          name: "carPlate",
          title: "Plate",
          getCellValue: resolveCarPlate,
        },
        {
          name: "type",
          title: "Type",
          getCellValue: resolveType,
        },
        {
          name: "status",
          title: "Status",
          getCellValue: resolveStatus,
        },
        {
          name: "details",
          title: "Details",
          getCellValue: resolveDetails,
        },
        {
          name: "mileage",
          title: "Mileage",
          getCellValue: resolveMileage,
        },
        {
          name: "createdAt",
          title: "Created At",
          getCellValue: resolveCreatedAt,
        },
        {
          name: "controls",
          title: "",
          getCellValue: resolveControls,
        },
      ];
    }
  };

  const columns = resolveColumns();

  const rowDetail = (data) => {
    const row = data.row;
    const hasComments = !!row.attributes.comments;

    return (
      <>
        <div>
          <div className='mb-0.5'>
            <span className='font-bold'>Details</span>
          </div>
          <div>{row.attributes.details}</div>
        </div>
        {hasComments && (
          <div className='mt-3'>
            <div className='mb-0.5'>
              <span className='font-bold'>Comments</span>
            </div>
            <div>{row.attributes.comments}</div>
          </div>
        )}
      </>
    );
  };

  const cellComponent = (props) => {
    const care = props.row;
    if (isBelow("SM")) {
      return (
        <Table.Cell {...props}>
          <div className='flex items-center gap-2'>
            {resolveCarPlate(care)} {resolveType(care)} {resolveStatus(care)}
          </div>
          <div>{resolveCreatedAt(care)}</div>
        </Table.Cell>
      );
    } else {
      return <Table.Cell {...props} />;
    }
  };

  const toggleCellComponent = ({ expanded, onToggle }) => {
    return isBelow("LG") ? (
      <td className='MuiTableCell body cursor-pointer' onClick={() => onToggle(!expanded)}>
        <div className='flex items-center justify-center'>
          <FontAwesomeIcon
            size='sm'
            className='text-text-main'
            icon={expanded ? faChevronUp : faChevronDown}
          />
        </div>
      </td>
    ) : (
      <td className='MuiTableCell body'></td>
    );
  };

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <div className='z-10'>
        <div className='user-messages-table'>
          <Grid rows={cares} columns={columns} getRowId={(row) => row.id}>
            <SortingState sorting={sorting} onSortingChange={handleSorting} />
            <RowDetailState
              expandedRowIds={expandedRows}
              onExpandedRowIdsChange={setExpandedRows}
            />
            <PagingState
              pageSize={pagination.size}
              currentPage={pagination.page}
              onCurrentPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
            <CustomPaging totalCount={totalElements} />
            <Table
              columnExtensions={[
                {
                  width: "120",
                  wordWrapEnabled: true,
                  columnName: "carPlate",
                },
                {
                  width: "150",
                  wordWrapEnabled: true,
                  columnName: "type",
                },
                {
                  width: "120",
                  wordWrapEnabled: true,
                  columnName: "status",
                },
                {
                  width: "auto",
                  wordWrapEnabled: true,
                  columnName: "details",
                },
                {
                  width: "120",
                  wordWrapEnabled: true,
                  columnName: "mileage",
                },
                {
                  width: "110",
                  wordWrapEnabled: true,
                  columnName: "createdAt",
                },
                {
                  width: "40",
                  align: "center",
                  wordWrapEnabled: true,
                  columnName: "controls",
                },
              ]}
              cellComponent={cellComponent}
            />
            <TableHeaderRow
              sortLabelComponent={TableHeaderRowSortLabelComponent}
              showSortingControls={true}
            />
            <TableRowDetail
              contentComponent={rowDetail}
              toggleCellComponent={toggleCellComponent}
              toggleColumnWidth={isBelow("LG") ? 20 : 0}
            />
            <PagingPanel pageSizes={[...(isExternalPage ? [3, 5] : []), 10, 25, 50, 100]} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default UsersTable;
