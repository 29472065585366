import Parse from "parse";
import { useFormik } from "formik";
import "../../../styles/global.css";
import { useEffect, useState } from "react";
import { number, object, string } from "yup";
import Modal from "../../../components/Modal";
import Card from "../../../components/stripe/Card";
import Button from "../../../components/form/Button";
import FormInput from "../../../components/form/FormInput";

const ChargeRentingModal = ({ isOpen, renting, toggleModal, handleCharge }) => {
  const [isLoading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const validationSchema = object().shape({
    amount: number().required("Amount is required"),
    paymentMethod: string().required("Payment Method is required"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      amount: Number(parseFloat(renting.totalBill).toFixed(2)),
      paymentMethod: null,
    },
  });

  const getUserPaymentMethods = async () => {
    const PaymentMethods = await new Parse.Query("PaymentMethod")
      .equalTo("user", {
        __type: "Pointer",
        className: "_User",
        objectId: renting.driver.id,
      })
      .findAll({ useMasterKey: true });
    setPaymentMethods(PaymentMethods);
    handleFormChange("paymentMethod", PaymentMethods[0].attributes.stripeId);
  };

  const handleFormChange = (field, value) => {
    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  };

  const handleSelectPaymentMethod = (pm) => {
    handleFormChange("paymentMethod", pm.attributes.stripeId);
  };

  const handleChargeLocal = () => {
    Object.keys(formik.values).forEach((key) => formik.setFieldTouched(key));

    if (formik.isValid) {
      setLoading(true);
      handleCharge(formik.values.paymentMethod)
        .then((r) => {
          if (r) {
            toggleModal(false);
            formik.resetForm();
            formik.validateForm();
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleCancel = () => {
    toggleModal(false);
    formik.resetForm();
    formik.validateForm();
  };

  useEffect(() => {
    formik.validateForm();
    getUserPaymentMethods();
    handleFormChange("amount", Number(parseFloat(renting.totalBill).toFixed(2)));
  }, [renting]);

  return (
    <Modal
      open={isOpen}
      title={`Complete Payment`}
      classes={"w-11/12 md:w-1/2 lg:w-3/5 xl:w-1/2"}
      handleToggle={() => toggleModal(!isOpen)}
    >
      <div>
        <div>
          <FormInput
            disabled
            type='text'
            label='Amount'
            value={formik.values.amount}
            errors={formik.touched.amount && formik.errors.amount}
            handleChange={(e) => handleFormChange("amount", e.target.value)}
          />
        </div>
        <div className='mt-4'>
          <div className='text-text-main'>
            <span>Select one of the user's payment method to apply the charge on</span>
          </div>
          <div className='mt-2 flex flex-col gap-2'>
            {paymentMethods.map((pm) => {
              return (
                <div className={`text-text-main rounded-lg hover:bg-opacity-70`}>
                  <Card
                    card={pm}
                    key={pm.id}
                    handleClick={handleSelectPaymentMethod}
                    isSelected={formik.values.paymentMethod === pm.attributes.stripeId}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className='col-span-2 grid grid-cols-2 gap-2 mt-2 sm:mt-4'>
          <Button classes='bg-gray-200 py-2' isLoading={isLoading} handleClick={handleCancel}>
            <span>Cancel</span>
          </Button>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            handleClick={() => handleChargeLocal()}
            classes='bg-blue-500 py-2 pr-2'
          >
            <span className='text-white'>Charge</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChargeRentingModal;
