import dayjs from "dayjs";
import Parse from "parse";
import { useRef } from "react";
import { debounce } from "lodash";
import Utils from "../../../utils/Utils";
import Invoice from "../../../models/Invoice";
import { findOption } from "../../../utils/Forms";
import FormSelect from "../../../components/form/FormSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDatepicker from "../../../components/form/FormDatepicker";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { faFilter, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

const Filters = ({
  admins,
  filters,
  isExternalPage,
  isOpened = false,
  handleFilterChange,
  handleClearFilters,
}) => {
  const usernameRef = useRef(null);

  const adminOptions = admins.map((adm) => ({
    value: adm,
    label: adm,
  }));
  const adminDefault = !Utils.isNull(filters?.admin)
    ? findOption(adminOptions, filters?.admin)
    : null;

  const statusOptions = Object.keys(Invoice.STATUS).map((s) => ({
    value: s,
    label: Utils.textFirstOnlyUpper(s),
  }));
  const statusDefault = !Utils.isNull(filters?.status)
    ? findOption(statusOptions, String(filters?.status)?.toUpperCase())
    : null;

  const typeOptions = Object.keys(Invoice.TYPE).map((s) => ({
    value: s,
    label: Utils.textFirstOnlyUpper(s),
  }));
  const typeDefault = !Utils.isNull(filters?.type)
    ? findOption(typeOptions, String(filters?.type)?.toUpperCase())
    : null;

  const collectionMethodOptions = Object.keys(Invoice.COLLECTION_METHOD).map((s) => ({
    value: s,
    label: Utils.textFirstOnlyUpper(s),
  }));
  const collectionMethodDefault = !Utils.isNull(filters?.collectionMethod)
    ? findOption(
        collectionMethodOptions,
        Utils.spaceToUnderscore(String(filters?.collectionMethod)?.toUpperCase())
      )
    : null;

  const dueFrom = filters?.dueFrom ? dayjs(filters?.dueFrom) : null;
  const dueTo = filters?.dueTo ? dayjs(filters?.dueTo) : null;
  const closedFrom = filters?.closedFrom ? dayjs(filters?.closedFrom) : null;
  const closedTo = filters?.closedTo ? dayjs(filters?.closedTo) : null;
  const from = filters?.from ? dayjs(filters?.from) : null;
  const to = filters?.to ? dayjs(filters?.to) : null;

  const handleDateChange = (field, value) => {
    if (Utils.isNull(value)) {
      handleFilterChange(field, null);
      return;
    }

    const v = new Date(value);

    field.includes("from") || field.includes("From") ? v.setHours(0, 0, 0) : v.setHours(23, 59, 59);
    handleFilterChange(field, v);
  };

  const getUsersByUsername = async (value) => {
    return (
      await new Parse.Query("_User").matches("username", value, "i").find({ useMasterKey: true })
    ).map((u) => ({
      value: u.id,
      label: u.attributes.username,
    }));
  };

  const debouncedFetchOptions = debounce((value, callback) => {
    getUsersByUsername(value).then(callback);
  }, 300);

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      debouncedFetchOptions(inputValue, resolve);
    });

  const handleClear = (e) => {
    if (!isExternalPage) usernameRef.current.clearValue();
    handleClearFilters(e);
  };

  return (
    <div className='filters'>
      <Accordion defaultExpanded={isOpened}>
        <AccordionSummary>
          <div className='title'>
            <div className='flex items-center flex-row gap-2'>
              <FontAwesomeIcon icon={faFilter} />
              Filters
            </div>
            <div className='filter-actions'>
              <div className='flex items-center gap-2 z-20' onClick={handleClear}>
                <FontAwesomeIcon icon={faFilterCircleXmark} />
                Clear Filters
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div
            className={`grid grid-cols-1 md:grid-cols-2 gap-2 ${
              isExternalPage ? "lg:grid-cols-4" : "lg:grid-cols-5"
            }`}
          >
            <FormSelect
              label='Admin'
              classes='w-full'
              isClearable={true}
              options={adminOptions}
              value={adminDefault}
              placeholder='Select Admin'
              handleChange={(option) => handleFilterChange("admin", option)}
            />
            {!isExternalPage && (
              <FormSelect
                cacheOptions
                defaultOptions
                isAsync={true}
                label='Username'
                ref={usernameRef}
                isClearable={true}
                promiseOptions={(value) => promiseOptions(value)}
                handleChange={(value) => handleFilterChange("user", value?.value)}
              />
            )}
            <FormSelect
              label='Status'
              classes='w-full'
              isClearable={true}
              options={statusOptions}
              value={statusDefault}
              placeholder='Status'
              handleChange={(option) => handleFilterChange("status", option)}
            />
            <FormSelect
              label='Type'
              classes='w-full'
              placeholder='Type'
              isClearable={true}
              value={typeDefault}
              options={typeOptions}
              handleChange={(option) => handleFilterChange("type", option)}
            />
            <FormSelect
              classes='w-full'
              isClearable={true}
              label='Collection Method'
              placeholder='Collection Method'
              value={collectionMethodDefault}
              options={collectionMethodOptions}
              handleChange={(option) => handleFilterChange("collectionMethod", option)}
            />
            <FormDatepicker
              label='From'
              value={from}
              isClearable={true}
              handleChange={(value) => handleDateChange("from", value)}
            />
            <FormDatepicker
              label='To'
              value={to}
              isClearable={true}
              handleChange={(value) => handleDateChange("to", value)}
            />
            <FormDatepicker
              value={closedFrom}
              isClearable={true}
              label='Closed From'
              handleChange={(value) => handleDateChange("closedFrom", value)}
            />
            <FormDatepicker
              value={closedTo}
              label='Closed To'
              isClearable={true}
              handleChange={(value) => handleDateChange("closedTo", value)}
            />
            <FormDatepicker
              value={dueFrom}
              isClearable={true}
              label='Due From'
              handleChange={(value) => handleDateChange("dueFrom", value)}
            />
            <FormDatepicker
              value={dueTo}
              label='Due To'
              isClearable={true}
              handleChange={(value) => handleDateChange("dueTo", value)}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Filters;
