export default class Invoice {
  static TYPE = {
    FINE: "FINE",
    DAMAGE: "DAMAGE",
    PARKING: "PARKING",
    CLEANING: "CLEANING",
  };

  static STATUS = {
    PAID: "PAID",
    FAILED: "FAILED",
    PENDING: "PENDING",
    CANCELLED: "CANCELLED",
    UNCOLLECTIBLE: "UNCOLLECTIBLE",
  };

  static COLLECTION_METHOD = {
    SEND_INVOICE: "SEND INVOICE",
    CHARGE_AUTOMATICALLY: "CHARGE AUTOMATICALLY",
  };
}
