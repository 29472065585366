import { Tooltip } from "@mui/material";
import Filters from "../components/Filters";
import RentingsTable from "../components/RentingsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { secondsToDaysHoursMinutes } from "../../../utils/Dates";
import { faCircleNotch, faEuro, faMinus } from "@fortawesome/free-solid-svg-icons";

const RentingsPageView = ({
  totals,
  sorting,
  filters,
  fromCar,
  fromUser,
  rentings,
  isLoading,
  pagination,
  totalElements,
  handleSorting,
  isExternalPage,
  isLoadingTotals,
  handleExportData,
  handleAddRenting,
  handlePageChange,
  handleFilterChange,
  handleClearFilters,
  handleApplyFilters,
  handleSetIsReviewed,
  handlePageSizeChange,
}) => {
  return (
    <div className='page-wrapper'>
      <div className={`page-content ${isExternalPage && "is-inner"}`}>
        {!isExternalPage && (
          <div className='page-title'>
            <span className='title'>RENTINGS</span>
          </div>
        )}
        <Filters
          filters={filters}
          fromCar={fromCar}
          fromUser={fromUser}
          isLoading={isLoading}
          isLoadingTotals={isLoadingTotals}
          handleExportData={handleExportData}
          handleAddRenting={handleAddRenting}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          handleApplyFilters={handleApplyFilters}
        />
        {!isExternalPage && (
          <div className='page-totals'>
            <div className='relative'>
              <span>{!!totals.dailyRentings ? "Daily Rentings" : "Rentings"}</span>
              {isLoadingTotals ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  className='animate-spin mt-4 opacity-50'
                  size='lg'
                />
              ) : (
                <span>
                  {!!totals.dailyRentings ? (
                    totals.dailyRentings
                  ) : !!totals.rentings ? (
                    totals.rentings
                  ) : (
                    <FontAwesomeIcon icon={faMinus} />
                  )}
                </span>
              )}
            </div>
            <div>
              <span>{!!totals.dailyTimeSpent ? "Daily Time Spent" : "Time Spent"}</span>
              {isLoadingTotals ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  className='animate-spin mt-4 opacity-50'
                  size='lg'
                />
              ) : (
                <span>
                  {!!totals.dailyTimeSpent ? (
                    secondsToDaysHoursMinutes(totals.dailyTimeSpent)
                  ) : !!totals.timeSpent ? (
                    secondsToDaysHoursMinutes(totals.timeSpent)
                  ) : (
                    <FontAwesomeIcon icon={faMinus} />
                  )}
                </span>
              )}
            </div>
            <div>
              <span>{!!totals.dailyRevenue ? "Daily Revenue" : "Revenue"}</span>
              {isLoadingTotals ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  className='animate-spin mt-4 opacity-50'
                  size='lg'
                />
              ) : (
                <>
                  {!!totals.dailyRevenue ? (
                    <>
                      <span>
                        {parseFloat(totals.dailyRevenue.discounted).toFixed(2)}{" "}
                        <FontAwesomeIcon icon={faEuro} />
                      </span>
                      <Tooltip
                        title={`Discounted amount: ${parseFloat(
                          totals?.dailyRevenue?.raw - totals?.dailyRevenue?.discounted
                        ).toFixed(2)}€`}
                      >
                        <span>
                          {!isLoadingTotals && !!totals.dailyRevenue.raw && (
                            <span>
                              Raw: {parseFloat(totals.dailyRevenue.raw).toFixed(2)}
                              <FontAwesomeIcon icon={faEuro} className='ml-1' />
                            </span>
                          )}
                        </span>
                      </Tooltip>
                    </>
                  ) : !!totals.revenue ? (
                    <>
                      <span>
                        {parseFloat(totals.revenue.discounted).toFixed(2)}{" "}
                        <FontAwesomeIcon icon={faEuro} />
                      </span>
                      <Tooltip
                        title={`Discounted amount: ${parseFloat(
                          totals?.revenue?.raw - totals?.revenue?.discounted
                        ).toFixed(2)}€`}
                      >
                        <span>
                          {!isLoadingTotals && !!totals.revenue.raw && (
                            <span>
                              Raw: {parseFloat(totals.revenue.raw).toFixed(2)}
                              <FontAwesomeIcon icon={faEuro} className='ml-1' />
                            </span>
                          )}
                        </span>
                      </Tooltip>
                    </>
                  ) : (
                    <FontAwesomeIcon icon={faMinus} />
                  )}
                </>
              )}
            </div>

            <div>
              <span>{!!totals.dailyUniqueRiders ? "Daily Unique Riders" : "Unique Riders"}</span>
              {isLoadingTotals ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  className='animate-spin mt-4 opacity-50'
                  size='lg'
                />
              ) : (
                <span>
                  {!!totals.dailyUniqueRiders ? (
                    totals.dailyUniqueRiders
                  ) : !!totals.uniqueRiders ? (
                    totals.uniqueRiders
                  ) : (
                    <FontAwesomeIcon icon={faMinus} />
                  )}
                </span>
              )}
            </div>
          </div>
        )}
        <div className='table-wrapper'>
          {(!!totals.total || !!totals.unpaid) && (
            <div className='external-header'>
              <div className='title-inner'></div>
              <div className='text-text-main flex gap-2 items-center'>
                {!!totals.total && (
                  <div className='flex gap-1 items-center'>
                    <span className='font-bold'>Total:</span>
                    <span className='text-lg'>{totals.total}</span>
                  </div>
                )}
                {!!totals.unpaid && (
                  <div className='flex gap-1 items-center'>
                    <span className='font-bold'>Unpaid:</span>
                    <span className='text-lg'>{totals.unpaid}</span>
                  </div>
                )}
              </div>
            </div>
          )}
          <RentingsTable
            sorting={sorting}
            rentings={rentings}
            isLoading={isLoading}
            pagination={pagination}
            totalElements={totalElements}
            handleSorting={handleSorting}
            handlePageChange={handlePageChange}
            handleSetIsReviewed={handleSetIsReviewed}
            handlePageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default RentingsPageView;
