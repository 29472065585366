import dayjs from "dayjs";
import Parse from "parse";
import { useState } from "react";
import { Tooltip } from "@mui/material";
import Utils from "../../../utils/Utils";
import { metersToKM } from "../../../utils/Units";
import useToaster from "../../../hooks/useToaster";
import RentingTimeUnitTag from "./RentingTimeUnitTag";
import FormInput from "../../../components/form/FormInput";
import { secondsToHoursMinutes } from "../../../utils/Dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDatepicker from "../../../components/form/FormDatepicker";
import {
  faEuro,
  faMinus,
  faSpinner,
  faGaugeHigh,
  faPercentage,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

const RentingDetails = ({
  renting,
  totalBill,
  form = null,
  isEditing = null,
  handleFormChange = null,
}) => {
  const toaster = useToaster();
  const [isLoading, setLoading] = useState(false);

  const getCarMileage = async () => {
    setLoading(true);

    await Parse.Cloud.run("getInverseDeviceStatus", {
      inversQNR: renting.car.inversQNR,
    })
      .then((r) => {
        if (!r) {
          setLoading(false);
          console.error(
            "Invers device did not respond. Try again or try adding the mileage manually."
          );
          toaster.error(
            "Invers device did not respond. Try again or try adding the mileage manually."
          );
        } else {
          setLoading(false);
          handleFormChange("endKm", r.mileage);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error(e.message);
        toaster.error(e.message);
      });
  };

  return (
    <>
      <div className='mb-2'>
        <span className='text-lg font-bold'>Renting Details</span>
      </div>
      <div className='renting-details'>
        <span>Total Cost</span>
        <span>
          {!Utils.isNull(totalBill.totalBill) ? (
            <>
              <FontAwesomeIcon icon={faEuro} /> {parseFloat(totalBill.totalBill).toFixed(2)}
              {isEditing && (
                <Tooltip
                  title="The bill will be calculated automatically upon changing the renting's start and end dates."
                  placement='top'
                >
                  <FontAwesomeIcon
                    className='absolute text-blue-500 cursor-pointer ml-1'
                    icon={faQuestionCircle}
                    size='2xs'
                  />
                </Tooltip>
              )}
            </>
          ) : (
            <div>
              <FontAwesomeIcon icon={faMinus} />
            </div>
          )}
        </span>
        <span>Time Cost</span>
        <span>
          {!!totalBill.bill ? (
            <>
              <FontAwesomeIcon icon={faEuro} /> {parseFloat(totalBill.bill).toFixed(2)}
              {isEditing && (
                <Tooltip
                  title="The bill will be calculated automatically upon changing the renting's start and end dates."
                  placement='top'
                >
                  <FontAwesomeIcon
                    className='absolute text-blue-500 cursor-pointer ml-1'
                    icon={faQuestionCircle}
                    size='2xs'
                  />
                </Tooltip>
              )}
            </>
          ) : (
            <div>
              <FontAwesomeIcon icon={faMinus} />
            </div>
          )}
        </span>
        {!!renting.billKm && (
          <>
            <span>KM Cost</span>
            <span>
              <FontAwesomeIcon icon={faEuro} /> {parseFloat(renting.billKm).toFixed(2)}
            </span>
          </>
        )}
        {!!totalBill.discount && (
          <>
            <span>Discount</span>
            <span>
              {totalBill.discount.type === "FIX" ? (
                <div className='flex items-center gap-1'>
                  <FontAwesomeIcon icon={faEuro} />
                  <font>{parseFloat(totalBill.discount.amount).toFixed(2)}</font>
                </div>
              ) : (
                <div className='flex items-center gap-1'>
                  <font>{totalBill.discount.amount}</font>
                  <FontAwesomeIcon icon={faPercentage} />(
                  {parseFloat(totalBill.discount.applies).toFixed(2)}
                  <FontAwesomeIcon icon={faEuro} />)
                </div>
              )}
              {isEditing && (
                <Tooltip
                  title="The bill will be calculated automatically upon changing the renting's start and end dates."
                  placement='top'
                >
                  <FontAwesomeIcon
                    className='absolute text-blue-500 cursor-pointer ml-1'
                    icon={faQuestionCircle}
                    size='2xs'
                  />
                </Tooltip>
              )}
            </span>
          </>
        )}
        {!!renting.reservation && (
          <>
            <span>Reservation Bill</span>
            <span>
              <FontAwesomeIcon icon={faEuro} /> {parseFloat(renting.reservationBill).toFixed(2)}
            </span>
          </>
        )}
        <span>Time Unit</span>
        {!!renting.timeUnit ? (
          <span>
            <div className='flex items-center'>
              <RentingTimeUnitTag timeUnit={renting.timeUnit} />
            </div>
          </span>
        ) : (
          <div>
            <FontAwesomeIcon icon={faMinus} />
          </div>
        )}
        <span>Time</span>
        {Utils.isNull(renting.time.totalTime) ? (
          <div>
            <FontAwesomeIcon icon={faMinus} />
          </div>
        ) : (
          <span>
            {secondsToHoursMinutes(renting.time.totalTime)}'
            {isEditing && (
              <Tooltip
                title="The total time will be calculated automatically upon changing the renting's start and end dates."
                placement='top'
              >
                <FontAwesomeIcon
                  className='absolute text-blue-500 cursor-pointer ml-1'
                  icon={faQuestionCircle}
                  size='2xs'
                />
              </Tooltip>
            )}
          </span>
        )}
        <span>Started on</span>
        {isEditing ? (
          <div className='flex items-center w-full'>
            <FormDatepicker
              withTime
              isClearable={false}
              errors={form.touched.startTime && form.errors.startTime}
              value={dayjs(new Date(form.values.startTime).toISOString())}
              handleChange={(value) => handleFormChange("startTime", value)}
            />
          </div>
        ) : (
          <>
            {Utils.isNull(renting.time.startTime) ? (
              <span>
                <FontAwesomeIcon icon={faMinus} />
              </span>
            ) : (
              <span>{renting.time.startTime}</span>
            )}
          </>
        )}
        <span>Ended on</span>
        {isEditing ? (
          <div className='flex items-center w-full'>
            <FormDatepicker
              withTime
              isClearable={false}
              errors={form.touched.endTime && form.errors.endTime}
              value={form.values.endTime}
              handleChange={(value) => handleFormChange("endTime", value)}
            />
          </div>
        ) : (
          <>
            {Utils.isNull(renting.time.endTime) ? (
              <span>
                <FontAwesomeIcon icon={faMinus} />
              </span>
            ) : (
              <span>{renting.time.endTime}</span>
            )}
          </>
        )}
        <span className='relative'>
          Distance
          <Tooltip
            title={`The ride's trip path distance, calculated by the ride's geolocation points is ${metersToKM(
              renting.distance
            )} km.`}
          >
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className='ml-0 -mt-2 text-[10px] cursor-pointer'
            />
          </Tooltip>
        </span>
        {Utils.isNull(renting.endKm) ? (
          <span>
            <FontAwesomeIcon icon={faMinus} />
          </span>
        ) : (
          <span>{parseFloat(renting.endKm - renting.startKm).toFixed(2)} km</span>
        )}
        <span>Start Mileage</span>
        {Utils.isNull(renting.startKm) ? (
          <span>
            <FontAwesomeIcon icon={faMinus} />
          </span>
        ) : (
          <span>{renting.startKm} km</span>
        )}
        <span>End Mileage</span>
        {isEditing ? (
          <div className='flex items-center w-full relative'>
            <FormInput
              type='text'
              placeholder='End Mileage'
              value={form.values.endKm}
              errors={form.touched.endKm && form.errors.endKm}
              handleChange={(e) => handleFormChange("endKm", e.target.value)}
            />
            <Tooltip title="Fetch the car's mileage from the invers device. If it doesn't respond, add the mileage manually.">
              <FontAwesomeIcon
                size='lg'
                onClick={getCarMileage}
                icon={isLoading ? faSpinner : faGaugeHigh}
                className={`absolute right-2 top-2 ${
                  isLoading ? "animate-spin cursor-not-allowed" : "cursor-pointer"
                }`}
              />
            </Tooltip>
          </div>
        ) : Utils.isNull(renting.endKm) ? (
          <span>
            <FontAwesomeIcon icon={faMinus} />
          </span>
        ) : (
          <span>{renting.endKm} km</span>
        )}
      </div>
    </>
  );
};

export default RentingDetails;
