import Status from "./Status";
import Utils from "../../../utils/Utils";
import Modal from "../../../components/Modal";
import Invoice from "../../../models/Invoice";
import Button from "../../../components/form/Button";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { invoiceSerializer } from "../../../models/serializers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InvoiceViewerModal = ({
  isOpen,
  invoice,
  isLoading,
  handleVoid,
  toggleModal,
  handleRetryPayment,
  handleMarkUncollectible,
}) => {
  const serialized = invoiceSerializer(invoice);

  const invoiceItems = serialized?.data?.lines?.data.map((item) => ({
    amount: Number(parseFloat(Number(item.amount / 100)).toFixed(2)),
    description: item.description,
  }));

  const invoicePDF = serialized?.data?.invoice_pdf ?? null;
  const invoiceURL = serialized?.data?.hosted_invoice_url ?? null;

  const canRetry =
    String(serialized.status).toUpperCase() === String(Invoice.STATUS.FAILED).toUpperCase() &&
    Utils.spaceToUnderscore(String(serialized.collectionMethod).toUpperCase()) ===
      Utils.spaceToUnderscore(String(Invoice.COLLECTION_METHOD.CHARGE_AUTOMATICALLY).toUpperCase());

  return (
    <Modal
      open={isOpen}
      title='Invoice'
      classes='w-11/12 md:w-2/3 text-text-main'
      handleToggle={() => toggleModal(!isOpen)}
    >
      <div className='invoice-card'>
        <div className='invoice-header'>
          <div className='details-left'>
            <div className='detail-row'>
              <p className='font-bold'>Issued by:</p>
              <p>{serialized.admin}</p>
            </div>
            <div className='detail-row'>
              <p className='font-bold'>Issued to:</p>
              <p>{serialized.user.username}</p>
            </div>
            <div className='detail-row'>
              <p className='font-bold'>Due Date:</p>
              <p>{serialized.dueDate || <FontAwesomeIcon icon={faMinus} />}</p>
            </div>
            <div className='detail-row'>
              <p className='font-bold'>Closed At:</p>
              <p>{serialized.closedAt || <FontAwesomeIcon icon={faMinus} />}</p>
            </div>
            <div className='detail-row'>
              <p className='font-bold'>Created At:</p>
              <p>{serialized.createdAt}</p>
            </div>
          </div>

          <div className='details-right'>
            <div className='detail-row'>
              <p className='font-bold'>Type:</p>
              <p>{serialized.type}</p>
            </div>
            <div className='detail-row'>
              <p className='font-bold'>Status:</p>
              <div className='flex justify-end'>
                <Status status={serialized.status} />
              </div>
            </div>
            <div className='detail-row'>
              <p className='font-bold'>Collection Method:</p>
              <p>
                {Utils.textFirstOnlyUpper(Utils.underscoreToSpace(serialized.collectionMethod))}
              </p>
            </div>
            <div className='detail-row'>
              <p className='font-bold'>Stripe Invoice ID:</p>
              <p className='truncate max-w-full'>
                <a className='link' href={invoiceURL} target='_blank' rel='noreferrer'>
                  {serialized.stripeInvoiceId}
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className='invoice-items'>
          <div>
            <span className='font-bold'>Invoice items:</span>
          </div>
          <div className='invoice-items-list'>
            {invoiceItems.map((item, index) => {
              return (
                <div key={index} className='invoice-item'>
                  <div>{index + 1}.</div>
                  <div className='flex justify-start w-full'>
                    <div>{item.description}</div>
                  </div>
                  <div>{item.amount}€</div>
                </div>
              );
            })}
          </div>
        </div>

        <div className='invoice-footer'>
          <div className='details-left'>
            <Button classes='bg-bg-btn-primary p-2 text-white font-bold'>
              <a href={invoicePDF} target='_blank' rel='noreferrer'>
                Download Invoice
              </a>
            </Button>
          </div>
          <div className='details-right'>
            <div className='detail-row'>
              <p className='font-bold'>Total:</p>
              <p>{serialized.amount}€</p>
            </div>
          </div>
        </div>

        {(serialized.status === Invoice.STATUS.PENDING ||
          serialized.status === Invoice.STATUS.FAILED) && (
          <div className='grid grid-cols-2 gap-2 mt-4 text-sm sm:text-base'>
            <Button
              disabled={isLoading}
              handleClick={() => handleMarkUncollectible(serialized)}
              classes='bg-gray-200 py-1 sm:py-2 text-gray-900 tracking-tight'
            >
              <span>MARK UNCOLLECTIBLE</span>
            </Button>
            <Button
              disabled={isLoading}
              isLoading={isLoading}
              handleClick={() => handleVoid(serialized)}
              classes={`bg-red-500 py-1 sm:py-2 tracking-tight`}
            >
              <span className='text-white'>VOID INVOICE</span>
            </Button>
          </div>
        )}
        {canRetry && (
          <div className='text-right mt-4'>
            <Button
              disabled={isLoading}
              handleClick={() => handleRetryPayment(serialized)}
              classes='bg-bg-btn-primary px-4 sm:py-2 text-white tracking-tight'
            >
              <span>RETRY PAYMENT</span>
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default InvoiceViewerModal;
