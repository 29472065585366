import Discount from "./Discount";

export default class Renting {
  static STATUS = {
    ACTIVE: "ACTIVE",
    UNPAID: "UNPAID",
    COMPLETED: "COMPLETED",
  };

  static RESERVATION_STATUS = {
    EXPIRED: "EXPIRED",
    CANCELED: "CANCELED",
    COMPLETED: "COMPLETED",
  };

  static TIME_UNIT = {
    MINUTE: "MINUTE",
    HOUR: "HOUR",
  };

  static getStatus = (isFinished, isPaid) => {
    switch (true) {
      case isFinished && isPaid:
        return this.STATUS.COMPLETED;
      case isFinished && !isPaid:
        return this.STATUS.UNPAID;
      case !isFinished && !isPaid:
        return this.STATUS.ACTIVE;
      default:
        return "UNDEFINED";
    }
  };

  static getColor = (isFinished, isPaid) => {
    const status = this.getStatus(isFinished, isPaid);

    switch (status) {
      case this.STATUS.COMPLETED:
        return "bg-green-500";
      case this.STATUS.UNPAID:
        return "bg-red-500";
      case this.STATUS.ACTIVE:
        return "bg-blue-500";
      default:
        break;
    }
  };

  static getTimeUnitLabel = (timeUnit) => {
    const unit = String(timeUnit).toUpperCase();
    if (unit === this.TIME_UNIT.MINUTE) return "MIN";
    else return "HOUR";
  };

  static getTimeUnitColor = (timeUnit) => {
    const unit = String(timeUnit).toUpperCase();
    switch (String(unit).toUpperCase()) {
      case this.TIME_UNIT.MINUTE:
        return "bg-blue-600";
      case this.TIME_UNIT.HOUR:
        return "bg-pink-500";
      default:
        return "bg-gray-500";
    }
  };

  static distanceCalculator = (coords1, coords2, isMiles = false) => {
    function toRad(x) {
      return (x * Math.PI) / 180;
    }

    var lon1 = coords1.longitude;
    var lat1 = coords1.latitude;

    var lon2 = coords2.longitude;
    var lat2 = coords2.latitude;

    var R = 6371; // km
    if (isMiles) R = 3959; // miles

    var x1 = lat2 - lat1;
    var dLat = toRad(x1);
    var x2 = lon2 - lon1;
    var dLon = toRad(x2);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;

    return d;
  };

  static discountCalculator = (bill, discount) => {
    let calcDiscount = null;

    if (!!discount) {
      switch (discount.type) {
        case Discount.TYPE.FIX:
          calcDiscount = discount.amount;
          break;
        case Discount.TYPE.REFERRAL:
          calcDiscount = 3;
          break;
        case Discount.TYPE.RIDE:
          calcDiscount = bill;
          break;
        case Discount.TYPE.PERCENTAGE:
          calcDiscount = (discount.amount / 100) * bill;
          break;
        default:
          break;
      }
    }

    return calcDiscount;
  };

  static totalBillCalculator = (bill, discount) => {
    return Number(parseFloat(bill - discount < 0.5 ? 0 : bill - discount).toFixed(2));
  };

  // This method accepts a Rentings set from a Query("Rentings") type, calculates the total bill of each renting by taking into account
  // applied discounts and returns a new Rentings set that includes the `totalBill` property in the attributes
  static provideTotalBill = (rentingsResultSet) => {
    return rentingsResultSet.map((r) => {
      let discount = null;
      const billKm = Number(parseFloat(r.get("billKm") ? r.get("billKm") : 0).toFixed(2));
      const bill = Number(parseFloat(r.get("bill") ? r.get("bill") : 0).toFixed(2));
      const hasDiscount = r.get("payment")?.get("usedDiscount");
      const reservationBill = Number(
        parseFloat(!!r.get("reservation") ? r.get("reservation").get("bill") : 0).toFixed(2)
      );
      const totalBillRaw = Number(parseFloat(bill + billKm + reservationBill).toFixed(2));

      if (!!hasDiscount) {
        discount = {
          type: hasDiscount.get("type") ?? "FIX",
          amount: hasDiscount.get("amount") ?? 0,
        };

        const calcDiscount = Number(
          parseFloat(this.discountCalculator(totalBillRaw, discount)).toFixed(2)
        );
        const totalBillDiscounted = this.totalBillCalculator(totalBillRaw, calcDiscount);

        return {
          ...r,
          attributes: {
            ...r.attributes,
            discount: { ...discount, applies: calcDiscount },
            totalBill: Number(totalBillDiscounted),
            totalBillRaw: Number(totalBillRaw),
          },
        };
      } else {
        return {
          ...r,
          attributes: {
            ...r.attributes,
            discount: discount,
            totalBill: Number(totalBillRaw),
            totalBillRaw: Number(totalBillRaw),
          },
        };
      }
    });
  };
}
