import Parse from "parse";
import Car from "../models/Car";
import useUser from "./useUser";
import Logger from "../models/Logger";
import useToaster from "./useToaster";

const useCar = () => {
  const userHook = useUser();
  const toaster = useToaster();
  const session = userHook.getCurrentSession();

  const handleToggleAvailable = async (toggle, carId) => {
    const Car = new Parse.Object("Car");
    Car.set("objectId", carId);
    Car.set("isAvailable", toggle);
    try {
      await Car.save(null, { useMasterKey: true }).then(async () => {
        await Logger.editCar(session, Car, toggle ? "AVAILABLE" : "UNAVAILABLE");
      });
    } catch (e) {
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  const handleToggleRiding = async (toggle, carId) => {
    const Car = new Parse.Object("Car");
    Car.set("objectId", carId);
    Car.set("isRiding", toggle);
    try {
      await Car.save(null, { useMasterKey: true }).then(async () => {
        await Logger.editCar(session, Car, toggle ? "RIDING" : "NOT RIDING");
      });
    } catch (e) {
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  const handleToggleUnderMaintenance = async (toggle, carId) => {
    const Car = new Parse.Object("Car");
    Car.set("objectId", carId);
    Car.set("underMaintenance", toggle);
    toggle ? Car.set("isAvailable", false) : Car.set("isAvailable", true);
    try {
      await Car.save(null, { useMasterKey: true }).then(async () => {
        await Logger.editCar(
          session,
          Car,
          toggle ? "UNDER MAINTENANCE TRUE" : "UNDER MAINTENANCE FALSE"
        );
      });
    } catch (e) {
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  const handleToggleBluetooth = async (toggle, carId) => {
    const Car = new Parse.Object("Car");
    Car.set("objectId", carId);
    Car.set("hasBluetooth", toggle);
    try {
      await Car.save(null, { useMasterKey: true }).then(async () => {
        await Logger.editCar(session, Car, toggle ? "HAS BLUETOOTH" : "HAS NO BLUETOOTH");
      });
    } catch (e) {
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  const handleToggleChildSeat = async (toggle, carId) => {
    const Car = new Parse.Object("Car");
    Car.set("objectId", carId);
    Car.set("hasChildSeat", toggle);
    try {
      await Car.save(null, { useMasterKey: true }).then(async () => {
        await Logger.editCar(session, Car, toggle ? "HAS CHILD SEAT" : "HAS NO CHILD SEAT");
      });
    } catch (e) {
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  const handleTogglePetFriendly = async (toggle, carId) => {
    const Car = new Parse.Object("Car");
    Car.set("objectId", carId);
    Car.set("isPetFriendly", toggle);
    try {
      await Car.save(null, { useMasterKey: true }).then(async () => {
        await Logger.editCar(session, Car, toggle ? "IS PET FRIENDLY" : "IS NO PET FRIENDLY");
      });
    } catch (e) {
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  const handleNotesSubmit = async (value, carId) => {
    const Car = new Parse.Object("Car");
    Car.set("objectId", carId);
    !!value ? Car.set("notes", value) : Car.unset("notes");
    try {
      await Car.save(null, { useMasterKey: true });
      await Logger.addCarNotes(session, Car);
    } catch (e) {
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  const handleEditCar = async (form, carId) => {
    const Car = new Parse.Object("Car");
    Car.set("objectId", carId);
    try {
      Car.set({
        fuelType: form.fuelType,
        price: Number(form.price),
        priceHourly: Number(form.priceHourly),
        initialMileage: Number(form.initialMileage),
      });
      await Car.save(null, { useMasterKey: true });
      await Logger.editCar(session, Car, JSON.stringify(form));
    } catch (e) {
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  const handleSubmitCare = async (form, careId) => {
    const CarCare = new Parse.Object("CarCare");
    !!careId && CarCare.set("objectId", careId);
    try {
      Object.keys(form).forEach((key) => CarCare.set(key, form[key]));

      const Car = new Parse.Object("Car").set("objectId", form.car.objectId);
      CarCare.set("mileage", Car.get("kmTraveled"));

      await CarCare.save(null, { useMasterKey: true });
      await Logger.editCarCare(session, CarCare, form);
    } catch (e) {
      toaster.error(e.message);
      console.error(e.message);
    }
  };

  return {
    handleEditCar,
    handleSubmitCare,
    handleNotesSubmit,
    handleToggleRiding,
    handleToggleAvailable,
    handleToggleBluetooth,
    handleToggleChildSeat,
    handleTogglePetFriendly,
    handleToggleUnderMaintenance,
  };
};

export default useCar;
