import dayjs from "dayjs";
import Parse from "parse";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import Renting from "../../../models/Renting";
import { findOption } from "../../../utils/Forms";
import Button from "../../../components/form/Button";
import FormInput from "../../../components/form/FormInput";
import { carSerializer } from "../../../models/serializers";
import FormSelect from "../../../components/form/FormSelect";
import FormCheckbox from "../../../components/form/FormCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDatepicker from "../../../components/form/FormDatepicker";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import {
  faFileDownload,
  faFilter,
  faFilterCircleXmark,
  faSquarePlus,
} from "@fortawesome/free-solid-svg-icons";

const Filters = ({
  filters,
  fromCar,
  fromUser,
  isLoading,
  isLoadingTotals,
  handleExportData,
  handleAddRenting,
  handleClearFilters,
  handleFilterChange,
  handleApplyFilters,
}) => {
  const [cars, setCars] = useState([]);

  const carOptions = cars.map((c) => ({
    value: c.carPlate,
    label: c.carPlate + " - " + c.brand + " " + c.model,
  }));
  const carDefault = !Utils.isNull(filters?.carPlate)
    ? findOption(carOptions, String(filters?.carPlate)?.toUpperCase())
    : null;

  const statusOptions = Object.keys(Renting.STATUS)
    .filter((key) => key !== "REJECTED")
    .map((s) => ({
      value: s,
      label: Utils.textFirstOnlyUpper(s),
    }));
  const statusDefault = !Utils.isNull(filters?.status)
    ? findOption(statusOptions, String(filters?.status)?.toUpperCase())
    : null;

  const timeUnitOptions = Object.keys(Renting.TIME_UNIT)
    .filter((key) => key !== "REJECTED")
    .map((s) => ({
      value: s,
      label: Utils.textFirstOnlyUpper(s),
    }));
  const timeUnitDefault = !Utils.isNull(filters?.timeUnit)
    ? findOption(timeUnitOptions, String(filters?.timeUnit)?.toUpperCase())
    : null;

  const from = filters?.from ? dayjs(new Date(filters?.from).toISOString()) : null;
  const to = filters?.to ? dayjs(new Date(filters?.to).toISOString()) : null;

  const getCars = async () => {
    try {
      const response = (
        await new Parse.Query("Car")
          .addAscending("brand")
          .addAscending("model")
          .notEqualTo("inversQNR", "ARISTEST")
          .find({ useMasterKey: true })
      ).map((c) => carSerializer(c));
      setCars(response);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDateChange = (field, value) => {
    if (Utils.isNull(value)) {
      handleFilterChange(field, null);
      return;
    }

    const v = new Date(value);
    field === "from" ? v.setHours(0, 0, 0) : v.setHours(23, 59, 59);
    handleFilterChange(field, v);
  };

  useEffect(() => {
    getCars();
  }, []);

  return (
    <div className='filters'>
      <Accordion defaultExpanded={false}>
        <AccordionSummary>
          <div className='title'>
            <div className='flex items-center flex-row gap-2'>
              <FontAwesomeIcon icon={faFilter} />
              Filters
            </div>
            <div className='filter-actions'>
              <div className='flex items-center gap-2 z-20' onClick={handleClearFilters}>
                <FontAwesomeIcon icon={faFilterCircleXmark} />
                Clear Filters
              </div>
              {fromUser && (
                <div className='flex items-center gap-2 z-20' onClick={handleAddRenting}>
                  <FontAwesomeIcon icon={faSquarePlus} />
                  Add Renting
                </div>
              )}
              <div className='flex items-center gap-2 z-20' onClick={handleExportData}>
                <FontAwesomeIcon icon={faFileDownload} />
                Export
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2 items-end'>
            {!fromUser && (
              <div>
                <span className='text-lg font-semibold'>
                  <FormInput
                    type='text'
                    label='Username'
                    classes='w-full'
                    placeholder='Username'
                    value={Utils.isNull(filters?.username) ? "" : filters?.username}
                    handleChange={(e) => handleFilterChange("username", e.target.value)}
                  />
                </span>
              </div>
            )}
            {!fromCar && (
              <div>
                <FormSelect
                  label='Car Plate'
                  classes='w-full'
                  isClearable={true}
                  options={carOptions}
                  value={carDefault}
                  placeholder='Car Plate'
                  handleChange={(option) => handleFilterChange("carPlate", option)}
                />
              </div>
            )}
            <div>
              <FormSelect
                label='Status'
                classes='w-full'
                isClearable={true}
                options={statusOptions}
                value={statusDefault}
                placeholder='Status'
                handleChange={(option) => handleFilterChange("status", option)}
              />
            </div>
            <div>
              <FormSelect
                label='Time Unit'
                classes='w-full'
                isClearable={true}
                options={timeUnitOptions}
                value={timeUnitDefault}
                placeholder='Time Unit'
                handleChange={(option) => handleFilterChange("timeUnit", option)}
              />
            </div>
            <div>
              <FormDatepicker
                label='From'
                value={from}
                isClearable={true}
                handleChange={(value) => handleDateChange("from", value)}
              />
            </div>
            <div>
              <FormDatepicker
                label='To'
                value={to}
                isClearable={true}
                handleChange={(value) => handleDateChange("to", value)}
              />
            </div>
            <div className='col-span-1 md:col-span-2 lg:col-span-3 flex flex-wrap items-end gap-2'>
              <FormCheckbox
                label='Has Incident'
                value={filters.hasIncident}
                handleChange={(checked) => handleFilterChange("hasIncident", checked)}
              />
              <FormCheckbox
                label='Has Note'
                value={filters.hasNotes}
                handleChange={(checked) => handleFilterChange("hasNotes", checked)}
              />
              <FormCheckbox
                label='Has Discount'
                value={filters.hasDiscount}
                handleChange={(checked) => handleFilterChange("hasDiscount", checked)}
              />
              <FormCheckbox
                label='Has Reservation'
                value={filters.hasReservation}
                handleChange={(checked) => handleFilterChange("hasReservation", checked)}
              />
              <FormCheckbox
                label='Is Not Reviewed'
                value={filters.isReviewed}
                handleChange={(checked) => handleFilterChange("isReviewed", checked)}
              />
            </div>
            <div className="">
              <Button
                classes={`bg-blue-500 text-white px-2 py-1 w-full`}
                handleClick={handleApplyFilters}
                disabled={isLoading || isLoadingTotals}
                isLoading={isLoading || isLoadingTotals}
              >
                Apply
              </Button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Filters;
