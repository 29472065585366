import Utils from "../../utils/Utils";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faCircle, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import {
  faCcJcb,
  faCcVisa,
  faCcAmex,
  faCcDiscover,
  faCcMastercard,
  faCcDinersClub,
} from "@fortawesome/free-brands-svg-icons";

const Card = ({ card, isSelected, handleClick = null, handleDelete = null }) => {
  const Card = card.attributes.card;
  const [cardIcon, setCardIcon] = useState(null);

  const initCardStyles = () => {
    switch (Card.brand) {
      case "amex":
        return setCardIcon(faCcAmex);
      case "visa":
        return setCardIcon(faCcVisa);
      case "discover":
        return setCardIcon(faCcDiscover);
      case "diners":
        return setCardIcon(faCcDinersClub);
      case "jcb":
        return setCardIcon(faCcJcb);
      case "unionpay":
        return setCardIcon(faCreditCard);
      case "mastercard":
        return setCardIcon(faCcMastercard);
      default:
        return;
    }
  };

  useEffect(() => {
    initCardStyles();
  }, []);

  return (
    <div
      className={`rounded-lg bg-bg-payment-card border-2 transition-all ease-in-out duration-100 px-4 py-2 ${
        !!handleClick ? "cursor-pointer hover:bg-opacity-70" : "hover:bg-opacity-100"
      } ${isSelected ? "border-blue-500" : "border-border-table-body"}`}
      onClick={!!handleClick ? () => handleClick(card) : null}
    >
      <div className='flex items-center gap-2 justify-between'>
        <div className='flex items-center gap-2'>
          <div className='mr-2'>
            <FontAwesomeIcon icon={cardIcon} size='2xl' />
          </div>
          <div className='flex items-center gap-1'>
            <FontAwesomeIcon icon={faCircle} className='text-[5px]' />
            <FontAwesomeIcon icon={faCircle} className='text-[5px]' />
            <FontAwesomeIcon icon={faCircle} className='text-[5px]' />
            <FontAwesomeIcon icon={faCircle} className='text-[5px]' />
          </div>
          <div>
            <span>{Card.last4}</span>
          </div>
        </div>
        <div className='flex items-center gap-2'>
          <div className='flex items-center'>
            <span className='w-6 inline-flex items-center justify-center'>
              {Utils.prefillZero(Card.exp_month)}
            </span>
            <span className='w-2 inline-flex items-center justify-center'>/</span>
            <span className='w-6 inline-flex items-center justify-center'>
              {String(Card.exp_year).slice(-2)}
            </span>
          </div>
          {!!handleDelete && (
            <div>
              <FontAwesomeIcon
                icon={faTrashCan}
                className='text-red-500 cursor-pointer'
                onClick={() => handleDelete(card)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
