import Status from "./Status";
import { useState } from "react";
import { Link } from "react-router-dom";
import Utils from "../../../utils/Utils";
import Loader from "../../../components/Loader";
import { isBelow } from "../../../utils/Viewport";
import { dateFormatter } from "../../../utils/Dates";
import InvoiceViewerModal from "./InvoiceViewerModal";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { invoiceSerializer } from "../../../models/serializers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortingState, PagingState, CustomPaging } from "@devexpress/dx-react-grid";
import { Grid, Table, PagingPanel, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";

const InvoicesTable = ({
  sorting,
  invoices,
  isLoading,
  pagination,
  handleVoid,
  totalElements,
  handleSorting,
  handlePageChange,
  handleRetryPayment,
  handlePageSizeChange,
  handleMarkUncollectible,
}) => {
  const sortableColumns = ["createdAt", "closedAt", "dueDate"];
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isInvoiceViewerModalOpen, toggleInvoiceViewerModal] = useState(false);

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props} className='sortable-column-label'>
          {props.children}
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold'>{props.column.title}</span>;
    }
  };

  const resolveUser = (data) => {
    return (
      <Link className='link' to={`/users/${data?.attributes?.user?.id}`}>
        {data?.attributes?.user?.attributes?.username}
      </Link>
    );
  };

  const resolveStatus = (data) => {
    const status = data.attributes.status;

    return <Status status={status} />;
  };

  const resolveCollectionMethod = (data) => {
    return (
      <span>
        {Utils.textFirstOnlyUpper(Utils.underscoreToSpace(data.attributes.collectionMethod))}
      </span>
    );
  };

  const resolveDueDate = (data) => {
    const date = data.attributes.dueDate;

    return !!date ? dateFormatter(date, false) : <FontAwesomeIcon icon={faMinus} />;
  };

  const resolveClosedAt = (data) => {
    const date = data.attributes.closedAt;

    return !!date ? dateFormatter(date, true) : <FontAwesomeIcon icon={faMinus} />;
  };

  const resolveCreatedAt = (data) => {
    return dateFormatter(data.attributes.createdAt, true);
  };

  const resolveColumns = () => {
    let columns = [
      {
        name: "admin",
        title: isBelow("SM") ? "Invoice" : "Admin",
        getCellValue: (data) => data,
      },
    ];

    switch (true) {
      case isBelow("SM"):
        return columns;
      default:
        return [
          {
            name: "admin",
            title: "Admin",
            getCellValue: (data) => data.attributes.admin,
          },
          {
            name: "user",
            title: "User",
            getCellValue: resolveUser,
          },
          {
            name: "type",
            title: "Type",
            getCellValue: (data) => data.attributes.type,
          },
          {
            name: "status",
            title: "Status",
            getCellValue: resolveStatus,
          },
          {
            name: "collectionMethod",
            title: "Collection Method",
            getCellValue: resolveCollectionMethod,
          },
          {
            name: "amount",
            title: "Amount",
            getCellValue: (data) => <span>{data.attributes.amount}€</span>,
          },
          {
            name: "dueDate",
            title: "Due Date",
            getCellValue: resolveDueDate,
          },
          {
            name: "closedAt",
            title: "Closed At",
            getCellValue: resolveClosedAt,
          },
          {
            name: "createdAt",
            title: "Created At",
            getCellValue: resolveCreatedAt,
          },
        ];
    }
  };

  const columns = resolveColumns();

  const handleInvoiceRowClick = (invoice) => {
    setSelectedInvoice(invoice);
    toggleInvoiceViewerModal(!isInvoiceViewerModalOpen);
  };

  const rowComponent = (props) => {
    const invoice = props.row;

    return (
      <Table.Row
        {...props}
        onClick={() => handleInvoiceRowClick(invoice)}
        className='cursor-pointer hover:bg-gray-100 hover:bg-opacity-5 transition-colors'
      />
    );
  };

  const cellComponent = (props) => {
    if (isBelow("SM")) {
      const invoice = invoiceSerializer(props.row);

      return (
        <Table.Cell {...props}>
          <div className='grid grid-cols-2 gap-2'>
            <div className='flex flex-col gap-0'>
              <span className='font-bold'>Admin:</span>
              <span>{invoice.admin}</span>
            </div>
            <div className='flex flex-col gap-0'>
              <span className='font-bold'>Issued To:</span>
              <span>{resolveUser(props.row)}</span>
            </div>
            <div className='flex flex-col gap-0'>
              <span className='font-bold'>Status:</span>
              <span className='flex justify-start'>{resolveStatus(props.row)}</span>
            </div>
            <div className='flex flex-col gap-0'>
              <span className='font-bold'>Type:</span>
              <span>{invoice.type}</span>
            </div>
            <div className='flex flex-col gap-0'>
              <span className='font-bold'>Collection Method:</span>
              <span>{resolveCollectionMethod(props.row)}</span>
            </div>
            <div className='flex flex-col gap-0'>
              <span className='font-bold'>Amount:</span>
              <span>{invoice.amount}€</span>
            </div>
            <div className='flex flex-col gap-0'>
              <span className='font-bold'>Created At:</span>
              <span>{resolveCreatedAt(props.row)}</span>
            </div>
            {invoice.dueDate && (
              <div className='flex flex-col gap-0'>
                <span className='font-bold'>Due Date:</span>
                <span>{resolveDueDate(props.row)}</span>
              </div>
            )}
            {invoice.closedAt && (
              <div className='flex flex-col gap-0'>
                <span className='font-bold'>Closed At:</span>
                <span>{resolveClosedAt(props.row)}</span>
              </div>
            )}
          </div>
        </Table.Cell>
      );
    } else {
      return <Table.Cell {...props} />;
    }
  };

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <div className='z-10'>
        <div className='fixed-table'>
          <Grid rows={invoices} columns={columns} getRowId={(row) => row.id}>
            <SortingState sorting={sorting} onSortingChange={handleSorting} />
            <PagingState
              pageSize={pagination.size}
              currentPage={pagination.page}
              onCurrentPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
            <CustomPaging totalCount={totalElements} />
            <Table
              columnExtensions={[
                {
                  width: "180",
                  columnName: "admin",
                },
                {
                  width: "70",
                  align: "center",
                  columnName: "amount",
                },
                {
                  width: "90",
                  columnName: "type",
                },
                {
                  width: "140",
                  align: "center",
                  columnName: "status",
                },
                {
                  width: "170",
                  columnName: "collectionMethod",
                },
                {
                  width: "100",
                  align: "center",
                  columnName: "dueDate",
                },
                {
                  width: "140",
                  align: "center",
                  columnName: "closedAt",
                },
                {
                  width: "140",
                  columnName: "createdAt",
                },
              ]}
              rowComponent={rowComponent}
              cellComponent={cellComponent}
            />
            <TableHeaderRow
              sortLabelComponent={TableHeaderRowSortLabelComponent}
              showSortingControls={true}
            />
            <PagingPanel pageSizes={[10, 25, 50, 100]} />
          </Grid>
        </div>
      </div>
      {!!selectedInvoice && (
        <InvoiceViewerModal
          isLoading={isLoading}
          handleVoid={handleVoid}
          invoice={selectedInvoice}
          isOpen={isInvoiceViewerModalOpen}
          toggleModal={toggleInvoiceViewerModal}
          handleRetryPayment={handleRetryPayment}
          handleMarkUncollectible={handleMarkUncollectible}
        />
      )}
    </div>
  );
};

export default InvoicesTable;
