import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Loader from "../../../components/Loader";
import { dateFormatter } from "../../../utils/Dates";
import { PagingState, CustomPaging, SortingState } from "@devexpress/dx-react-grid";
import { Grid, Table, PagingPanel, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";

const UsersTableTermsReset = ({
  users,
  sorting,
  isLoading,
  pagination,
  totalElements,
  handleSorting,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const sortableColumns = ["email", "updatedAt"];

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props} className='sortable-column-label'>
          {props.children}
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold'>{props.column.title}</span>;
    }
  };

  const resolveUsername = (data) => {
    return (
      <div className='flex items-center'>
        {!!data?.attributes?.country?.attributes?.emoji && (
          <span className='text-xl mr-1 cursor-pointer'>
            <Tooltip title={data?.attributes?.country?.attributes?.name} placement='top'>
              {data?.attributes?.country?.attributes?.emoji}
            </Tooltip>
          </span>
        )}
        <Link className='link' to={`/users/${data?.id}`}>
          <span className='font-semibold truncate'>{data?.attributes?.username}</span>
        </Link>
      </div>
    );
  };

  const resolveUpdatedAt = (data) => {
    return dateFormatter(data.attributes.updatedAt, true);
  };

  const columns = [
    {
      name: "email",
      title: "User",
      getCellValue: resolveUsername,
    },
    {
      name: "updatedAt",
      title: "Date Updated",
      getCellValue: resolveUpdatedAt,
    },
  ];

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <div className='z-10'>
        <div className='fixed-table'>
          <Grid rows={users} columns={columns} getRowId={(row) => row.id}>
            <SortingState sorting={sorting} onSortingChange={handleSorting} />
            <PagingState
              currentPage={pagination.page}
              pageSize={pagination.size}
              onCurrentPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
            <CustomPaging totalCount={totalElements} />
            <Table
              columnExtensions={[
                {
                  columnName: "email",
                  wordWrapEnabled: true,
                  width: "auto",
                },
                {
                  columnName: "message",
                  wordWrapEnabled: true,
                  width: "80",
                  align: "center",
                },
                {
                  columnName: "note",
                  wordWrapEnabled: true,
                  width: "60",
                  align: "center",
                },
                {
                  columnName: "updatedAt",
                  wordWrapEnabled: true,
                  width: "150",
                  align: "center",
                },
              ]}
            />
            <TableHeaderRow
              showSortingControls={true}
              sortLabelComponent={TableHeaderRowSortLabelComponent}
            />
            <PagingPanel pageSizes={[10, 25, 50, 100]} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default UsersTableTermsReset;
