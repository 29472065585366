import "../styles/global.css";
import "../styles/styles.scss";
import { createContext, useState, useContext, useCallback } from "react";
import Button from "./form/Button";

const ConfirmContext = createContext();

export const useConfirm = () => useContext(ConfirmContext);

export const ConfirmProvider = ({ children }) => {
  const [confirmState, setConfirmState] = useState({
    isOpen: false,
    component: null,
    resolve: null,
  });

  const showConfirm = useCallback((component = null) => {
    return new Promise((resolve) => {
      setConfirmState({ isOpen: true, component, resolve });
    });
  }, []);

  const handleConfirm = () => {
    confirmState.resolve(true);
    setConfirmState({ isOpen: false, component: null, resolve: null });
  };

  const handleCancel = () => {
    confirmState.resolve(false);
    setConfirmState({ isOpen: false, component: null, resolve: null });
  };

  return (
    <ConfirmContext.Provider value={showConfirm}>
      {children}
      {confirmState.isOpen && (
        <div className='z-[99999] fixed inset-0 flex justify-center items-start bg-black bg-opacity-50 pt-28 pb-20'>
          <div className='bg-bg-confirm-wrapper border-3 border-solid border-border-confirm-wrapper px-6 py-4 rounded-lg shadow-lg sm:max-w-md lg:max-w-lg xl:max-w-xl w-full m-4'>
            <div className='text-center'>
              <p className='text-lg text-text-main font-semibold'>Please confirm your action!</p>
            </div>

            {/* Render the custom component if provided */}
            {confirmState.component && (
              <div className='mt-4 p-2 rounded bg-bg-confirm-content text-text-main'>
                {confirmState.component}
              </div>
            )}

            <div className='mt-8 flex justify-center gap-4'>
              <Button handleClick={handleCancel} classes='px-4 py-2 bg-bg-btn-cancel text-text-main rounded'>
                Cancel
              </Button>
              <Button
                handleClick={handleConfirm}
                classes='px-4 py-2 bg-bg-btn-primary text-white rounded'
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      )}
    </ConfirmContext.Provider>
  );
};
