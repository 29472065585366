import Parse from "parse";
import { useFormik } from "formik";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import Logger from "../../../models/Logger";
import useUser from "../../../hooks/useUser";
import Modal from "../../../components/Modal";
import Invoice from "../../../models/Invoice";
import Loader from "../../../components/Loader";
import { findOption } from "../../../utils/Forms";
import useToaster from "../../../hooks/useToaster";
import { array, number, object, string } from "yup";
import Button from "../../../components/form/Button";
import FormInput from "../../../components/form/FormInput";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FormSelect from "../../../components/form/FormSelect";
import { userSerializer } from "../../../models/serializers";
import { useConfirm } from "../../../components/ConfirmContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";

const IssueInvoiceModal = ({ userId = null, isOpen, toggleModal, getInvoices }) => {
  const userHook = useUser();
  const toaster = useToaster();
  const showConfirm = useConfirm();
  const [user, setUser] = useState(null);
  const session = userHook.getCurrentSession();
  const [isLoading, setLoading] = useState(false);
  const isNumeric = (str) => /^[0-9]*\.?[0-9]+$/.test(str);

  const getUser = async () => {
    try {
      const User = userSerializer(
        await new Parse.Query("_User").get(userId, { useMasterKey: true })
      );
      setUser(User);
    } catch (e) {
      console.error(e);
    }
  };

  const validationSchema = object().shape({
    type: string().required("Type is required"),
    invoiceItems: array()
      .min(1, "At least one item is required")
      .required("At least one item is required"),
    collectionMethod: string().required("Collection method is required"),
    daysUntilDue: number()
      .nullable()
      .typeError("Days until due must be a number")
      .when("collectionMethod", {
        is: "SEND_INVOICE",
        then: (schema) => schema.required("Days until due is required"),
      }),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      type: "",
      daysUntilDue: "",
      collectionMethod: "",
      invoiceItems: [{ description: "", amount: "", currency: "EUR" }],
    },
  });

  const currencyOptions = [
    { value: "EUR", label: "EUR" },
    { value: "RSD", label: "RSD" },
  ];

  const typeOptions = Object.keys(Invoice.TYPE).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(t),
  }));
  const typeDefault = !Utils.isNull(formik.values.type)
    ? findOption(typeOptions, String(formik.values.type)?.toUpperCase())
    : null;

  const collectionMethodOptions = Object.keys(Invoice.COLLECTION_METHOD).map((t) => ({
    value: t,
    label: Utils.underscoreToSpace(Utils.textFirstOnlyUpper(t)),
  }));
  const collectionMethodDefault = !Utils.isNull(formik.values.collectionMethod)
    ? findOption(
        collectionMethodOptions,
        Utils.spaceToUnderscore(String(formik.values.collectionMethod)?.toUpperCase())
      )
    : null;

  const handleFormChange = (field, value) => {
    if (field.includes("invoiceItems")) {
      formik.setFieldTouched("invoiceItems");
      const index = field.split(".")[2];
      const innerField = field.split(".")[1];
      let items = formik.values.invoiceItems;

      items[index][innerField] = value;

      formik.setFieldValue("invoiceItems", items);
    } else {
      if (field === "collectionMethod" && value === "charge_automatically")
        formik.setFieldValue("daysUntilDue", "");

      formik.setFieldTouched(field);
      formik.setFieldValue(field, value);
    }
  };

  const handleAddItem = () => {
    let items = formik.values.invoiceItems;
    items.push({ description: "", amount: "", currency: "EUR" });

    formik.setFieldValue("invoiceItems", items);
  };

  const handleRemoveItem = (index) => {
    let items = formik.values.invoiceItems;
    items.splice(index, 1);

    formik.setFieldValue("invoiceItems", items);
  };

  const handleCancel = () => {
    toggleModal(false);
    formik.resetForm();
  };

  const handleIssue = async () => {
    Object.keys(formik.values).forEach((key) => formik.setFieldTouched(key));
    const confirmContent = (
      <div>
        This action is irreversible.
        <br /> You will issue an invoice to {user?.username} for a total amount of{" "}
        {formik.values.invoiceItems.reduce((sum, item) => sum + Number(item.amount), 0)}€
        {!!formik.values.daysUntilDue
          ? ` which will be due in ${formik.values.daysUntilDue} days.`
          : "."}
      </div>
    );

    let invoiceItemsErrors = false;
    formik.values.invoiceItems.map((item) => {
      if (!!!item.description || !!!item.amount) invoiceItemsErrors = true;
    });

    if (formik.isValid && !invoiceItemsErrors) {
      const confirmed = await showConfirm(confirmContent);
      if (!confirmed) {
        return;
      } else {
        toaster.info("Issuing invoice.. Please wait..");
        setLoading(true);

        await Parse.Cloud.run("createInvoice", {
          type: formik.values.type,
          customerId: user?.customerId,
          admin: session.user.username,
          invoiceItems: formik.values.invoiceItems,
          collectionMethod: formik.values.collectionMethod,
          daysUntilDue: !!formik.values.daysUntilDue ? formik.values.daysUntilDue : null,
        })
          .then(async (r) => {
            if (r.success) {
              setLoading(false);
              toggleModal(false);
              formik.resetForm();
              toaster.success("Invoice issued!");
              await Logger.issueInvoice(session, user, formik.values);
              getInvoices();
            } else {
              setLoading(false);
              toaster.error("An error occured. Try again.");
            }
          })
          .catch((e) => {
            setLoading(false);
            console.error(e.message);
            toaster.error(e.message);
          });
      }
    }
  };

  useEffect(() => {
    getUser();
    formik.validateForm();
  }, []);

  useEffect(() => {
    if (!isOpen) {
      formik.resetForm();
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      handleToggle={() => toggleModal(!isOpen)}
      classes={"w-11/12 md:w-2/3 text-text-main"}
      title={`Issue Invoice to ${user?.username}`}
    >
      <Loader isLoading={isLoading} />
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-1 items-start md:gap-2'>
        <FormSelect
          label='Type*'
          value={typeDefault}
          options={typeOptions}
          errors={formik.touched.type && formik.errors.type}
          handleChange={(value) => handleFormChange("type", value.value)}
        />
        <FormSelect
          label='Collection Method*'
          value={collectionMethodDefault}
          options={collectionMethodOptions}
          errors={formik.touched.collectionMethod && formik.errors.collectionMethod}
          handleChange={(value) =>
            handleFormChange(
              "collectionMethod",
              Utils.spaceToUnderscore(String(value.value).toUpperCase())
            )
          }
        />
        <FormInput
          type='text'
          label='Days until due'
          placeholder='Days until due'
          value={formik.values.daysUntilDue}
          errors={formik.touched.daysUntilDue && formik.errors.daysUntilDue}
          handleChange={(e) => handleFormChange("daysUntilDue", e.target.value)}
          disabled={
            formik.values.collectionMethod ===
            Utils.spaceToUnderscore(Invoice.COLLECTION_METHOD.CHARGE_AUTOMATICALLY)
          }
        />
      </div>
      <div className='relative text-center mt-4 mb-1'>
        <span className='text-lg font-medium ml-1'>Invoice Items</span>
        <div
          onClick={handleAddItem}
          className='absolute right-0 top-1/2 -translate-y-1/2 flex items-center gap-1 cursor-pointer'
        >
          <FontAwesomeIcon icon={faSquarePlus} />
          <span className='font-medium'>Add Item</span>
        </div>
      </div>
      <div className='flex flex-col gap-2'>
        {formik.values.invoiceItems.map((item, index) => {
          return (
            <div className='flex items-start relative gap-2'>
              <FormInput
                type='text'
                value={item.description}
                placeholder='Invoice item description'
                errors={item.description === "" && "Description cannot be empty!"}
                handleChange={(e) =>
                  handleFormChange(`invoiceItems.description.${index}`, e.target.value)
                }
              />
              <div className='w-40'>
                <FormInput
                  type='text'
                  value={item.amount}
                  placeholder='Amount'
                  errors={
                    item.amount === ""
                      ? "Amount cannot be empty!"
                      : !isNumeric(item.amount) && "Amount must be a number!"
                  }
                  handleChange={(e) =>
                    handleFormChange(`invoiceItems.amount.${index}`, e.target.value)
                  }
                />
              </div>
              <div className='flex items-center gap-2 w-44'>
                <FormSelect
                  disabled
                  options={currencyOptions}
                  value={findOption(currencyOptions, String(item.currency)?.toUpperCase())}
                />
                <FontAwesomeIcon
                  size='lg'
                  icon={faTimes}
                  onClick={() => handleRemoveItem(index)}
                  className='cursor-pointer'
                />
              </div>
            </div>
          );
        })}
        {formik.errors.invoiceItems && (
          <div className='text-center mt-2'>
            <span className='text-red-500'>{formik.errors.invoiceItems}</span>
          </div>
        )}
      </div>
      <div className='grid grid-cols-2 gap-2 mt-4'>
        <Button
          classes='bg-gray-200 py-2 text-gray-900'
          disabled={isLoading}
          handleClick={handleCancel}
        >
          <span>Cancel</span>
        </Button>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          handleClick={handleIssue}
          classes={`bg-bg-btn-primary py-2`}
        >
          <span className='text-white'>Issue</span>
        </Button>
      </div>
    </Modal>
  );
};

export default IssueInvoiceModal;
