import { Tooltip } from "@mui/material";
import Utils from "../../../utils/Utils";
import Renting from "../../../models/Renting";
import { isAbove, isBelow } from "../../../utils/Viewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActiveStatusBlinker from "../../../components/ActiveStatusBlinker";
import { faImage, faStar, faBan } from "@fortawesome/free-solid-svg-icons";

const Status = ({ intStatus = null, isPaid, isFinished }) => {
  const status = Renting.getStatus(isFinished, isPaid);
  const color = Renting.getColor(isFinished, isPaid);

  const renderIntStatus = () => {
    if (intStatus === 0) {
      return (
        <Tooltip title='User did not upload neither photos nor review.'>
          <FontAwesomeIcon icon={faBan} className='cursor-pointer' />
        </Tooltip>
      );
    } else if (intStatus === 1) {
      return (
        <Tooltip title='User uploaded only photos.'>
          <FontAwesomeIcon icon={faImage} className='cursor-pointer' />
        </Tooltip>
      );
    } else if (intStatus === 2) {
      return (
        <Tooltip title='User uploaded both photos and review.'>
          <div className='flex items-center gap-2 cursor-pointer'>
            <FontAwesomeIcon icon={faImage} />
            <FontAwesomeIcon icon={faStar} />
          </div>
        </Tooltip>
      );
    }
  };

  return (
    <div className='flex items-center justify-center gap-1'>
      <div
        className={`rounded text-white font-bold p-1 sm:p-1.5 ${color} flex items-center gap-0.5 mx-auto ${
          isBelow("MD") && isAbove("SM") ? "w-6 h-6 justify-center" : "w-max"
        }`}
      >
        {status === Renting.STATUS.ACTIVE && <ActiveStatusBlinker color='white' />}
        {(isBelow("SM") || isAbove("MD")) && Utils.textFirstOnlyUpper(status)}
      </div>

      {status === Renting.STATUS.COMPLETED && !!intStatus && (
        <div className='block'>
          <span
            className={`rounded text-text-main font-bold p-1 sm:p-1.5 flex items-center gap-0.5 w-max mx-auto`}
          >
            {renderIntStatus()}
          </span>
        </div>
      )}
    </div>
  );
};

export default Status;
