import "../styles/styles.scss";
import User from "../../../models/User";
import Utils from "../../../utils/Utils";
import useUser from "../../../hooks/useUser";
import Status from "../components/Status.js";
import CarPhotos from "../components/CarPhotos";
import Loader from "../../../components/Loader";
import PageMenu from "../../../components/PageMenu";
import { metersToKM } from "../../../utils/Units.js";
import Button from "../../../components/form/Button.js";
import RentingNotes from "../components/RentingNotes.js";
import { useCallback, useEffect, useState } from "react";
import RentingEvents from "../components/RentingEvents.js";
import ReviewDetails from "../components/ReviewDetails.js";
import PaymentDetails from "../components/PaymentDetails.js";
import ReservationTag from "../components/ReservationTag.js";
import UserCarDetails from "../components/UserCarDetails.js";
import { Polygon } from "../../../components/map/Polygon.ts";
import RentingDetails from "../components/RentingDetails.js";
import LogIncidentModal from "../components/LogIncidentModal";
import ImageLightbox from "../../../components/ImageLightbox";
import { Polyline } from "../../../components/map/Polyline.ts";
import { secondsToHoursMinutes } from "../../../utils/Dates.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RentingRowDetail from "../components/RentingRowDetail.js";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import ChargeRentingModal from "../components/ChargeRentingModal.js";
import IncidentsPageState from "../../incidents/state/IncidentsPageState";
import AdditionalInformation from "../components/AdditionalInformation.js";
import { Map, useMap, useMapsLibrary, AdvancedMarker } from "@vis.gl/react-google-maps";
import {
  faEdit,
  faEuro,
  faXmark,
  faMinus,
  faSpinner,
  faDownload,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";

const RentingPageView = ({
  car,
  form,
  trip,
  driver,
  events,
  renting,
  mapBounds,
  totalBill,
  incidents,
  isLoading,
  isEditing,
  handleEdit,
  handleSave,
  handleToggle,
  handleCharge,
  handleRefund,
  isLoadingReport,
  handleFormChange,
  handleNotesSubmit,
  handleDownloadPDF,
  handleSetIsReviewed,
  handleSubmitDocument,
}) => {
  const userHook = useUser();
  const hasPayment = !!renting?.payment;
  const [openTab, setTab] = useState(0);
  const map = useMap("renting-page-map");
  const coreLib = useMapsLibrary("core");
  const [index, setIndex] = useState(-1);
  const userRoles = userHook.getUserRoles();
  const [slides, setSlides] = useState(null);
  const hasReservation = !!renting?.reservation;
  const [logIncident, toggleLogIncident] = useState(false);
  const adminAccess = userRoles?.includes(User.ROLES.ADMIN);
  const [isChargeModalOpen, setChargeModalOpen] = useState(false);
  const isNotPaid = !renting?.isPaid && !renting?.payment && renting?.isFinished;

  const initializeSlides = useCallback(() => {
    let slds = [];
    !Utils.isNull(renting.carPhotos.front) && slds.push({ src: renting.carPhotos.front });
    !Utils.isNull(renting.carPhotos.back) && slds.push({ src: renting.carPhotos.back });
    !Utils.isNull(renting.carPhotos.left) && slds.push({ src: renting.carPhotos.left });
    !Utils.isNull(renting.carPhotos.right) && slds.push({ src: renting.carPhotos.right });
    setSlides(slds);
  });

  const allowEdit =
    !Utils.isNull(renting.time.startTime) &&
    (Utils.isNull(renting.time.endTime) ||
      Utils.isNull(renting.address.endAddress) ||
      Utils.isNull(renting.location.end.latitude) ||
      Utils.isNull(renting.location.end.longitude)) &&
    adminAccess;

  const tripPath = trip?.attributes?.locationData?.map((item) => ({
    lat: item.latitude,
    lng: item.longitude,
  }));

  const handleOpenImage = (imageUrl) => {
    let index = slides.findIndex((item) => item.src === imageUrl);
    setIndex(index);
  };

  useEffect(() => {
    if (coreLib && map && tripPath) {
      const bounds = new coreLib.LatLngBounds();
      tripPath.forEach((coord) => {
        bounds.extend(coord);
      });
      map.fitBounds(bounds);
    }
  }, [tripPath]);

  useEffect(() => {
    initializeSlides();
  }, []);

  return (
    <div className='page-wrapper relative'>
      <div className='page-content'>
        <Loader isLoading={isLoading} />
        <div className='page-title'>
          <div className='flex items-center gap-0 sm:gap-4 flex-col sm:flex-row'>
            <span className='title'>RENTING - {renting.id} </span>
            <Status
              isPaid={renting.isPaid}
              intStatus={renting.status}
              isFinished={renting.isFinished}
            />
          </div>
          {isNotPaid && (
            <div className='w-full flex justify-end'>
              <Button
                classes={"bg-orange-500 px-4 py-1.5"}
                handleClick={() => setChargeModalOpen(!isChargeModalOpen)}
              >
                <span className='text-white font-bold'>Charge</span>
              </Button>
            </div>
          )}
          <div className='flex items-center gap-2'>
            {allowEdit && (
              <div className='flex items-center gap-2'>
                <div
                  className='flex items-center justify-center w-8 h-8 bg-white border border-solid border-gray-200 rounded cursor-pointer'
                  onClick={allowEdit ? handleEdit : null}
                >
                  <FontAwesomeIcon
                    icon={isEditing ? faXmark : faEdit}
                    className={`${isEditing && "text-red-500"}`}
                    size='lg'
                  />
                </div>
                {isEditing && (
                  <div
                    className='flex items-center justify-center w-8 h-8 bg-white border border-solid border-gray-200 rounded cursor-pointer'
                    onClick={handleSave}
                  >
                    <FontAwesomeIcon icon={faFloppyDisk} className='text-green-600' size='lg' />
                  </div>
                )}
              </div>
            )}

            <PageMenu>
              <div
                className='flex items-center cursor-pointer'
                onClick={() => toggleLogIncident(true)}
              >
                <FontAwesomeIcon icon={faClipboard} size='sm' />
                <span className='ml-2 text-sm'>Log Incident</span>
              </div>
              <div
                className={`flex items-center ${
                  isLoadingReport ? "cursor-not-allowed text-gray-400" : "cursor-pointer"
                }`}
                onClick={!isLoadingReport ? handleDownloadPDF : null}
              >
                <FontAwesomeIcon
                  size='sm'
                  icon={isLoadingReport ? faSpinner : faDownload}
                  className={`${isLoadingReport && "animate-spin"}`}
                />
                <span className='ml-2 text-sm'>Download Report</span>
              </div>
            </PageMenu>
          </div>
        </div>
        <div className='page-totals'>
          <div>
            <span>Vehicle</span>
            <span>{renting.car.carPlate}</span>
          </div>
          <div>
            <span>Time</span>
            <span>
              {!!renting.time.totalTime ? (
                secondsToHoursMinutes(renting.time.totalTime) + "'"
              ) : (
                <FontAwesomeIcon icon={faMinus} />
              )}
            </span>
          </div>
          <div>
            <span>Distance</span>
            <span>
              {!!renting.distance ? (
                metersToKM(renting.distance) + " km"
              ) : (
                <FontAwesomeIcon icon={faMinus} />
              )}
            </span>
          </div>
          <div>
            <span>Cost</span>
            <span>
              {!!totalBill.totalBill ? (
                <>
                  {parseFloat(totalBill.totalBill).toFixed(2)}
                  <FontAwesomeIcon icon={faEuro} />
                </>
              ) : (
                <FontAwesomeIcon icon={faMinus} />
              )}
            </span>
          </div>
        </div>
        <div>
          <div className='tabs grid-cols-4'>
            <div
              onClick={() => setTab(0)}
              className={`tab ${openTab === 0 ? "active" : "inactive"}`}
            >
              <span>Details</span>
            </div>
            <div
              onClick={() => setTab(1)}
              className={`tab ${openTab === 1 ? "active" : "inactive"}`}
            >
              <span>Events</span>
            </div>
            {hasPayment && (
              <div
                onClick={() => setTab(2)}
                className={`tab ${openTab === 2 ? "active" : "inactive"}`}
              >
                <span>Payment Details</span>
              </div>
            )}
            {!!incidents && (
              <div
                onClick={() => setTab(3)}
                className={`tab ${openTab === 3 ? "active" : "inactive"}`}
              >
                <span>Incidents</span>
              </div>
            )}
          </div>
          <div className='py-4'>
            {openTab === 0 ? (
              <>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4'>
                  <div className='box-border-rounded with-bg'>
                    <UserCarDetails
                      car={car}
                      form={form}
                      driver={driver}
                      renting={renting}
                      isEditing={isEditing}
                      handleFormChange={handleFormChange}
                    />
                  </div>
                  <div className='box-border-rounded with-bg'>
                    <RentingDetails
                      form={form}
                      renting={renting}
                      totalBill={totalBill}
                      isEditing={isEditing}
                      handleFormChange={handleFormChange}
                    />
                  </div>
                  <div className='box-border-rounded with-bg'>
                    <AdditionalInformation
                      driver={driver}
                      renting={renting}
                      handleToggle={handleToggle}
                      handleSetIsReviewed={handleSetIsReviewed}
                    />
                  </div>
                  <div className='box-border-rounded with-bg'>
                    <RentingNotes renting={renting} handleNotesSubmit={handleNotesSubmit} />
                  </div>
                  <div className='box-border-rounded with-bg'>
                    <ReviewDetails renting={renting} />
                  </div>
                </div>
                {hasReservation && (
                  <div className='box-border-rounded with-bg p-1'>
                    <div className='flex mb-2'>
                      <ReservationTag isCompact={false} />
                    </div>
                    <RentingRowDetail row={renting} />
                  </div>
                )}
                {!!tripPath && tripPath.length > 0 && (
                  <div className='my-4 h-[400px] rounded overflow-hidden border border-solid border-gray-300'>
                    <Map
                      id='renting-page-map'
                      gestureHandling={"greedy"}
                      disableDefaultUI={false}
                      mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
                    >
                      <Polygon
                        paths={mapBounds}
                        fillOpacity='0.25'
                        fillColor={"#4361EE"}
                        strokeColor={"#4361EE"}
                        strokeWeight='.5'
                      />
                      <Polyline
                        path={tripPath}
                        fillOpacity='0.25'
                        fillColor={"#FF0000"}
                        strokeColor={"#FF0000"}
                        strokeWeight='2'
                      />
                      <AdvancedMarker position={tripPath?.length > 0 && tripPath[0]}>
                        <div className='route-marker'>
                          <div>
                            <span>Start</span>
                          </div>
                        </div>
                      </AdvancedMarker>
                      <AdvancedMarker
                        position={tripPath?.length > 0 && tripPath[tripPath?.length - 1]}
                      >
                        <div className='route-marker'>
                          <div>
                            <span>End</span>
                          </div>
                        </div>
                      </AdvancedMarker>
                    </Map>
                  </div>
                )}

                <div className='box-border-rounded with-bg'>
                  <CarPhotos
                    car={car}
                    renting={renting}
                    handleOpenImage={handleOpenImage}
                    handleSubmitDocument={handleSubmitDocument}
                  />
                </div>
              </>
            ) : openTab === 1 ? (
              <RentingEvents events={events} />
            ) : openTab === 2 ? (
              <PaymentDetails renting={renting} handleRefund={handleRefund} />
            ) : Array.isArray(incidents) ? (
              <IncidentsPageState rentingId={renting.id} hasFilters={false} />
            ) : (
              <div>
                <span className='text-text-main'>Nothing to show</span>
              </div>
            )}
          </div>
        </div>
        <ImageLightbox index={index} slides={slides} setIndex={setIndex} />
      </div>
      <LogIncidentModal
        renting={renting}
        logIncident={logIncident}
        toggleLogIncident={toggleLogIncident}
      />
      <ChargeRentingModal
        renting={renting}
        isOpen={isChargeModalOpen}
        handleCharge={handleCharge}
        toggleModal={setChargeModalOpen}
      />
    </div>
  );
};

export default RentingPageView;
