import Parse from "parse";
import { useEffect, useState } from "react";
import Logger from "../../../models/Logger";
import useUser from "../../../hooks/useUser";
import Modal from "../../../components/Modal";
import Loader from "../../../components/Loader";
import useToaster from "../../../hooks/useToaster";
import Button from "../../../components/form/Button";
import UsersTableTermsReset from "./UsersTableTermsReset";

const ResetTermsModal = ({ isOpen, toggleModal }) => {
  const userHook = useUser();
  const toaster = useToaster();
  const [users, setUsers] = useState([]);
  const session = userHook.getCurrentSession();
  const [isLoading, setLoading] = useState(true);
  const [usersReset, setUsersReset] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [isLoadingReset, setLoadingReset] = useState(false);
  const [confirmedReset, setConfirmedReset] = useState(false);
  const [sorting, setSorting] = useState([{ columnName: "updatedAt", direction: "desc" }]);
  const [pagination, setPagination] = useState({
    page: 0,
    size: 25,
  });

  const usersToReset = async () => {
    setLoading(true);
    try {
      const query = new Parse.Query("_User").equalTo("acceptTerms", true).withCount();

      if (sorting[0]?.direction === "asc") {
        query.ascending(sorting[0].columnName);
      } else {
        query.descending(sorting[0].columnName);
      }
      query.limit(pagination.size);
      query.skip(pagination.page * pagination.size);
      const users = await query.find({ useMasterKey: true });

      setUsers(users.results);
      setTotalElements(users.count);
      setLoading(false);
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
      setLoading(false);
    }
  };

  const handleSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPagination({ ...pagination, page: page });
  };

  const handlePageSizeChange = (size) => {
    setLoading(true);
    setPagination({ ...pagination, size: size });
  };

  const confirmReset = () => {
    if (
      window.confirm(`Last warning! Are you sure you want to proceed resetting the users' terms?`)
    ) {
      setConfirmedReset(true);
    }
  };

  const resetTerms = async () => {
    setLoadingReset(true);
    try {
      let parseObjects = [];
      const usersToReset = await new Parse.Query("_User")
        .select("objectId")
        .equalTo("acceptTerms", true)
        .findAll({ useMasterKey: true });

      setUsersReset(usersToReset);

      usersToReset.forEach((u) => {
        const user = new Parse.Object("_User").set("objectId", u.id).set("acceptTerms", false);
        parseObjects.push(user);
      });

      await Parse.Object.saveAll(parseObjects, { useMasterKey: true }).then(async () => {
        await Logger.resetUserTerms(session, parseObjects);
      });
      setLoadingReset(false);
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
      setLoadingReset(false);
    }
  };

  useEffect(() => {
    usersToReset();
  }, []);

  useEffect(() => {
    if (confirmedReset) {
      resetTerms();
    }
  }, [confirmedReset]);

  useEffect(() => {
    usersToReset();
  }, [sorting, pagination]);

  return (
    <Modal
      open={isOpen}
      title='Reset Terms'
      classes={"w-11/12 md:w-5/6"}
      handleToggle={() => toggleModal(!isOpen)}
    >
      <div>
        {!!isLoadingReset ? (
          <div className='relative h-40'>
            <div>
              <span className='text-text-main'>Plese wait...</span>
            </div>
            <Loader isLoading={isLoadingReset} />
          </div>
        ) : usersReset.length > 0 ? (
          <div className='text-text-main'>
            <div>
              <span className='font-medium'>Procedure completed successfully.</span>
            </div>
            <div>
              <span className='font-medium'>
                {usersReset.length} total users have their terms reset.
              </span>
            </div>
          </div>
        ) : (
          <>
            <div className='text-text-main pb-2'>
              <div>
                <span className='font-medium'>
                  This action will reset the terms acceptance to <b>{totalElements}</b> users!
                  Review them below.
                </span>
              </div>
              <div className='flex items-center justify-between'>
                <span className='font-medium'>
                  Are you sure you want to do this? Proceed with caution!
                </span>
                <Button classes={`bg-red-700 px-2 py-1`} handleClick={confirmReset}>
                  Yes i'm sure
                </Button>
              </div>
            </div>
            <div className='max-h-[65vh] overflow-y-auto'>
              <UsersTableTermsReset
                users={users}
                sorting={sorting}
                isLoading={isLoading}
                pagination={pagination}
                totalElements={totalElements}
                handleSorting={handleSorting}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ResetTermsModal;
