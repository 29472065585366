import Car from "../../../models/Car";
import Utils from "../../../utils/Utils";
import { findOption } from "../../../utils/Forms";
import FormInput from "../../../components/form/FormInput";
import FormSelect from "../../../components/form/FormSelect";

const CarAdditionalInfo = ({ car, editForm, isEditing, isLoadingForm, handleEditFormChange }) => {
  const fuelTypeOptions = Object.keys(Car.FUEL_TYPES).map((i) => ({
    value: i,
    label: Utils.textFirstOnlyUpper(i),
  }));
  const fuelTypeDefaultOption = !Utils.isNull(editForm.values.fuelType)
    ? findOption(fuelTypeOptions, String(editForm.values.fuelType))
    : null;

  return (
    <>
      <div className='mb-2'>
        <span className='text-lg font-bold'>Additional Information</span>
      </div>
      <div className='car-details'>
        <span>ID</span>
        <span>{car.id}</span>
        <span>Transmission</span>
        <span>{car.autoTransmission ? "Automatic" : "Manual"}</span>
        <span>Fuel Type</span>
        {isEditing ? (
          <div>
            <FormSelect
              isClearable={false}
              placeholder='Fuel Type'
              disabled={isLoadingForm}
              options={fuelTypeOptions}
              value={fuelTypeDefaultOption}
              errors={editForm.touched.fuelType && editForm.errors.fuelType}
              handleChange={(value) => handleEditFormChange("fuelType", value.value)}
            />
          </div>
        ) : (
          <span>{Utils.textFirstOnlyUpper(car.fuelType)}</span>
        )}
        <span>Seats</span>
        <span>{car.seatsNum}</span>
        <span>Manufacturing Date</span>
        <span>{car.manufacturingDate}</span>
        <span>Mileage</span>
        <span>{car.kmTraveled} km</span>
        <span>Initial Mileage</span>
        {isEditing ? (
          <div>
            <FormInput
              type='text'
              disabled={isLoadingForm}
              placeholder='Initial Mileage'
              value={editForm.values.initialMileage}
              errors={editForm.touched.initialMileage && editForm.errors.initialMileage}
              handleChange={(e) => handleEditFormChange("initialMileage", e.target.value)}
            />
          </div>
        ) : (
          <span>{car.initialMileage && `${car.initialMileage} km`}</span>
        )}
        <span>Owner</span>
        <span>{car.owner.carProviderName}</span>
        <span>Invers QNR</span>
        <span>{car.inversQNR}</span>
        <span>Created</span>
        <span>{car.createdAt}</span>
      </div>
    </>
  );
};

export default CarAdditionalInfo;
