export default class Car {
  static FUEL_TYPES = {
    PETROL: "PETROL",
    DIESEL: "DIESEL",
    HYBRID: "HYBRID",
  };

  static CARE_STATUS = {
    PENDING: "PENDING",
    ONGOING: "ONGOING",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
  };

  static CARE_TYPES = {
    SERVICE: "SERVICE",
    OILS_CHANGE: "OILS CHANGE",
    TIRES_CHANGE: "TIRES CHANGE",
  };

  static getCareStatusColor = (status) => {
    switch (status) {
      case Car.CARE_STATUS.PENDING:
        return "bg-orange-500";
      case Car.CARE_STATUS.ONGOING:
        return "bg-blue-500";
      case Car.CARE_STATUS.COMPLETED:
        return "bg-green-500";
      case Car.CARE_STATUS.CANCELLED:
        return "bg-red-500";
      default:
        return "";
    }
  };

  static getFuelColor = (fuel) => {
    switch (true) {
      case fuel < 25:
        return "bg-red-500";
      case fuel >= 25 && fuel < 50:
        return "bg-orange-500";
      case fuel >= 50 && fuel < 75:
        return "bg-yellow-500";
      case fuel >= 75:
        return "bg-green-600";
      default:
        return "";
    }
  };

  static getFuelColorMUI = (fuel) => {
    switch (true) {
      case fuel < 25:
        return "error";
      case fuel >= 25 && fuel < 75:
        return "warning";
      case fuel >= 75:
        return "success";
      default:
        return "primary";
    }
  };
}
