import Parse from "parse";
import Admin from "./Admin";
import Utils from "../utils/Utils";
import {
  carSerializer,
  userSerializer,
  commSerializer,
  rentingSerializer,
  discountSerializer,
  campaignSerializer,
  happyHourSerializer,
  userMessageSerializer,
} from "./serializers";

export default class Logger {
  static login = async (session) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.id);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.LOGIN);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} logged in.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static logout = async (session) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.LOGOUT);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} logged out.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static addCar = async (session, car) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const cS = carSerializer(car);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.ADD_CAR);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} added new car: ${cS.carPlate} - ${cS.brand} ${cS.model}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static editRenting = async (session, renting) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const rS = rentingSerializer(renting);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.EDIT_RENTING);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} edited renting: ${rS.id} of user ${rS.driver.id} - ${rS.driver.username}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static markedRentingReviewed = async (session, renting) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const rS = rentingSerializer(renting);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.EDIT_RENTING);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} marked renting: ${rS.id} of user ${rS.driver.id} - ${rS.driver.username} as reviewed ${
        rS.isReviewed ? "TRUE" : "FALSE"
      }.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static authorizeUser = async (session, user, action) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const uS = userSerializer(user);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.AUTHORIZE_USER);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} authorized user: ${uS.id} - ${uS.username} with action ${action}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static editUser = async (session, user, action) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const uS = userSerializer(user);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.EDIT_USER);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} edited user: ${uS.id} - ${uS.username} with details ${action}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static editCar = async (session, car, action) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const cS = carSerializer(car);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.EDIT_CAR);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} edited car: ${cS.id} - ${cS.carPlate} with action ${action}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static sendMessage = async (session, message) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const umS = userMessageSerializer(message);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.SEND_USER_MESSAGE);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} sent user message to: ${umS.reciever.id} - ${umS.reciever.username} of type ${
        umS.type
      } and title: ${umS.title}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static sendEmail = async (session, email) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.SEND_USER_EMAIL);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} sent user email to: ${email.toEmail} and subject: ${email.subject}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static addDiscount = async (session, discount) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const dS = discountSerializer(discount);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.ADD_DISCOUNT);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} added discount to: ${dS.user.id} - ${dS.user.username} of ${dS.amount} type ${dS.type}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static addUserNotes = async (session, user) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const uS = userSerializer(user);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.ADD_USER_NOTES);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} added notes to user: ${uS.id} - ${uS.username}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static addRentingNotes = async (session, renting) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const rS = rentingSerializer(renting);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.ADD_RENTING_NOTES);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} added notes to renting: ${rS.id} - User: ${rS.driver.id} ${rS.driver.username} Car: ${
        rS.car.carPlate
      }.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static addCarNotes = async (session, car) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const cS = carSerializer(car);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.ADD_CAR_NOTES);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} added notes to car: ${cS.id} - ${cS.carPlate}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static addUserComm = async (session, comm, isEdit) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const commS = commSerializer(comm);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", isEdit ? Admin.ACTIONS.EDIT_USER_COMM : Admin.ACTIONS.ADD_USER_COMM);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(aS.lastName)} ${
        isEdit ? "edited" : "added"
      } communication to user: ${commS.user.id} - ${commS.user.username} with details: ${
        commS.type
      } ${commS.priority} ${commS.status} and subject ${commS.subject}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static deleteUserComm = async (session, comm) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const commS = commSerializer(comm);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.DELETE_USER_COMM);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} deleted a communication record from user: ${commS.user.id} - ${
        commS.user.username
      } with details: ${commS.type} ${commS.priority} ${commS.status} and subject ${commS.subject}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static deleteUser = async (session, user) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const uS = userSerializer(user);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.DELETE_USER);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} deleted the user: ${uS.id} - ${uS.username}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static deleteUserPaymentMethod = async (session, user, paymentMethod) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.DELETE_USER_PAYMENT_METHOD);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} deleted user's: ${user.id} - ${user.username} payment method ${paymentMethod.id} - ${
        paymentMethod.attributes.type
      } ${paymentMethod.attributes.card.brand} ${
        paymentMethod.attributes.card.last4
      } ${Utils.prefillZero(paymentMethod.attributes.card.exp_month)}/${
        paymentMethod.attributes.card.exp_year
      }.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static downloadRentalReport = async (session, renting) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const rS = rentingSerializer(renting);
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.DOWNLOAD_RENTAL_REPORT);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} downloaded rental report of: ${rS.driver.username} - ${rS.car.carPlate} ${
        rS.time.startTime
      }.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static editGeneralSettings = async (session, setting) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.EDIT_GENERAL_SETTINGS);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} edited General Settings: ${setting}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static editCarCareSettings = async (session, setting) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.EDIT_CAR_CARE_SETTINGS);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} edited Car Care Setting: ${setting}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static addCampaign = async (session, campaign) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    const cS = campaignSerializer(campaign);
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.ADD_CAMPAIGN);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} added new Campaign: ${campaign.attributes.code} with values Code: ${cS.code}, Type: ${
        cS.discountType
      }, Amount: ${cS.discountAmount}, Start: ${cS.start}, End: ${cS.end}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static editCampaign = async (session, campaign) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    const cS = campaignSerializer(campaign);
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.EDIT_CAMPAIGN);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} edited Campaign: ${campaign.attributes.code} with values Code: ${cS.code}, Type: ${
        cS.discountType
      }, Amount: ${cS.discountAmount}, Start: ${cS.start}, End: ${cS.end}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static toggleHappyHour = async (session, happyHour) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    const hhS = happyHourSerializer(happyHour);
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.EDIT_HAPPY_HOUR);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} toggled Happy Hour: ${hhS.text} ${hhS.isEnabled ? "ON" : "OFF"}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static editHappyHour = async (session, happyHour) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    const hhS = happyHourSerializer(happyHour);
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.EDIT_HAPPY_HOUR);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} edited Happy Hour: ${hhS.text} with values Type: ${hhS.type}, Amount: ${
        hhS.discount
      }, Start: ${hhS.startsAt}, End: ${hhS.endsAt}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static resetUserTerms = async (session, usersToReset) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.RESET_USER_TERMS);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} reset the user terms of: ${usersToReset.length} users.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static editCarCare = async (session, care, form) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const cS = carSerializer(new Parse.Object("Car").set("objectId", care.get("car").objectId));
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    const details = form;
    delete details.car;
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.EDIT_CAR_CARE);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} edited care for car: ${cS.carPlate} - ${cS.brand} ${cS.model} with details: ${String(
        JSON.stringify(details)
      ).replace(":", " : ")}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static applyRentingCharge = async (session, charge) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.APPLY_RENTING_CHARGE);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} applied charge for renting: ${charge.rentingId} with amount: ${
        charge.amount
      }€ and paymentId: ${charge.paymentMethod}`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static applyPaymentRefund = async (session, data) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.APPLY_PAYMENT_REFUND);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} applied payment refund for payment with ID: ${data.paymentId}, Stripe payment ID: ${
        data.stripePaymentId
      }, renting ID: ${data.rentingId}, amount: ${data.amount}€, reason ${
        data.reason
      } and details: ${!!data.details ? data.details : "no-details-included"}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static issueInvoice = async (session, user, data) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.ISSUE_INVOICE);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} issued an invoice to user with ID: ${user.id} - ${user.username}, Stripe customer ID: ${
        user.customerId
      }, of type ${data.type} and collection method of "${
        data.collectionMethod
      }" for a total amount of: ${data.invoiceItems.reduce(
        (sum, item) => sum + Number(item.amount),
        0
      )}€${!!data.daysUntilDue ? `, due in: ${data.daysUntilDue} days.` : "."}`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static editInvoice = async (session, data, action) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.EDIT_INVOICE);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} edited the invoice with ID: ${data.id}, Invoice Stripe ID: ${
        data.stripeInvoiceId
      } issued to user with ID: ${data.user.id}, username: ${
        data.user.username
      } and Stripe customer ID: ${data.user.customerId}, with action: ${action}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };

  static retryInvoicePayment = async (session, data) => {
    const admin = new Parse.Object("_User").set("objectId", session.user.objectId);
    const aS = userSerializer(admin);
    const role = session.roles.includes("Admin")
      ? "Admin"
      : session.roles.includes("Maintainer")
      ? "Maintainer"
      : "User";
    const Logger = new Parse.Object("AdminLogger");
    Logger.set("user", admin);
    Logger.set("action", Admin.ACTIONS.RETRY_INVOICE_PAYMENT);
    Logger.set(
      "extra",
      `${role} ${Utils.textFirstOnlyUpper(aS.firstName)} ${Utils.textFirstOnlyUpper(
        aS.lastName
      )} retried the invoice payment for invoice with ID: ${data.id}, Invoice Stripe ID: ${
        data.stripeInvoiceId
      } issued to user with ID: ${data.user.id}, username: ${
        data.user.username
      } and Stripe customer ID: ${data.user.customerId}.`
    );
    await Logger.save(null, { useMasterKey: true });
  };
}
