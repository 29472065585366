import Status from "./Status";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Utils from "../../../utils/Utils";
import Renting from "../../../models/Renting";
import ReservationTag from "./ReservationTag";
import Loader from "../../../components/Loader";
import { metersToKM } from "../../../utils/Units";
import RentingRowDetail from "./RentingRowDetail";
import RentingTimeUnitTag from "./RentingTimeUnitTag";
import { isAbove, isBelow } from "../../../utils/Viewport";
import { rentingSerializer } from "../../../models/serializers";
import FormCheckbox from "../../../components/form/FormCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dateFormatter, secondsToHoursMinutes } from "../../../utils/Dates";
import { faEuro, faMinus, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { PagingState, CustomPaging, SortingState, RowDetailState } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  PagingPanel,
  TableHeaderRow,
  TableRowDetail,
} from "@devexpress/dx-react-grid-material-ui";

const UserRentingsTable = ({
  sorting,
  rentings,
  isLoading,
  pagination,
  totalElements,
  handleSorting,
  handlePageChange,
  handleSetIsReviewed,
  handlePageSizeChange,
}) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const sortableColumns = ["bill", "startTime", "endTime", "totalTime", "distance"];

  const handleExpand = (rows) => {
    setExpandedRows(rows);
  };

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props} className={`sortable-column-label`}>
          {props.children}
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold'>{props.column.title}</span>;
    }
  };

  const resolveId = (data) => {
    const renting = rentingSerializer(data);
    const timeUnit = renting.timeUnit;
    const hasReservation = !!renting.reservation;

    return (
      <div className='flex items-start flex-col gap-1'>
        <Link className='font-bold link' to={"/rentings/" + data.id}>
          {data.id}
        </Link>
        <div className='flex items-center gap-1'>
          {hasReservation && (
            <ReservationTag
              status={data.attributes.reservation.attributes.status}
              isCompact={true}
            />
          )}
          {!!timeUnit && <RentingTimeUnitTag timeUnit={timeUnit} />}
        </div>
      </div>
    );
  };

  const resolveBill = (data) => {
    const bill = Utils.billFormatter(data.attributes.bill);

    if (Utils.isNull(bill)) {
      return (
        <div>
          <FontAwesomeIcon icon={faMinus} />
        </div>
      );
    } else {
      return (
        <div>
          {parseFloat(bill).toFixed(2)}
          <FontAwesomeIcon icon={faEuro} className='ml-1' />
        </div>
      );
    }
  };

  const resolveDiscount = (data) => {
    const bill = data.attributes.bill;
    const hasDiscount = data.attributes.discount ?? null;

    if (!!hasDiscount) {
      const discount = Renting.discountCalculator(bill, hasDiscount);
      return (
        <div>
          <span>{parseFloat(discount).toFixed(2)}</span>
          <FontAwesomeIcon icon={faEuro} className='ml-1' />
        </div>
      );
    } else {
      return <FontAwesomeIcon icon={faMinus} />;
    }
  };

  const resolveTotal = (data) => {
    const renting = rentingSerializer(data);
    const billKm = renting.billKm;
    const reservationBill = renting.reservationBill;
    const bill = parseFloat(renting.bill).toFixed(2);
    const totalBill = parseFloat(renting.totalBill).toFixed(2);

    return (
      <div>
        <Tooltip
          placement='top'
          title={`Bill = initial bill (car price * time + 1€ unlock): ${bill}€ ${
            !!billKm ? ` + additional KM bill: ${billKm}€` : ``
          } ${reservationBill !== 0 ? ` + reservation bill: ${reservationBill}€` : ``}`}
        >
          <span>{totalBill}</span>
          <FontAwesomeIcon icon={faEuro} className='ml-1' />
        </Tooltip>
      </div>
    );
  };

  const resolveCarPlate = (data) => {
    const isCarDefined = !!data?.attributes?.car;
    if (isCarDefined) {
      return (
        <Link className='link' to={`/cars/${data.attributes.car.id}`}>
          <span className='font-semibold'>{data.attributes.car.attributes.carPlate}</span>
        </Link>
      );
    } else {
      return;
    }
  };

  const resolveUsername = (data) => {
    return (
      <Link className='link' to={`/users/${data?.attributes?.driver?.id}`}>
        <span className='font-semibold truncate'>
          {data?.attributes?.driver?.attributes?.username}
        </span>
      </Link>
    );
  };

  const resolveStartTime = (data) => {
    const isValid = !!data.attributes.startTime;
    const hasRsrv = !!data.attributes.reservation;
    if (!isValid && hasRsrv) {
      return (
        <div className='flex items-center'>
          <ReservationTag isCompact={false} />
        </div>
      );
    } else {
      if (hasRsrv) {
        return (
          <Tooltip
            title={`Ride start time is: ${dateFormatter(data.attributes.startTime, true)}`}
            placement='top'
          >
            {dateFormatter(data.attributes.reservation.attributes.start, true)}
          </Tooltip>
        );
      } else {
        return dateFormatter(data.attributes.startTime, true);
      }
    }
  };

  const resolveEndTime = (data) => {
    const endTime = dateFormatter(data.attributes.endTime, true);
    if (Utils.isNull(endTime)) {
      return <FontAwesomeIcon icon={faMinus} />;
    } else {
      return endTime;
    }
  };

  const resolveTotalTime = (data) => {
    const totalTime = data.attributes.totalTime;

    if (Utils.isNull(totalTime)) {
      return <FontAwesomeIcon icon={faMinus} />;
    } else {
      return secondsToHoursMinutes(totalTime) + "'";
    }
  };

  const resolveDistance = (data) => {
    const distance = data.attributes.distance;

    if (Utils.isNull(distance)) {
      return <FontAwesomeIcon icon={faMinus} />;
    } else {
      return metersToKM(distance) + " km";
    }
  };

  const resolveIsReviewed = (data) => {
    const isReviewed = data.attributes.isReviewed;

    return (
      <div className='flex items-center'>
        <FormCheckbox
          value={isReviewed}
          handleChange={(value) => handleSetIsReviewed(value, data.id)}
        />
      </div>
    );
  };

  const resolveStatus = (data) => {
    const isFinished = data.attributes.isFinished;
    const isPaid = data.attributes.isPaid;

    return <Status isFinished={isFinished} isPaid={isPaid} />;
  };

  const resolveColumns = () => {
    if (isBelow("SM")) {
      return [
        {
          name: "id",
          title: "Renting",
          getCellValue: resolveId,
        },
      ];
    } else if (isAbove("SM") && isBelow("LG")) {
      return [
        {
          name: "id",
          title: "ID",
          getCellValue: resolveId,
        },
        {
          name: "carPlate",
          title: "Car",
          getCellValue: resolveCarPlate,
        },
        {
          name: "username",
          title: "User",
          getCellValue: resolveUsername,
        },
        {
          name: "startTime",
          title: "Start Date",
          getCellValue: resolveStartTime,
        },
        {
          name: "total",
          title: "Total",
          getCellValue: resolveTotal,
        },
        {
          name: "status",
          title: "Status",
          getCellValue: resolveStatus,
        },
        {
          name: "isReviewed",
          title: "Rvd",
          getCellValue: resolveIsReviewed,
        },
      ];
    } else if (isAbove("LG") && isBelow("XL")) {
      return [
        {
          name: "id",
          title: "ID",
          getCellValue: resolveId,
        },
        {
          name: "carPlate",
          title: "Car",
          getCellValue: resolveCarPlate,
        },
        {
          name: "username",
          title: "User",
          getCellValue: resolveUsername,
        },
        {
          name: "startTime",
          title: "Start Date",
          getCellValue: resolveStartTime,
        },
        {
          name: "bill",
          title: "Amount",
          getCellValue: resolveBill,
        },
        {
          name: "discount",
          title: "Discount",
          getCellValue: resolveDiscount,
        },
        {
          name: "total",
          title: "Total",
          getCellValue: resolveTotal,
        },
        {
          name: "status",
          title: "Status",
          getCellValue: resolveStatus,
        },
        {
          name: "isReviewed",
          title: "Rvd",
          getCellValue: resolveIsReviewed,
        },
      ];
    } else {
      return [
        {
          name: "id",
          title: "ID",
          getCellValue: resolveId,
        },
        {
          name: "carPlate",
          title: "Car",
          getCellValue: resolveCarPlate,
        },
        {
          name: "username",
          title: "User",
          getCellValue: resolveUsername,
        },
        {
          name: "startTime",
          title: "Start Date",
          getCellValue: resolveStartTime,
        },
        {
          name: "endTime",
          title: "End Date",
          getCellValue: resolveEndTime,
        },
        {
          name: "totalTime",
          title: "Time",
          getCellValue: resolveTotalTime,
        },
        {
          name: "distance",
          title: "Distance",
          getCellValue: resolveDistance,
        },
        {
          name: "bill",
          title: "Amount",
          getCellValue: resolveBill,
        },
        {
          name: "discount",
          title: "Discount",
          getCellValue: resolveDiscount,
        },
        {
          name: "total",
          title: "Total",
          getCellValue: resolveTotal,
        },
        {
          name: "status",
          title: "Status",
          getCellValue: resolveStatus,
        },
        {
          name: "isReviewed",
          title: "Rvd",
          getCellValue: resolveIsReviewed,
        },
      ];
    }
  };

  const columns = resolveColumns();

  const cellComponent = (props) => {
    if (isBelow("SM")) {
      const renting = props.row;
      return (
        <Table.Cell {...props} padding='none'>
          <div className='relative flex flex-col gap-1 py-2'>
            <div className='flex items-center gap-1 justify-between'>
              {columns.find((c) => c.name === "id").getCellValue(renting)}
              {resolveCarPlate(renting)}
            </div>
            <div className='flex items-center gap-1 justify-between'>
              {resolveUsername(renting)}
            </div>
            <div className='flex items-center gap-1 justify-between'>
              <span>Start: {resolveStartTime(renting)}</span>
              {!!renting.attributes.endTime && <span>End: {resolveEndTime(renting)}</span>}
            </div>
            {!!renting.attributes.endTime && (
              <div className='flex items-center gap-2 justify-between'>
                <div className='flex justify-between gap-0.5'>
                  <span className='max-w-12 truncate'>Amount:</span>{" "}
                  <span className='w-max'>{resolveBill(renting)}</span>
                </div>
                <div className='flex justify-between gap-1'>
                  <span className='max-w-12 truncate'>Discount:</span>{" "}
                  <span className='w-max'>{resolveDiscount(renting)}</span>
                </div>
                <div className='flex justify-between gap-1'>
                  <span className='max-w-12 truncate'>Total:</span>{" "}
                  <span className='w-max'>{resolveTotal(renting)}</span>
                </div>
              </div>
            )}
            <div className='flex items-center gap-1 justify-between'>
              <div className='flex items-center gap-2'>
                <span>Is Reviewed:</span>
                {resolveIsReviewed(renting)}
              </div>
              {resolveStatus(renting)}
            </div>
          </div>
        </Table.Cell>
      );
    } else {
      return <Table.Cell {...props} />;
    }
  };

  const toggleCellComponent = ({ row, expanded, onToggle }) => {
    const hasReservation = !!row.attributes.reservation;

    return (
      <>
        {hasReservation ? (
          <td className='MuiTableCell body cursor-pointer' onClick={() => onToggle(!expanded)}>
            <div className='flex items-center justify-center'>
              <FontAwesomeIcon
                size='sm'
                className='text-text-main'
                icon={expanded ? faChevronUp : faChevronDown}
              />
            </div>
          </td>
        ) : (
          <td className='MuiTableCell body'></td>
        )}
      </>
    );
  };

  const resolveStatusWidth = () => {
    if (isAbove("MD") && isBelow("LG")) {
      return "90";
    } else if (isAbove("LG")) {
      return "120";
    } else {
      return "40";
    }
  };

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <div className='z-10'>
        <Grid rows={rentings} columns={columns} getRowId={(row) => row.id}>
          <SortingState sorting={sorting} onSortingChange={handleSorting} />
          <RowDetailState expandedRowIds={expandedRows} onExpandedRowIdsChange={handleExpand} />
          <PagingState
            currentPage={pagination.page}
            pageSize={pagination.size}
            onCurrentPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
          <CustomPaging totalCount={totalElements} />
          <Table
            columnExtensions={[
              {
                columnName: "id",
                wordWrapEnabled: true,
                width: isAbove("SM") && isBelow("MD") ? "auto" : isBelow("SM") ? "auto" : "140",
              },
              {
                columnName: "carPlate",
                wordWrapEnabled: true,
                width: "80",
              },
              {
                columnName: "username",
                wordWrapEnabled: false,
                width: isBelow("MD") ? "120" : "auto",
              },
              {
                columnName: "startTime",
                wordWrapEnabled: true,
                width: isBelow("LG") ? "90" : "130",
              },
              { columnName: "endTime", wordWrapEnabled: true, width: "140" },
              {
                columnName: "totalTime",
                wordWrapEnabled: true,
                width: "50",
                align: "center",
              },
              {
                columnName: "distance",
                wordWrapEnabled: true,
                width: "100",
                align: "center",
              },
              {
                columnName: "bill",
                wordWrapEnabled: false,
                width: "70",
                align: "center",
              },
              {
                columnName: "discount",
                wordWrapEnabled: false,
                width: "75",
                align: "center",
              },
              {
                columnName: "total",
                wordWrapEnabled: false,
                width: "70",
                align: "center",
              },
              {
                columnName: "status",
                wordWrapEnabled: true,
                width: resolveStatusWidth(),
                align: "center",
              },
              {
                columnName: "isReviewed",
                width: "40",
                align: "center",
              },
            ]}
            cellComponent={cellComponent}
          />
          <TableHeaderRow
            sortLabelComponent={TableHeaderRowSortLabelComponent}
            showSortingControls={true}
          />
          <TableRowDetail
            contentComponent={RentingRowDetail}
            toggleCellComponent={toggleCellComponent}
            toggleColumnWidth={isBelow("LG") ? 14 : 25}
          />
          <PagingPanel pageSizes={[10, 25, 50, 100, 1000]} />
        </Grid>
      </div>
    </div>
  );
};

export default UserRentingsTable;
