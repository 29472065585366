import Parse from "parse";
import Utils from "../../../utils/Utils";
import useCar from "../../../hooks/useCar";
import { useState, useEffect } from "react";
import Loader from "../../../components/Loader";
import CaresPageView from "../view/CaresPageView";
import useToaster from "../../../hooks/useToaster";
import { getViewportWidth } from "../../../utils/Viewport";

const CaresPageState = ({ carId = null, shouldUpdate }) => {
  const carHook = useCar();
  const toaster = useToaster();
  const windowSize = getViewportWidth();
  const [cares, setCares] = useState([]);
  const [filters, setFilters] = useState({});
  const isExternalPage = !Utils.isNull(carId);
  const [isLoading, setLoading] = useState(true);
  const [totalElements, setTotalElements] = useState(0);
  const [isLoadingCare, setLoadingCare] = useState(false);
  const [sorting, setSorting] = useState([{ columnName: "createdAt", direction: "desc" }]);
  const [pagination, setPagination] = useState({
    page: 0,
    size: isExternalPage ? 3 : windowSize === "MOBILE" ? 10 : 25,
  });

  const getCares = async () => {
    const query = new Parse.Query("CarCare");
    query.include(["car"]);

    if (!!carId) {
      const carPointer = {
        __type: "Pointer",
        className: "Car",
        objectId: carId,
      };
      query.equalTo("car", carPointer);
    }

    try {
      if (Object.keys(filters).length > 0) {
        if (filters?.carPlate) {
          let car = await new Parse.Query("Car")
            .matches("carPlate", filters.carPlate.trim(), "i")
            .first({ useMasterKey: true });

          query.equalTo("car", car);
        }

        !!filters.type &&
          query.equalTo("type", Utils.underscoreToSpace(String(filters.type).toUpperCase()));
        !!filters.status && query.equalTo("status", filters.status);
        !!filters.from && query.greaterThanOrEqualTo("createdAt", filters.from);
        !!filters.to && query.lessThanOrEqualTo("createdAt", filters.to);
        !!filters.compFrom && query.greaterThanOrEqualTo("completedAt", filters.compFrom);
        !!filters.compTo && query.lessThanOrEqualTo("completedAt", filters.compTo);
      }

      if (sorting[0]?.direction === "asc") {
        query.ascending(sorting[0].columnName);
      } else {
        query.descending(sorting[0].columnName);
      }
      query.limit(pagination.size);
      query.skip(pagination.page * pagination.size);
      query.withCount(true);
      query
        .find({ useMasterKey: true })
        .then((r) => {
          setCares(r.results);
          setTotalElements(r.count);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const handleFilterChange = (field, value) => {
    setLoading(true);
    if (!!value?.value) {
      setFilters((prev) => ({ ...prev, [field]: value.value }));
    } else if (Array.isArray(value)) {
      setFilters((prev) => ({ ...prev, [field]: value.map((i) => i.value) }));
    } else {
      setFilters((prev) => ({ ...prev, [field]: value }));
    }
    (Utils.isNull(value) || value === "" || value.length === 0) &&
      setFilters((prev) => {
        const obj = { ...prev };
        delete obj[field];
        return obj;
      });
    setPagination({ ...pagination, page: 0 });
  };

  const handleClearFilters = (e) => {
    setLoading(true);
    e.stopPropagation();
    setFilters({});
  };

  const handleSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPagination({ ...pagination, page: page });
  };

  const handlePageSizeChange = (size) => {
    setLoading(true);
    setPagination({ ...pagination, size: size });
  };

  const handleSubmitCare = async (form, careId) => {
    setLoadingCare(true);
    return await carHook
      .handleSubmitCare(form, careId)
      .then(() => {
        setLoadingCare(false);
        toaster.success("Care submitted successfully");
        getCares();
        return true;
      })
      .catch((e) => {
        console.error(e.message);
        toaster.error(e.message);
        return false;
      });
  };

  useEffect(() => {
    getCares();
  }, [filters, sorting, pagination]);

  useEffect(() => {
    if (shouldUpdate) {
      setLoading(true);
      getCares();
    }
  }, [shouldUpdate]);

  return (
    <>
      {cares ? (
        <CaresPageView
          carId={carId}
          cares={cares}
          filters={filters}
          sorting={sorting}
          isLoading={isLoading}
          pagination={pagination}
          isLoadingCare={isLoadingCare}
          totalElements={totalElements}
          handleSorting={handleSorting}
          isExternalPage={isExternalPage}
          handlePageChange={handlePageChange}
          handleSubmitCare={handleSubmitCare}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          handlePageSizeChange={handlePageSizeChange}
        />
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default CaresPageState;
