import Parse from "parse";
import { useEffect, useState } from "react";
import Logger from "../../../models/Logger";
import useUser from "../../../hooks/useUser";
import Modal from "../../../components/Modal";
import Loader from "../../../components/Loader";
import Card from "../../../components/stripe/Card";
import { useConfirm } from "../../../components/ConfirmContext";

const PaymentMethodsModal = ({ user, isOpen, toggleModal }) => {
  const userHook = useUser();
  const showConfirm = useConfirm();
  const session = userHook.getCurrentSession();
  const [isLoading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const getPaymentMethods = async () => {
    const PaymentMethods = await new Parse.Query("PaymentMethod")
      .equalTo("user", {
        __type: "Pointer",
        className: "_User",
        objectId: user.id,
      })
      .find({ useMasterKey: true });
    setPaymentMethods(PaymentMethods);
  };

  const handleDelete = async (paymentMethod) => {
    const extraContent = (
      <div>
        <div>
          Are you sure you want to delete {user.username} - {user.firstName} {user.lastName} payment
          method?
        </div>
      </div>
    );
    const confirmed = await showConfirm(extraContent);
    if (!confirmed) {
      return;
    } else {
      setLoading(true);
      const PM = await new Parse.Query("PaymentMethod")
        .equalTo("objectId", paymentMethod.id)
        .first({ useMasterKey: true });

      if (!!PM) {
        await PM.destroy({ useMasterKey: true });
      }

      Parse.Cloud.run("detachPaymentMethod", {
        paymentMethodId: paymentMethod.attributes.stripeId,
      }).then(async () => {
        await Logger.deleteUserPaymentMethod(session, user, paymentMethod);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    getPaymentMethods();
  }, [isLoading]);

  return (
    <Modal
      open={isOpen}
      title='User Payment Methods'
      classes={"w-4/5 md:w-1/2 xl:w-1/3 text-text-main"}
      handleToggle={() => toggleModal(!isOpen)}
    >
      <Loader isLoading={isLoading} />
      <div className='flex flex-col gap-4'>
        {paymentMethods?.length > 0 ? (
          paymentMethods.map((pm) => {
            return <Card key={pm.id} card={pm} handleDelete={handleDelete} />;
          })
        ) : (
          <div>User has no payment methods set.</div>
        )}
      </div>
    </Modal>
  );
};

export default PaymentMethodsModal;
