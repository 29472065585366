import { dateFormatter } from "../utils/Dates";

export function userSerializer(user) {
  if (!user) return;
  return {
    id: user.id,
    customerId: user.attributes?.customerId ?? null,
    username: user.attributes.username,
    email: user.attributes.email,
    firstName: user.attributes.name,
    lastName: user.attributes.lastName,
    gender: user.attributes.gender,
    phone: user.attributes.phone,
    address: user.attributes.address ?? null,
    city: user.attributes.city ?? null,
    country: user.attributes.country ? countrySerializer(user.attributes.country) : null,
    postalCode: user.attributes.postalCode ?? null,
    dateOfBirth: dateFormatter(user.attributes.dateOfBirth),
    idDocument: {
      front: user.attributes.idFront?._url,
      back: user.attributes.idBack?._url,
    },
    license: {
      id: user.attributes.licenseNo,
      front: user.attributes.licenseFront?._url,
      back: user.attributes.licenseBack?._url,
      issue: dateFormatter(user.attributes.licenseIssueDate),
      expire: dateFormatter(user.attributes.licenseExpiryDate),
    },
    licenseIntl: {
      front: user.attributes.licenseIntlFront?._url ?? null,
      back: user.attributes.licenseIntlBack?._url ?? null,
    },
    residencyProof: {
      proof1: user.attributes.proof1?._url ?? null,
      proof2: user.attributes.proof2?._url ?? null,
    },
    selfie: user.attributes.selfie?._url ?? null,
    emailVerified: user.attributes.emailVerified,
    phoneVerified: user.attributes.phoneVerified,
    isAuthorized: user.attributes.isAuthorized,
    isRejected: user.attributes.isRejected,
    isEnabled: user.attributes.isEnabled,
    isLocked: user.attributes.isLocked,
    isReviewed: user.attributes.isReviewed,
    isLoggedIn: user.attributes.isLoggedIn,
    referredBy: user.attributes.referredBy?.id,
    notes: user.attributes.notes,
    hasPaymentMethod: user.attributes.hasPaymentMethod,
    acceptTerms: user.attributes.acceptTerms,
    createdAt: dateFormatter(user.attributes.createdAt, true),
    updatedAt: dateFormatter(user.attributes.updatedAt, true),
    authorizedAt: dateFormatter(user.attributes.authorizedAt, true),
  };
}

export function rentingSerializer(renting) {
  return {
    id: renting.id,
    driver:
      typeof renting.get === "function"
        ? userSerializer(renting.get("driver"))
        : userSerializer(renting.attributes.driver),
    car:
      typeof renting.get === "function"
        ? carSerializer(renting.get("car"))
        : carSerializer(renting.attributes.car),
    carPhotos: {
      front: renting.attributes.carFront?._url,
      back: renting.attributes.carBack?._url,
      left: renting.attributes.carLeft?._url,
      right: renting.attributes.carRight?._url,
    },
    damage: renting.attributes.damageReport,
    reservation: !!renting.attributes.reservation
      ? reservationSerializer(renting.attributes.reservation)
      : null,
    time: {
      startTime: dateFormatter(renting.attributes.startTime, true),
      startRaw: renting.attributes.startTime,
      endTime: dateFormatter(renting.attributes.endTime, true),
      endRaw: renting.attributes.endTime,
      totalTime: renting.attributes.totalTime,
    },
    address: {
      startAddress: renting.attributes.startAddress,
      endAddress: renting.attributes.endAddress,
    },
    location: {
      start: {
        latitude: renting.attributes.startLatitude,
        longitude: renting.attributes.startLongitude,
      },
      end: {
        latitude: renting.attributes.endLatitude,
        longitude: renting.attributes.endLongitude,
      },
    },
    timeUnit: renting.attributes.timeUnit,
    bill: renting.attributes.bill,
    billKm: renting.attributes.billKm,
    totalBill: renting.attributes.totalBill ?? null,
    discount: !!renting.attributes.discount
      ? {
          type: renting.attributes.discount.type,
          amount: renting.attributes.discount.amount,
          applies: renting.attributes.discount.applies ?? "",
        }
      : null,
    startKm: renting.attributes.startKm,
    endKm: renting.attributes.endKm,
    reservationBill: renting.attributes.reservation?.attributes?.bill ?? 0,
    distance: renting.attributes.distance,
    isFinished: renting.attributes.isFinished,
    isSplitBilling: renting.attributes.isSplitBilling,
    isReviewed: renting.attributes.isReviewed,
    isPaid: renting.attributes.isPaid,
    payment: renting.attributes.payment
      ? paymentSerializer(renting.attributes.payment, true)
      : null,
    status: renting.attributes.status,
    feedback: {
      starRating: renting.attributes.starRating,
      cleanRating: renting.attributes.cleanRating,
      reasonOfUse: renting.attributes.reasonOfUse,
      peopleCount: renting.attributes.peopleCount,
    },
    notes: renting.attributes.notes,
    createdAt: dateFormatter(renting.attributes.createdAt, true),
    updatedAt: dateFormatter(renting.attributes.updatedAt, true),
  };
}

export function reservationSerializer(reservation) {
  return {
    id: reservation.id,
    bill: reservation.attributes.bill,
    status: reservation.attributes.status,
    time: {
      startTime: dateFormatter(reservation.attributes.start, true),
      startRaw: reservation.attributes.start,
      endTime: dateFormatter(reservation.attributes.end, true),
      endRaw: !!reservation.attributes.end ? reservation.attributes.end : null,
    },
    createdAt: dateFormatter(reservation.attributes.createdAt, true),
    updatedAt: dateFormatter(reservation.attributes.updatedAt, true),
  };
}

export function carSerializer(car) {
  if (!car) return;
  let c = {
    id: car.id,
    carPlate: car.attributes.carPlate,
    brand: car.attributes.brand,
    model: car.attributes.model,
    autoTransmission: car.attributes.autoTransmission,
    address: car.attributes.address,
    city: car.attributes.city,
    fuel: car.attributes.fuel,
    fuelType: car.attributes.fuelType ?? null,
    location: {
      latitude: car.attributes.latitude,
      longitude: car.attributes.longitude,
    },
    kmTraveled: car.attributes.kmTraveled,
    initialMileage: car.attributes.initialMileage,
    manufacturingDate: car.attributes.manufacturingDate,
    price: car.attributes.price,
    priceHourly: car.attributes.priceHourly,
    seatsNum: car.attributes.seatsNum,
    isRiding: car.attributes.isRiding,
    isAvailable: car.attributes.isAvailable,
    underMaintenance: car.attributes.underMaintenance,
    isPetFriendly: car.attributes.isPetFriendly,
    hasBluetooth: car.attributes.hasBluetooth,
    hasChildSeat: car.attributes.hasChildSeat,
    inversQNR: car.attributes.inversQNR,
    photo: car.attributes.photo?._url,
    owner: !!car.attributes.owner?.attributes.carProviderName
      ? carProvidersSerializer(car.attributes.owner)
      : null,
    notes: car.attributes.notes,
    createdAt: dateFormatter(car.attributes.createdAt, false),
    updatedAt: dateFormatter(car.attributes.updatedAt, true),
    latestRide: car.latestRide ? dateFormatter(car.latestRide) : null,
    maintenance: car.maintenance ? maintenanceSerializer(car.maintenance) : null,
  };

  if (car.attributes.inversStatus) {
    c = {
      ...c,
      inversStatus: {
        ignition: car.attributes.inversStatus.ignition,
        central_lock: car.attributes.inversStatus.central_lock,
        immobilizer: car.attributes.inversStatus.immobilizer,
        LowBatteryLevelAlarm: car.attributes.inversStatus.LowBatteryLevelAlarm,
      },
    };
  }

  return c;
}

export function discountSerializer(discount) {
  return {
    id: discount.id,
    type: discount.attributes.type,
    amount: discount.attributes.amount,
    isUsed: discount.attributes.isUsed,
    addedBy: discount.attributes.addedBy,
    user: discount.attributes.user ? userSerializer(discount.attributes.user) : null,
    expiresAt: dateFormatter(discount.attributes.expiresAt, true),
    createdAt: dateFormatter(discount.attributes.createdAt, true),
    updatedAt: dateFormatter(discount.attributes.updatedAt, true),
  };
}

export function paymentSerializer(payment, isFromRentingSerializer = false) {
  const paymentObject = {
    id: payment.id,
    isApplePay: payment.attributes.isApplePay,
    isGooglePay: payment.attributes.isGooglePay,
    isRefunded: payment.attributes.isRefunded,
    data: payment.attributes.data,
    status: payment.attributes.status,
    stripePaymentId: payment.attributes.stripePaymentId,
    refund: payment.attributes.refund ? refundSerializer(payment.attributes.refund) : null,
    user: userSerializer(payment.attributes.user),
    usedDiscount: payment.attributes.usedDiscount
      ? discountSerializer(payment.attributes.usedDiscount)
      : null,
    createdAt: dateFormatter(payment.attributes.createdAt, true),
    updatedAt: dateFormatter(payment.attributes.updatedAt, true),
  };

  if (!isFromRentingSerializer) {
    paymentObject["renting"] = rentingSerializer(payment.attributes.renting);
  }

  return paymentObject;
}

export function refundSerializer(refund) {
  return {
    id: refund.id,
    payment: refund.attributes.payment,
    admin: refund.attributes.admin,
    user: userSerializer(refund.attributes.user),
    stripeRefundId: refund.attributes.stripeRefundId,
    amount: refund.attributes.amount,
    status: refund.attributes.status,
    reason: refund.attributes.reason,
    data: refund.attributes.data,
    createdAt: dateFormatter(refund.attributes.createdAt, true),
    updatedAt: dateFormatter(refund.attributes.updatedAt, true),
  };
}

export function carProvidersSerializer(owner) {
  if (!!owner.carProviderName) {
    return owner;
  } else {
    return {
      id: owner.id,
      carProviderName: owner.attributes.carProviderName,
      createdAt: dateFormatter(owner.attributes.createdAt, true),
      updatedAt: dateFormatter(owner.attributes.updatedAt, true),
    };
  }
}

export function damageSerializer(damage) {
  return {
    id: damage.id,
    user: userSerializer(damage.attributes.user),
    car: carSerializer(damage.attributes.car),
    images: {
      front: damage.frontDamage,
      back: damage.backDamage,
      left: damage.leftDamage,
      right: damage.rightDamage,
      other: damage.otherDamage,
    },
    description: damage.description,
    isFixed: damage.attributes.isFixed,
    createdAt: dateFormatter(damage.attributes.createdAt, true),
  };
}

export function adminLogsSerializer(log) {
  return {
    id: log.id,
    user: userSerializer(log.attributes.user),
    action: log.attributes.action,
    extra: log.attributes.extra,
    createdAt: dateFormatter(log.attributes.createdAt, true),
  };
}

export function maintenanceSerializer(maintenance) {
  return {
    id: maintenance.id,
    status: maintenance.attributes.status,
    adminComment: maintenance.attributes.adminComment,
    maintainerComment: maintenance.attributes.maintainerComment,
    car: carSerializer(maintenance.attributes.car),
    user: userSerializer(maintenance.attributes.user),
    startTime: dateFormatter(maintenance.attributes.startTime, false),
    endTime: maintenance.attributes.endTime
      ? dateFormatter(maintenance.attributes.endTime, false)
      : null,
    photos: {
      one: maintenance.attributes.photo1?._url,
      two: maintenance.attributes.photo2?._url,
      three: maintenance.attributes.photo3?._url,
      four: maintenance.attributes.photo4?._url,
    },
  };
}

export function jobSerializer(job) {
  return {
    id: job.id,
    enum: job.attributes.enum,
    name: job.attributes.name,
    nameEL: job.attributes.nameEL,
  };
}

export function countrySerializer(country) {
  return {
    id: country.id,
    code: country.attributes.code,
    name: country.attributes.name,
    native: country.attributes.native,
    icon: country.attributes.emoji,
  };
}

export function userMessageSerializer(message) {
  return {
    id: message.id,
    reciever: userSerializer(message.attributes.user),
    sender: userSerializer(message.attributes.sentBy),
    type: message.attributes.type,
    title: message.attributes.title,
    message: message.attributes.message,
    seen: message.attributes.seen,
    createdAt: message.attributes.createdAt,
  };
}

export function incidentSerializer(incident) {
  return {
    id: incident.id,
    renting: rentingSerializer(incident.attributes.renting),
    submittedBy: userSerializer(incident.attributes.submittedBy),
    description: incident.attributes.description,
    date: dateFormatter(incident.attributes.date),
    carDamage: incident.attributes.carDamage,
    injury: incident.attributes.injury,
    location: incident.attributes.location,
    files: incident.attributes.files,
    createdAt: dateFormatter(incident.attributes.createdAt, true),
  };
}

export function commSerializer(comm) {
  return {
    id: comm.id,
    user: userSerializer(comm.attributes.user),
    admin: userSerializer(comm.attributes.admin),
    subject: comm.attributes.subject,
    content: comm.attributes.content,
    type: comm.attributes.type,
    priority: comm.attributes.priority,
    status: comm.attributes.status,
    tags: comm.attributes.tags,
    attachment: comm.attributes.attachment?._url,
    renting: !!comm.attributes.renting ? rentingSerializer(comm.attributes.renting) : null,
    communicationDate: dateFormatter(comm.attributes.communicationDate, true),
    followupDate: dateFormatter(comm.attributes.followupDate, true),
    createdAt: dateFormatter(comm.attributes.createdAt, true),
    updatedAt: dateFormatter(comm.attributes.updatedAt, true),
  };
}

export function userMessageTemplateSerializer(template) {
  return {
    id: template.id,
    type: template.attributes.type,
    el: { title: template.attributes.title_el, message: template.attributes.message_el },
    en: { title: template.attributes.title_en, message: template.attributes.message_en },
  };
}

export function campaignSerializer(campaign) {
  return {
    id: campaign.id,
    code: campaign.attributes.code,
    discountAmount: campaign.attributes.discountAmount,
    discountType: campaign.attributes.discountType,
    start: dateFormatter(campaign.attributes.start, true),
    end: dateFormatter(campaign.attributes.end, true),
    createdAt: dateFormatter(campaign.attributes.createdAt, true),
    updatedAt: dateFormatter(campaign.attributes.updatedAt, true),
  };
}

export function happyHourSerializer(happyHour) {
  return {
    id: happyHour.id,
    text: happyHour.attributes.text,
    type: happyHour.attributes.type,
    discount: happyHour.attributes.discount,
    isEnabled: happyHour.attributes.isEnabled,
    startsAt: dateFormatter(happyHour.attributes.startsAt, true),
    endsAt: dateFormatter(happyHour.attributes.endsAt, true),
    createdAt: dateFormatter(happyHour.attributes.createdAt, true),
    updatedAt: dateFormatter(happyHour.attributes.updatedAt, true),
  };
}

export function invoiceSerializer(invoice) {
  return {
    id: invoice.id,
    admin: invoice.attributes.admin,
    user: userSerializer(invoice.attributes.user),
    type: invoice.attributes.type,
    amount: invoice.attributes.amount,
    collectionMethod: invoice.attributes.collectionMethod,
    status: invoice.attributes.status,
    data: invoice.attributes.data,
    stripeInvoiceId: invoice.attributes.stripeInvoiceId,
    dueDate: invoice.attributes.dueDate ? dateFormatter(invoice.attributes.dueDate, false) : null,
    closedAt: invoice.attributes.closedAt ? dateFormatter(invoice.attributes.closedAt, true) : null,
    createdAt: dateFormatter(invoice.attributes.createdAt, true),
    updatedAt: dateFormatter(invoice.attributes.updatedAt, true),
  };
}
