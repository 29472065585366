import { useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";

const useToaster = () => {
  const theme = useSelector((state) => state?.theme);

  const info = (details, duration = null) => {
    toast.info(details, {
      theme: String(theme).toLowerCase(),
      draggable: true,
      autoClose: !!duration ? duration : 5000,
      closeOnClick: true,
      pauseOnHover: true,
      transition: Bounce,
      progress: undefined,
      position: "top-right",
      hideProgressBar: false,
    });
  };

  const success = (details, duration = null) => {
    toast.success(details, {
      theme: String(theme).toLowerCase(),
      draggable: true,
      autoClose: !!duration ? duration : 5000,
      closeOnClick: true,
      pauseOnHover: true,
      transition: Bounce,
      progress: undefined,
      position: "top-right",
      hideProgressBar: false,
    });
  };

  const error = (details, duration = null) => {
    toast.error(details, {
      theme: String(theme).toLowerCase(),
      draggable: true,
      autoClose: !!duration ? duration : 5000,
      closeOnClick: true,
      pauseOnHover: true,
      transition: Bounce,
      progress: undefined,
      position: "top-right",
      hideProgressBar: false,
    });
  };

  return {
    info,
    error,
    success,
  };
};

export default useToaster;
