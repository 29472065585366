import Parse from "parse";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import Logger from "../../../models/Logger";
import useUser from "../../../hooks/useUser";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import useRenting from "../../../hooks/useRenting";
import RentingModel from "../../../models/Renting";
import RentingPageView from "../view/RentingPageView";
import useToaster from "../../../hooks/useToaster.js";
import Card from "../../../components/stripe/Card.js";
import { object, date, ref, string, number } from "yup";
import { dateFormatter } from "../../../utils/Dates.js";
import useRentalReport from "../../../hooks/useRentalReport.js";
import { useConfirm } from "../../../components/ConfirmContext.js";
import { countrySerializer, rentingSerializer } from "../../../models/serializers";

const RentingPageState = () => {
  const userHook = useUser();
  const toaster = useToaster();
  const showConfirm = useConfirm();
  const rentingHook = useRenting();
  const [car, setCar] = useState();
  const { rentingId } = useParams();
  const [trip, setTrip] = useState(null);
  const [events, setEvents] = useState();
  const [driver, setDriver] = useState();
  const [renting, setRenting] = useState();
  const rentalReportHook = useRentalReport();
  const session = userHook.getCurrentSession();
  const [totalBill, setTotalBill] = useState();
  const [rentingRaw, setRentingRaw] = useState();
  const [isLoading, setLoading] = useState(false);
  const [mapBounds, setMapBounds] = useState(null);
  const [incidents, setIncidents] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoadingReport, setLoadingReport] = useState(false);

  const getMapBounds = async () => {
    Parse.Config.get().then((config) => {
      const newData = config.attributes.parkingMap.map((i) => ({ lat: i[1], lng: i[0] }));
      setMapBounds(newData);
    });
  };

  const validationSchema = object().shape({
    startLocation: string().required("Start coordinates is required"),
    endLocation: string().required("End coordinates is required"),
    endKm: number()
      .required("End coordinates is required")
      .typeError("End Mileage must be a number!")
      .min(ref("startKm"), "End mileage must be greater than start mileage"),
    startTime: date().required("Start time is required"),
    endTime: date()
      .required("End time is required")
      .min(ref("startTime"), "End time cannot be earlier than start time"),
  });

  const formik = useFormik({
    initialValues: {
      bill: "",
      totalTime: "",
      distance: "",
      startAddress: "",
      endAddress: "",
      startTime: null,
      endTime: null,
      startLocation: "",
      endLocation: "",
      peopleCount: "",
      reasonOfUse: "",
      starRating: "",
      startKm: "",
      endKm: "",
    },
    validationSchema: validationSchema,
  });

  const getRenting = async () => {
    setLoading(true);
    const Renting = Parse.Object.extend("Rentings");
    const query = new Parse.Query(Renting);
    query.equalTo("objectId", rentingId);
    query.include([
      "car",
      "driver",
      "payment",
      "incident",
      "feedback",
      "reservation",
      "payment.usedDiscount",
      "payment.refund",
    ]);

    try {
      const response = await query.first({ useMasterKey: true });
      setRentingRaw(response);
      const billCalculated = RentingModel.provideTotalBill([response])[0];
      setTotalBill({
        bill: billCalculated.attributes.bill,
        discount: billCalculated.attributes.discount,
        totalBill: billCalculated.attributes.totalBill,
        totalBillRaw: billCalculated.attributes.totalBillRaw,
      });

      let r = rentingSerializer(billCalculated);

      if (r.driver.country) {
        const country = countrySerializer(
          await new Parse.Query("Continentscountriescities_Country")
            .equalTo("objectId", r.driver.country.id)
            .first()
        );
        r = { ...r, driver: { ...r.driver, country: country } };
      }

      const u = r.driver;
      const c = r.car;
      setRenting(r);
      setDriver(u);
      setCar(c);

      formik.setValues({
        bill: r.bill,
        totalTime: r.time.totalTime,
        distance: r.distance,
        startAddress: r.address.startAddress,
        endAddress: r.address.endAddress,
        startTime: r.time.startRaw,
        endTime: !!r.time.endRaw ? dayjs(r.time.endRaw) : dayjs(),
        startLocation: r.location.start.latitude + "," + r.location.start.longitude,
        endLocation:
          r.location.end.latitude && r.location.end.longitude
            ? r.location.end.latitude + "," + r.location.end.longitude
            : null,
        peopleCount: r.feedback.peopleCount,
        reasonOfUse: r.feedback.reasonOfUse,
        starRating: r.feedback.starRating,
        startKm: r.startKm,
        endKm: r.endKm,
      });
      setLoading(false);
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const getTrip = async () => {
    const rentingPointer = {
      __type: "Pointer",
      className: "Rentings",
      objectId: rentingId,
    };

    const trip = await new Parse.Query("Trip").equalTo("renting", rentingPointer).first();

    setTrip(trip);
  };

  const getEvents = async () => {
    const Events = await new Parse.Query("UserLog")
      .equalTo("renting", {
        __type: "Pointer",
        className: "Rentings",
        objectId: rentingId,
      })
      .descending("createdAt")
      .find({ useMasterKey: true });

    setEvents(Events);
  };

  const getIncidents = async () => {
    try {
      const rentingPointer = {
        __type: "Pointer",
        className: "Rentings",
        objectId: rentingId,
      };

      const incidents = await new Parse.Query("Incident").equalTo("renting", rentingPointer).find();

      incidents.length > 0 && setIncidents(incidents);
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleFormChange = (field, value) => {
    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  };

  const handleSave = async () => {
    const extraContent = (
      <div>
        <div>
          This action is irreversible.
          <br /> Please confirm you want to save and close the renting by submitting the following
          values:
        </div>
        <div className='mt-4'>
          <div className='flex items-center gap-5'>
            <span className='font-bold'>Start Time:</span>
            <span>{dateFormatter(formik.values.startTime, true)}</span>
          </div>
          <div className='flex items-center gap-5'>
            <span className='font-bold'>End Time:</span>
            <span>{dateFormatter(formik.values.endTime, true)}</span>
          </div>
        </div>
      </div>
    );

    const confirmed = await showConfirm(extraContent);

    if (!confirmed) {
      return;
    } else {
      if (!formik.isValid) {
        Object.keys(formik.errors).forEach((key) => toaster.error(formik.errors[key]));
      } else {
        setLoading(true);
        toaster.info("Please wait... This will take a while...");
        try {
          const carPrice = car.price;
          const carPriceHourly = car.priceHourly;
          const timeUnit = renting.timeUnit;
          const startKm = renting.startKm;
          const startTime = dayjs(formik.values.startTime).toDate();
          const endTime = dayjs(formik.values.endTime).toDate();
          const startLatitude = formik.values.startLocation.split(",")[0].trim();
          const startLongitude = formik.values.startLocation.split(",")[1].trim();
          const startAddress = renting.address.starAddress;
          const endLatitude = formik.values.endLocation.split(",")[0].trim();
          const endLongitude = formik.values.endLocation.split(",")[1].trim();

          toaster.info("Fetching car's current address...");
          const endAddress = await Parse.Cloud.run("getAddress", {
            latitude: endLatitude,
            longitude: endLongitude,
          });

          // Fetch and store car's tracking data from invers to b4a cloud
          toaster.info(
            "Fetching car's tracking data from invers and storing to database...",
            10000
          );
          await Parse.Cloud.run("pollSingleVehicleTracking", { deviceId: car.inversQNR });

          toaster.info("Creating the ride's trip...");
          const tracking = await Parse.Cloud.run("fetchVehicleTracking", {
            sinceDate: startTime.toISOString(),
            deviceId: car.inversQNR,
          });

          const Renting = new Parse.Object("Rentings");
          let distance = 0;

          // Handle missing or empty tracking data
          if (!!!tracking.data || tracking.data.length === 0) {
            // Create a default trip with no movement
            const Trip = new Parse.Object("Trip");
            Trip.set("renting", {
              __type: "Pointer",
              className: "Rentings",
              objectId: renting.id,
            });
            Trip.set("trackingData", { message: "No tracking data available" });
            Trip.set("locationData", []); // Empty location data
            await Trip.save();
          } else {
            const parsedTrackingData = tracking.data.map((item) => ({
              latitude: item.latitude,
              longitude: item.longitude,
              timestamp: item.trackingTimestamp,
            }));

            // Save trip data
            const Trip = new Parse.Object("Trip");
            Trip.set("renting", {
              __type: "Pointer",
              className: "Rentings",
              objectId: renting.id,
            });
            Trip.set("trackingData", tracking); // Full tracking data
            Trip.set("locationData", parsedTrackingData); // Simplified location data
            await Trip.save();

            // Calculate total distance traveled if there are at least two points
            if (parsedTrackingData.length > 1) {
              distance = parsedTrackingData
                .reduce((dist, curr, i, arr) => {
                  if (i === 0) return dist;
                  return dist + RentingModel.distanceCalculator(arr[i - 1], curr) * 1000;
                }, 0)
                .toFixed(2);
            }
          }

          toaster.info("Calculating ride's time, billing and distance...");

          // Total time is in seconds. This will be stored in the database.
          const totalTime = Math.floor((endTime.getTime() - startTime.getTime()) / 1000);
          // Passed min is total time in minutes. This will be sent to cloud function to calculate total bill.
          //  Calculate billing in parallel
          const passedTime =
            timeUnit === RentingModel.TIME_UNIT.HOUR
              ? Math.ceil(totalTime / 3600)
              : Math.ceil(totalTime / 60);

          const bill = await Parse.Cloud.run("billCalculator", {
            rentingId: rentingId,
            passedTime: Number(passedTime),
            price:
              timeUnit === RentingModel.TIME_UNIT.HOUR ? Number(carPriceHourly) : Number(carPrice),
          });

          let billKm = 0;
          if (timeUnit === RentingModel.TIME_UNIT.HOUR) {
            billKm = (
              await Parse.Cloud.run("calculateMileageCost", {
                startKm: Number(startKm),
                hours: Number(passedTime),
                endKm: Number(formik.values.endKm),
              })
            ).totalCost;
          }

          Renting.set("objectId", rentingId);
          Renting.set("bill", bill);
          Renting.set("startTime", startTime);
          Renting.set("endTime", endTime);
          Renting.set("totalTime", totalTime);
          Renting.set("distance", Number(distance));
          Renting.set("startLatitude", Number(startLatitude));
          Renting.set("startLongitude", Number(startLongitude));
          Renting.set("endLatitude", Number(endLatitude));
          Renting.set("endLongitude", Number(endLongitude));
          Renting.set("startAddress", startAddress);
          Renting.set("endAddress", endAddress);
          Renting.set("endKm", Number(formik.values.endKm));
          Renting.set("billKm", Number(billKm));
          Renting.set("isFinished", true);
          Renting.set("status", 1);

          toaster.info("Saving and closing ride...");
          await Renting.save().then(async () => {
            await Logger.editRenting(session, Renting);

            const carId = rentingSerializer(Renting).car.id;
            const Car = new Parse.Object("Car");
            Car.set("objectId", carId);
            Car.set("isRiding", false);

            toaster.info("Making the car available again...");
            await Car.save();
          });
          await getRenting();
          await getTrip();
          setIsEditing(false);
          toaster.success("Procedure completed successfully!");
        } catch (e) {
          setLoading(false);
          console.error(e.message);
          toaster.error(e.message);
        }
      }
    }
  };

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleToggle = async (field, toggle) => {
    setLoading(true);
    const Renting = new Parse.Object("Rentings");
    Renting.set("objectId", renting.id);
    Renting.set(field, toggle);
    try {
      await Renting.save(null, { useMasterKey: true }).finally(() => {
        getRenting();
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleSubmitDocument = async (document, file) => {
    try {
      let fileBase64 = null;
      let fileReader = new FileReader();

      return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
          fileReader.abort();
          reject(console.error("Problem parsing input file."));
        };

        fileReader.onload = async (fileLoadedEvent) => {
          fileBase64 = fileLoadedEvent.target.result;
          const data = fileBase64.split(",")[1];

          const parseFile = new Parse.File(file.name, { base64: data });

          const newDocument = new Parse.Object("Rentings");
          newDocument.set("objectId", rentingId);
          newDocument.set(document, parseFile);

          await newDocument.save(null, { useMasterKey: true }).then(() => {
            getRenting().then(() => {
              resolve("ok");
            });
          });
        };

        fileReader.readAsDataURL(file);
      });
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleNotesSubmit = async (value) => {
    await rentingHook.handleNotesSubmit(value, rentingId);
  };

  const handleSetIsReviewed = async (value) => {
    setLoading(true);
    await rentingHook.handleSetIsReviewed(value, rentingId).then(() => {
      setRenting({
        ...renting,
        isReviewed: value,
      });

      setLoading(false);
    });
  };

  const handleDownloadPDF = async () => {
    setLoadingReport(true);

    const PDF = await rentalReportHook.generate(rentingRaw, false);
    const blob = await pdf(PDF).toBlob();
    saveAs(blob, `${renting.driver.username} rentalReport.pdf`);
    await Logger.downloadRentalReport(session, rentingRaw);
    setLoadingReport(false);
    toaster.success("Rental Report downloaded successfully.");
  };

  const handleCharge = async (paymentMethod) => {
    if (renting.isPaid) {
      toaster.error("The renting is already paid. Charge cannot be applied again.");
      return;
    }
    if (!!renting.payment) {
      toaster.error(
        "The renting has already a payment registered. Charge cannot be applied again."
      );
      return;
    }

    const pm = await new Parse.Query("PaymentMethod")
      .equalTo("stripeId", paymentMethod)
      .first({ useMasterKey: true });

    const extraContent = (
      <div>
        <div className='mb-4'>
          Your action will charge the user. Review the details of the charge below.
        </div>
        <div>
          <div>
            <span className='font-bold'>Amount: </span>
            <span>{parseFloat(renting.totalBill).toFixed(2)}</span>
          </div>
        </div>
        <div>
          <div>
            <span className='font-bold'>Card: </span>
            <span>
              <Card card={pm} />
            </span>
          </div>
        </div>
      </div>
    );

    const confirmed = await showConfirm(extraContent);

    if (!confirmed) {
      return;
    } else {
      setLoading(true);
      try {
        const amount = Math.round(totalBill.totalBill * 100);
        await Parse.Cloud.run("adminCreatePayment", {
          rentingId: rentingId,
          amount: amount,
          paymentMethod: paymentMethod,
        })
          .then(async () => {
            await Logger.applyRentingCharge(session, {
              rentingId: rentingId,
              amount: parseFloat(renting.totalBill).toFixed(2),
              paymentMethod: paymentMethod,
            });
          })
          .catch((error) => {
            if (String(error.message).includes("declined")) {
              console.error("The user's card was declined.");
              toaster.error("The user's card was declined.");
              return;
            } else {
              console.error(error.message);
              toaster.error(error.message);
              return;
            }
          });
        await getRenting();
        setLoading(false);
        toaster.success("Charge done successfully.");
        return true;
      } catch (error) {
        console.error(error.message);
        toaster.error(error.message);
        return;
      }
    }
  };

  const handleRefund = async (form) => {
    try {
      const amount = Math.round(totalBill.totalBill * 100);
      await Parse.Cloud.run("adminRefundPayment", {
        paymentId: renting.payment.stripePaymentId,
        amount: amount,
        reason: form.reason,
        details: form.details,
        adminEmail: session.user.username,
      })
        .then(async () => {
          await Logger.applyPaymentRefund(session, {
            paymentId: renting.payment.id,
            stripePaymentId: renting.payment.stripePaymentId,
            rentingId: renting.id,
            amount: parseFloat(renting.totalBill).toFixed(2),
            reason: form.reason,
            details: form.details,
          });
        })
        .catch((error) => {
          if (String(error.message).includes("declined")) {
            console.error("The user's card was declined.");
            toaster.error("The user's card was declined.");
          } else {
            console.error(error.message);
            toaster.error(error.message);
          }
          return;
        });
      await getRenting();
      setLoading(false);
      toaster.success(
        `Refund of ${Number(parseFloat(amount / 100).toFixed(2))}€ issued successfully.`
      );
      return true;
    } catch (error) {
      console.error(error.message);
      toaster.error(error.message);
      return;
    }
  };

  useEffect(() => {
    getMapBounds();
    getRenting();
    getTrip();
    getEvents();
    getIncidents();
  }, []);

  return (
    <>
      {renting && driver && car && mapBounds ? (
        <RentingPageView
          car={car}
          trip={trip}
          form={formik}
          driver={driver}
          events={events}
          renting={renting}
          mapBounds={mapBounds}
          totalBill={totalBill}
          incidents={incidents}
          isLoading={isLoading}
          isEditing={isEditing}
          rentingRaw={rentingRaw}
          handleEdit={handleEdit}
          handleSave={handleSave}
          handleToggle={handleToggle}
          handleCharge={handleCharge}
          handleRefund={handleRefund}
          isLoadingReport={isLoadingReport}
          handleFormChange={handleFormChange}
          handleNotesSubmit={handleNotesSubmit}
          handleDownloadPDF={handleDownloadPDF}
          handleSetIsReviewed={handleSetIsReviewed}
          handleSubmitDocument={handleSubmitDocument}
        />
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default RentingPageState;
