import dayjs from "dayjs";
import Parse from "parse";
import Car from "../../../models/Car";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import { findOption } from "../../../utils/Forms";
import { carSerializer } from "../../../models/serializers";
import FormSelect from "../../../components/form/FormSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDatepicker from "../../../components/form/FormDatepicker";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { faFilter, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

const Filters = ({ filters, handleFilterChange, handleClearFilters }) => {
  const [cars, setCars] = useState([]);
  const carOptions = cars.map((c) => ({
    value: c.carPlate,
    label: c.carPlate + " - " + c.brand + " " + c.model,
  }));
  const carDefault = !Utils.isNull(filters?.carPlate)
    ? findOption(carOptions, String(filters?.carPlate)?.toUpperCase())
    : null;

  const to = filters?.to ? dayjs(new Date(filters?.to).toISOString()) : null;
  const from = filters?.from ? dayjs(new Date(filters?.from).toISOString()) : null;
  const compTo = filters?.compTo ? dayjs(new Date(filters?.compTo).toISOString()) : null;
  const compFrom = filters?.compFrom ? dayjs(new Date(filters?.compFrom).toISOString()) : null;

  const typeOptions = Object.keys(Car.CARE_TYPES).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(Utils.underscoreToSpace(t)),
  }));
  const typeDefaultOption = !Utils.isNull(filters.type)
    ? findOption(typeOptions, String(filters.type)?.toUpperCase())
    : null;

  const statusOptions = Object.keys(Car.CARE_STATUS).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(Utils.underscoreToSpace(t)),
  }));
  const statusDefaultOption = !Utils.isNull(filters.status)
    ? findOption(statusOptions, String(filters.status)?.toUpperCase())
    : null;

  const handleDateChange = (field, value) => {
    if (Utils.isNull(value)) {
      handleFilterChange(field, null);
      return;
    }

    const v = new Date(value);
    field === "from" ? v.setHours(0, 0, 0) : v.setHours(23, 59, 59);
    handleFilterChange(field, v);
  };

  const getCars = async () => {
    try {
      const response = (
        await new Parse.Query("Car")
          .addAscending("brand")
          .addAscending("model")
          .notEqualTo("inversQNR", "ARISTEST")
          .find({ useMasterKey: true })
      ).map((c) => carSerializer(c));
      setCars(response);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getCars();
  }, []);

  return (
    <div className='filters'>
      <Accordion defaultExpanded={false}>
        <AccordionSummary>
          <div className='title'>
            <div className='flex items-center flex-row gap-2'>
              <FontAwesomeIcon icon={faFilter} />
              Filters
            </div>
            <div className='filter-actions'>
              <div className='flex items-center gap-2 z-20' onClick={handleClearFilters}>
                <FontAwesomeIcon icon={faFilterCircleXmark} />
                Clear Filters
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2'>
            <div>
              <FormSelect
                label='Car Plate'
                isClearable={true}
                value={carDefault}
                options={carOptions}
                placeholder='Car Plate'
                handleChange={(option) => handleFilterChange("carPlate", option)}
              />
            </div>
            <div>
              <FormSelect
                label='Type'
                classes='w-full'
                isClearable={true}
                options={typeOptions}
                value={typeDefaultOption}
                placeholder='Type'
                handleChange={(option) => handleFilterChange("type", option)}
              />
            </div>
            <div>
              <FormSelect
                label='Status'
                classes='w-full'
                isClearable={true}
                options={statusOptions}
                value={statusDefaultOption}
                placeholder='Type'
                handleChange={(option) => handleFilterChange("status", option)}
              />
            </div>
            <div>
              <FormDatepicker
                label='From'
                value={from}
                isClearable={true}
                handleChange={(value) => handleDateChange("from", value)}
              />
            </div>
            <div>
              <FormDatepicker
                label='To'
                value={to}
                isClearable={true}
                handleChange={(value) => handleDateChange("to", value)}
              />
            </div>
            <div>
              <FormDatepicker
                label='Completed From'
                value={compFrom}
                isClearable={true}
                handleChange={(value) => handleDateChange("compFrom", value)}
              />
            </div>
            <div>
              <FormDatepicker
                label='Completed To'
                value={compTo}
                isClearable={true}
                handleChange={(value) => handleDateChange("compTo", value)}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Filters;
