import Utils from "../../../utils/Utils";
import { isBelow } from "../../../utils/Viewport";
import { dateFormatter } from "../../../utils/Dates";
import { Accordion, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faStop,
  faLockOpen,
  faStopwatch,
  faFlagCheckered,
} from "@fortawesome/free-solid-svg-icons";
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
} from "@mui/lab";

const eventIcons = {
  DEFAULT: <FontAwesomeIcon icon={faInfo} className='w-5 h-5' />,
  START_RENT: <FontAwesomeIcon icon={faLockOpen} className='w-5 h-5' />,
  END_RENT: <FontAwesomeIcon icon={faFlagCheckered} className='w-5 h-5' />,
  RESERVE_END: <FontAwesomeIcon icon={faStop} className='w-5 h-5' />,
  RESERVE_START: <FontAwesomeIcon icon={faStopwatch} className='w-5 h-5' />,
};

const RentingEvents = ({ events }) => {
  return (
    <div className='px-0 py-1.5 sm:py-4'>
      <div className='text-center py-3 sm:pb-2 sm:pt-0'>
        <span className='text-2xl font-semibold text-text-main'>Timeline</span>
      </div>
      <Timeline position={`${isBelow("SM") && "right"}`}>
        {events.map((event, i) => {
          const isStart = event.attributes.type.split("_").includes("START");
          const descendingLogMessages = event.attributes.logMessages.sort((a, b) =>
            b.timestamp.localeCompare(a.timestamp)
          );
          return (
            <TimelineItem
              key={event.objectId}
              position={`${isBelow("SM") ? "right" : isStart ? "left" : "right"}`}
            >
              <TimelineSeparator>
                <TimelineDot className='event-logs-timeline-dot'>
                  {eventIcons[event.attributes.type] || eventIcons["DEFAULT"]}
                </TimelineDot>
                {i !== events.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <div className='bg-bg-timeline-content shadow rounded-lg p-3'>
                  <div className='mb-0'>
                    <span className='text-text-main font-bold'>
                      {Utils.underscoreToSpace(event.attributes.type)}
                    </span>
                  </div>
                  <div className='mb-2'>
                    <span className='text-sm italic font-light text-text-main opacity-70'>
                      {dateFormatter(event.attributes.timestamp, true)}
                    </span>
                  </div>
                  <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon className='text-text-main' />}>
                      <div className='p-2'>
                        <span className='text-text-main font-normal'>Event Logs</span>
                      </div>
                    </AccordionSummary>
                    <div className='pl-0.5'>
                      {descendingLogMessages.map((log, i) => (
                        <div
                          key={i}
                          className='mb-2 border-l-4 border-blue-400 px-2 text-left leading-3.5'
                        >
                          <div className='text-gray-600'>
                            <span className='text-xs font-light text-text-main opacity-70'>
                              {dateFormatter(log.timestamp, false, "DD/MM/YYYY HH:mm:ss")}
                            </span>
                          </div>
                          <div className=''>
                            <span
                              className='text-[12px] text-text-main leading-[0px] overflow-hidden'
                              style={{
                                wordBreak: "break-word",
                              }}
                            >
                              {log.message}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Accordion>
                </div>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </div>
  );
};

export default RentingEvents;
