import dayjs from "dayjs";
import Parse from "parse";
import { useRef } from "react";
import { debounce } from "lodash";
import User from "../../../models/User";
import Utils from "../../../utils/Utils";
import { findOption } from "../../../utils/Forms";
import Button from "../../../components/form/Button";
import FormSelect from "../../../components/form/FormSelect";
import FormAgeInput from "../../../components/form/FormAgeInput";
import FormCheckbox from "../../../components/form/FormCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDatepicker from "../../../components/form/FormDatepicker";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { faFileDownload, faFilter, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

const Filters = ({
  filters,
  isLoading,
  isLoadingTotals,
  handleClearFilters,
  handleFilterChange,
  handleApplyFilters,
  handleOpenExportModal,
}) => {
  const phoneRef = useRef(null);
  const usernameRef = useRef(null);
  const lastNameRef = useRef(null);
  const to = filters?.to ? dayjs(filters?.to) : null;
  const from = filters?.from ? dayjs(filters?.from) : null;
  const toAuth = filters?.toAuth ? dayjs(filters?.toAuth) : null;
  const fromAuth = filters?.fromAuth ? dayjs(filters?.fromAuth) : null;

  const genderOptions = Object.keys(User.GENDERS).map((g) => ({
    value: g,
    label: Utils.textFirstOnlyUpper(g),
  }));
  const genderDefaultOption = !Utils.isNull(filters.gender)
    ? findOption(genderOptions, String(filters.gender)?.toUpperCase())
    : null;

  const handleDateChange = (field, value) => {
    if (Utils.isNull(value)) {
      handleFilterChange(field, null);
      return;
    }

    const v = new Date(value);
    if (field === "from" || field === "fromAuth") {
      v.setHours(0, 0, 0);
    } else {
      v.setHours(23, 59, 59);
    }
    handleFilterChange(field, v);
  };

  const getUsersByUsername = async (value) => {
    return (
      await new Parse.Query("_User").matches("username", value, "i").find({ useMasterKey: true })
    ).map((u) => ({
      value: u.attributes.username,
      label: u.attributes.username,
    }));
  };

  const getUsersByPhone = async (value) => {
    return (
      await new Parse.Query("_User").matches("phone", value, "i").find({ useMasterKey: true })
    ).map((u) => ({
      value: u.attributes.phone,
      label: u.attributes.phone,
    }));
  };
  const getUsersByLastName = async (value) => {
    return (
      await new Parse.Query("_User").matches("lastName", value, "i").find({ useMasterKey: true })
    ).map((u) => ({
      value: u.attributes.lastName,
      label: u.attributes.lastName,
    }));
  };

  const debouncedFetchOptions = debounce((value, field, callback) => {
    switch (field) {
      case "username":
        getUsersByUsername(value).then(callback);
        break;
      case "phone":
        getUsersByPhone(value).then(callback);
        break;
      case "lastName":
        getUsersByLastName(value).then(callback);
        break;
      default:
        break;
    }
  }, 300);

  const promiseOptions = (inputValue, field) =>
    new Promise((resolve) => {
      debouncedFetchOptions(inputValue, field, resolve);
    });

  const handleClear = (e) => {
    phoneRef.current.clearValue();
    usernameRef.current.clearValue();
    lastNameRef.current.clearValue();
    handleClearFilters(e);
  };

  return (
    <div className='filters'>
      <Accordion defaultExpanded={false}>
        <AccordionSummary>
          <div className='title'>
            <div className='flex items-center flex-row gap-2'>
              <FontAwesomeIcon icon={faFilter} />
              Filters
            </div>
            <div className='filter-actions'>
              <div className='flex items-center gap-2 z-20' onClick={handleClear}>
                <FontAwesomeIcon icon={faFilterCircleXmark} />
                Clear Filters
              </div>
              <div className='flex items-center gap-2 z-20' onClick={handleOpenExportModal}>
                <FontAwesomeIcon icon={faFileDownload} />
                Export
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-2'>
            <div>
              <FormSelect
                cacheOptions
                defaultOptions
                isAsync={true}
                label='Username'
                ref={usernameRef}
                isClearable={true}
                promiseOptions={(value) => promiseOptions(value, "username")}
                handleChange={(value) => handleFilterChange("username", value?.value)}
              />
            </div>
            <div>
              <FormSelect
                cacheOptions
                label='Phone'
                defaultOptions
                isAsync={true}
                ref={phoneRef}
                isClearable={true}
                promiseOptions={(value) => promiseOptions(value, "phone")}
                handleChange={(value) => handleFilterChange("phone", value?.value)}
              />
            </div>
            <div>
              <FormSelect
                cacheOptions
                defaultOptions
                isAsync={true}
                label='Last Name'
                ref={lastNameRef}
                isClearable={true}
                promiseOptions={(value) => promiseOptions(value, "lastName")}
                handleChange={(value) => handleFilterChange("lastName", value?.value)}
              />
            </div>
            <div>
              <FormSelect
                label='Gender'
                isClearable={true}
                options={genderOptions}
                value={genderDefaultOption}
                handleChange={(value) => handleFilterChange("gender", value.value)}
              />
            </div>
            <div>
              <FormAgeInput
                label='Age'
                handleChangeTo={(value) => handleFilterChange("ageTo", value)}
                handleChangeFrom={(value) => handleFilterChange("ageFrom", value)}
              />
            </div>
            <div>
              <FormDatepicker
                label='From'
                value={from}
                isClearable={true}
                handleChange={(value) => handleDateChange("from", value)}
              />
            </div>
            <div>
              <FormDatepicker
                label='To'
                value={to}
                isClearable={true}
                handleChange={(value) => handleDateChange("to", value)}
              />
            </div>
            <div>
              <FormDatepicker
                label='Auth From'
                value={fromAuth}
                isClearable={true}
                handleChange={(value) => handleDateChange("fromAuth", value)}
              />
            </div>
            <div>
              <FormDatepicker
                label='Auth To'
                value={toAuth}
                isClearable={true}
                handleChange={(value) => handleDateChange("toAuth", value)}
              />
            </div>
            <div className='col-span-1 sm:col-span-2 lg:col-span-4 flex items-end gap-2'>
              <div className='flex flex-wrap items-center gap-2'>
                <FormCheckbox
                  label='Locked'
                  value={filters.isLocked}
                  handleChange={(checked) => handleFilterChange("isLocked", checked)}
                />
                <FormCheckbox
                  label='Has Note'
                  value={filters.hasNotes}
                  handleChange={(checked) => handleFilterChange("hasNotes", checked)}
                />
                <FormCheckbox
                  label='Is Disabled'
                  value={filters.isDisabled}
                  handleChange={(checked) => handleFilterChange("isDisabled", checked)}
                />
                <FormCheckbox
                  label='Has Payment Method'
                  value={filters.hasPaymentMethod}
                  handleChange={(checked) => handleFilterChange("hasPaymentMethod", checked)}
                />
              </div>
            </div>
            <div className='flex items-end justify-end col-span-1 sm:col-span-2 lg:col-span-1'>
              <Button
                classes={`bg-blue-500 text-white px-2 py-1 w-full sm:w-1/2 lg:w-full`}
                handleClick={handleApplyFilters}
                disabled={isLoading || isLoadingTotals}
                isLoading={isLoading || isLoadingTotals}
              >
                Apply
              </Button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Filters;
