export default class Admin {
  static ACTIONS = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    EDIT_USER: "EDIT USER",
    DELETE_USER: "DELETE USER",
    AUTHORIZE_USER: "AUTHORIZE USER",
    RESET_USER_TERMS: "RESET USER TERMS",
    SEND_USER_EMAIL: "SEND USER EMAIL",
    SEND_USER_MESSAGE: "SEND USER MESSAGE",
    ADD_USER_COMM: "ADD USER COMMUNICATION",
    EDIT_USER_COMM: "EDIT USER COMMUNICATION",
    DELETE_USER_COMM: "DELETE USER COMMUNICATION",
    DELETE_USER_PAYMENT_METHOD: "DELETE USER PAYMENT METHOD",
    ADD_USER_NOTES: "ADD USER NOTES",
    ADD_DISCOUNT: "ADD DISCOUNT",
    EDIT_RENTING: "EDIT RENTING",
    ADD_RENTING_NOTES: "ADD RENTING NOTES",
    ADD_CAR: "ADD CAR",
    EDIT_CAR: "EDIT CAR",
    EDIT_CAR_CARE: "EDIT CAR CARE",
    EDIT_CAR_CARE_SETTINGS: "EDIT CAR CARE SETTINGS",
    ADD_CAR_NOTES: "ADD CAR NOTES",
    DOWNLOAD_RENTAL_REPORT: "DOWNLOAD RENTAL REPORT",
    EDIT_GENERAL_SETTINGS: "EDIT GENERAL SETTINGS",
    ADD_CAMPAIGN: "ADD CAMPAIGN",
    EDIT_CAMPAIGN: "EDIT CAMPAIGN",
    EDIT_HAPPY_HOUR: "EDIT HAPPY HOUR",
    APPLY_RENTING_CHARGE: "APPLY RENTING CHARGE",
    APPLY_PAYMENT_REFUND: "APPLY PAYMENT REFUND",
    ISSUE_INVOICE: "ISSUE INVOICE",
    EDIT_INVOICE: "EDIT INVOICE",
    RETRY_INVOICE_PAYMENT: "RETRY INVOICE PAYMENT",
  };
}
