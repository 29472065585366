import Parse from "parse";
import dayjs from "dayjs";
import { useFormik } from "formik";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import { array, date, object, string } from "yup";
import { findOption } from "../../../utils/Forms";
import Button from "../../../components/form/Button";
import { dateFormatter } from "../../../utils/Dates";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import FormSelect from "../../../components/form/FormSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormTextarea from "../../../components/form/FormTextarea";
import FormDatepicker from "../../../components/form/FormDatepicker";

const MaintenanceForm = ({
  car,
  jobs,
  selectedJobs,
  setSelectedJobs,
  activeMaintenance,
  isLoadingMaintenance,
  handleStartMaintenance,
  handleCloseMaintenance,
  handleChangeMaintainer,
}) => {
  const now = dayjs();
  const [allJobs, setAllJobs] = useState([]);
  const [latestJobs, setLatestJobs] = useState([]);
  const [maintainers, setMaintainers] = useState([]);
  const [openMaintenance, setOpenMaintenance] = useState(false);

  const validationSchema = object().shape({
    adminComment: string().nullable(),
    maintainer: string().required("Maintainer cannot be empty"),
    maintenanceDate: date()
      .required("Maintenance date is required")
      .min(dayjs().startOf("day"), "End time cannot be earlier than today"),
    jobs: array()
      .required("You must select at least 1 job")
      .test({
        message: "You must select at least 1 job",
        test: (arr) => arr.length >= 1,
      }),
  });

  const formik = useFormik({
    initialValues: {
      jobs: [],
      maintenanceDate: dayjs().startOf("day"),
      maintainer: !!activeMaintenance ? activeMaintenance?.attributes?.user?.id : null,
      adminComment: !!activeMaintenance ? activeMaintenance?.attributes?.adminComment : "",
    },
    validationSchema: validationSchema,
  });

  const defaultMaintainer = !Utils.isNull(formik.values.maintainer)
    ? findOption(maintainers, formik.values.maintainer)
    : null;

  const getLatestJobs = async () => {
    const maintenanceJobs = await new Parse.Query("MaintenanceJob").aggregate({
      addFields: {
        carPointer: { $substr: ["$_p_car", 4, -1] },
      },
      lookup: {
        from: "Car",
        localField: "carPointer",
        foreignField: "_id",
        as: "carDetails",
      },
      unwind: "$carDetails",
      match: {
        "carDetails._id": { $eq: car.id },
        isDone: true,
      },
      sort: { createdAt: -1 },
      group: {
        objectId: "$job", // Group by Job type
        latestJob: { $last: "$$ROOT" }, // Take the last (latest) job per job type
      },
    });

    const allJobs = await new Parse.Query("Job")
      .equalTo("deprecated", false)
      .find({ useMasterKey: true });

    setAllJobs(allJobs);

    const jobsDone = maintenanceJobs.map((mj) => {
      const jobId = mj.latestJob._p_job.substring(4);
      const Job = new Parse.Object("Job").set("objectId", jobId);
      return { date: dayjs(mj.latestJob.doneDate.iso), job: Job };
    });

    setLatestJobs(jobsDone);
  };

  const getMaintainers = async () => {
    const role = await new Parse.Query(Parse.Role)
      .equalTo("name", "Maintainer")
      .first({ useMasterKey: true });
    const maintainers = await role.relation("users").query().find({ useMasterKey: true });

    setMaintainers(
      maintainers.map((m) => ({
        value: m.id,
        label: m.attributes.name + " " + m.attributes.lastName,
      }))
    );
  };

  const checkJobMatch = (jobEnum) => {
    return latestJobs?.find((job) => job.job?.attributes?.enum === jobEnum);
  };

  const handleFormChange = (field, value) => {
    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  };

  const handleCancel = async () => {
    formik.resetForm();
    setOpenMaintenance(false);
    setSelectedJobs([]);
  };

  const handleStart = () => {
    Object.keys(formik.values).forEach((k) => formik.setFieldTouched(k));

    if (formik.isValid) {
      handleStartMaintenance(formik.values);
    }
  };

  const handleClose = async (cancel = false) => {
    handleCloseMaintenance(cancel).then(() => {
      formik.setFieldValue("maintainer", null);
      formik.setFieldValue("maintenanceDate", dayjs().startOf("day"));
      setOpenMaintenance(false);
      setSelectedJobs([]);
    });
  };

  const setJob = (jobId) => {
    if (selectedJobs.includes(jobId)) {
      setSelectedJobs((prev) => {
        const newJobs = prev.filter((i) => i !== jobId);
        handleFormChange("jobs", newJobs);
        return newJobs;
      });
    } else {
      setSelectedJobs((prev) => {
        const newJobs = [...prev, jobId];
        handleFormChange("jobs", newJobs);
        return newJobs;
      });
    }
  };

  useEffect(() => {
    getLatestJobs();
    getMaintainers();
  }, []);

  useEffect(() => {
    formik.validateForm();
  }, [openMaintenance]);

  return (
    <>
      <div className='mb-4'>
        <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-2'>
          {allJobs.map((job) => {
            const matchingJob = checkJobMatch(job.attributes.enum);
            const dateIfMatch = !!matchingJob ? matchingJob.date : null;
            const color = () => {
              if (!!matchingJob && matchingJob.job.attributes.periodicity !== 0) {
                const differenceInDays = now.diff(matchingJob.date, "day");
                const periodicity = matchingJob.job.attributes.periodicity;
                if (differenceInDays > periodicity) {
                  return "OVERDUE";
                } else {
                  return "ONTIME";
                }
              } else {
                return "DEFAULT";
              }
            };

            const getColor = () => {
              switch (color()) {
                case "OVERDUE":
                  return "bg-red-500 border-red-800 bg-opacity-60";
                case "ONTIME":
                  return "bg-green-500 border-green-800 bg-opacity-60";
                default:
                  return "bg-bg-card-main border-border-card-main";
              }
            };

            return (
              <div
                className={`border border-solid  ${getColor()} rounded-xl px-2 py-1 lg:px-4 lg:py-2 text-text-main text-center`}
              >
                <span className='block font-bold mb-1'>{job.attributes.nameEL}</span>
                <span className='block text-sm'>last checked on:</span>
                <span className='block font-bold'>
                  {!!dateIfMatch ? (
                    dateFormatter(dateIfMatch, true)
                  ) : (
                    <FontAwesomeIcon icon={faMinus} />
                  )}
                </span>
                {job.attributes.periodicity !== 0 && (
                  <span className='block text-xs font-medium mt-1'>
                    check every {job.attributes.periodicity} days
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {!!activeMaintenance ? (
        <div className='bg-bg-card-main border border-border-card-main rounded-xl'>
          <div className='px-5 py-2 text-center'>
            <span className='text-xl text-text-main'>
              Βρέθηκε ενεργή συντήρηση για αυτό το όχημα
            </span>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 items-end md:grid-cols-2 p-4'>
            <div className='flex items-end gap-2'>
              <FormSelect
                options={maintainers}
                label='Change maintainer'
                value={defaultMaintainer}
                handleChange={(value) => handleFormChange("maintainer", value.value)}
              />
              <Button
                isLoading={isLoadingMaintenance}
                handleClick={() => handleChangeMaintainer(formik.values.maintainer)}
                disabled={
                  isLoadingMaintenance ||
                  formik.values.maintainer === activeMaintenance.attributes.user.id
                }
                classes={`${
                  formik.values.maintainer === activeMaintenance.attributes.user.id
                    ? "bg-bg-btn-cancel"
                    : "bg-bg-btn-primary"
                } p-2 text-white ${isLoadingMaintenance && "pr-8 flex justify-center"}`}
              >
                Assign
              </Button>
            </div>
            <div>
              <div className='flex justify-end gap-2'>
                <Button
                  handleClick={() => handleClose(true)}
                  disabled={isLoadingMaintenance}
                  isLoading={isLoadingMaintenance}
                  classes={`bg-red-500 bg-opacity-50 p-2 text-white ${
                    isLoadingMaintenance && "pr-8"
                  }`}
                >
                  Cancel
                </Button>
                <Button
                  handleClick={handleClose}
                  disabled={isLoadingMaintenance}
                  isLoading={isLoadingMaintenance}
                  classes={`bg-bg-btn-cancel p-2 text-white ${isLoadingMaintenance && "pr-8"}`}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${
            openMaintenance && "bg-bg-table-main border border-border-table-body p-2 rounded-xl"
          }`}
        >
          {openMaintenance && (
            <div className='mb-4 sm:mb-2'>
              <div className='text-lg font-medium text-center mb-4 sm:mb-2'>
                <span className='text-text-main'>Plan a new maintenance for {car.carPlate}</span>
              </div>
              <div className='grid grid-cols-1 items-start sm:grid-cols-2 gap-1 sm:gap-2'>
                <div>
                  <FormSelect
                    options={maintainers}
                    label='Select a maintainer to assign the maintenance'
                    errors={formik.touched.maintainer && formik.errors.maintainer}
                    handleChange={(value) => handleFormChange("maintainer", value.value)}
                    value={
                      !Utils.isNull(formik.values.maintainer)
                        ? findOption(maintainers, formik.values.maintainer)
                        : null
                    }
                  />
                </div>
                <div>
                  <FormDatepicker
                    withTime={false}
                    isClearable={true}
                    disablePastDates={true}
                    value={formik.values.maintenanceDate}
                    label='When should this maintenance start?'
                    handleChange={(value) => handleFormChange("maintenanceDate", value)}
                    errors={formik.touched.maintenanceDate && formik.errors.maintenanceDate}
                  />
                </div>
                <div className='col-span-1 sm:col-span-2 mt-2'>
                  <div>
                    <span className='text-text-main'>Which jobs should be done?</span>
                  </div>
                  <div className='flex flex-wrap items-center gap-2 bg-bg-main rounded-lg border border-border-card-main p-2'>
                    {jobs.map((j) => {
                      const isSet = !!selectedJobs.includes(j.id);
                      return (
                        <div
                          key={j.id}
                          onClick={() => setJob(j.id)}
                          className={`inline-flex items-center rounded-xl px-2 py-0.5 border ${
                            isSet
                              ? "bg-green-500/50 border-green-500"
                              : "bg-bg-card-main border-border-card-main"
                          } cursor-pointer`}
                        >
                          <span className='text-sm text-text-main'>{j.attributes.nameEL}</span>
                        </div>
                      );
                    })}
                  </div>
                  {formik.touched.jobs && formik.errors.jobs && (
                    <div>
                      <span className='text-sm text-red-500'>{formik.errors.jobs}</span>
                    </div>
                  )}
                </div>
                <div className='col-span-2 mt-2'>
                  <FormTextarea
                    label='Comments'
                    value={formik.values.adminComment}
                    placeholder='Add comments for the maintainer'
                    handleChange={(value) => handleFormChange("adminComment", value)}
                  />
                </div>
              </div>
            </div>
          )}
          <div className='flex justify-end'>
            <div className='flex items-center gap-2'>
              <Button
                handleClick={() =>
                  openMaintenance ? handleCancel() : setOpenMaintenance(!openMaintenance)
                }
                classes={`p-2 text-white ${
                  openMaintenance ? "bg-bg-btn-cancel" : "bg-bg-btn-primary"
                }`}
              >
                {openMaintenance ? "Cancel" : "Start new maintenance"}
              </Button>
              {openMaintenance && (
                <Button
                  handleClick={handleStart}
                  isLoading={isLoadingMaintenance}
                  disabled={isLoadingMaintenance || !formik.isValid}
                  classes={`bg-blue-500 px-2 py-2 text-white ${isLoadingMaintenance && "pr-8"}`}
                >
                  Start
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MaintenanceForm;
