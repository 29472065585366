import { useFormik } from "formik";
import "../../../styles/global.css";
import { object, string } from "yup";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import Payment from "../../../models/Payment";
import { findOption } from "../../../utils/Forms";
import Button from "../../../components/form/Button";
import FormSelect from "../../../components/form/FormSelect";
import FormTextarea from "../../../components/form/FormTextarea";

const RefundModal = ({ isOpen, renting, toggleModal, handleRefund }) => {
  const [isLoading, setLoading] = useState(false);

  const validationSchema = object().shape({
    reason: string().required("Reason is required"),
    details: string().nullable(),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      reason: "",
      details: "",
    },
  });

  const reasonOptions = Object.keys(Payment.REFUND_REASONS).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(Utils.underscoreToSpace(t)),
  }));
  const reasonDefaultOption = !Utils.isNull(formik.values.reason)
    ? findOption(reasonOptions, String(formik.values.reason)?.toUpperCase())
    : null;

  const handleFormChange = (field, value) => {
    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  };

  const handleRefundLocal = () => {
    Object.keys(formik.values).forEach((key) => formik.setFieldTouched(key));

    if (formik.isValid) {
      setLoading(true);
      handleRefund(formik.values)
        .then((r) => {
          if (r) {
            toggleModal(false);
            formik.resetForm();
            formik.validateForm();
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleCancel = () => {
    toggleModal(false);
    formik.resetForm();
    formik.validateForm();
  };

  useEffect(() => {
    formik.validateForm();
  }, [renting]);

  return (
    <Modal
      open={isOpen}
      title={`Refund Payment`}
      classes={"w-11/12 md:w-1/2 lg:w-3/5 xl:w-1/2"}
      handleToggle={() => toggleModal(!isOpen)}
    >
      <div className='flex flex-col gap-2'>
        <div>
          <FormSelect
            isClearable={false}
            label='Stripe Reason'
            options={reasonOptions}
            placeholder='Stripe Reason'
            value={reasonDefaultOption}
            errors={formik.touched.reason && formik.errors.reason}
            handleChange={(value) => handleFormChange("reason", String(value.value).toLowerCase())}
          />
        </div>
        <div>
          <FormTextarea
            rows='5'
            type='text'
            label='More details'
            value={formik.values.details}
            errors={formik.touched.details && formik.errors.details}
            handleChange={(value) => handleFormChange("details", value)}
            placeholder='Write more details if needed for the reason of refund'
          />
        </div>

        <div className='col-span-2 grid grid-cols-2 gap-2 mt-2 sm:mt-4'>
          <Button classes='bg-gray-200 py-2' isLoading={isLoading} handleClick={handleCancel}>
            <span>Cancel</span>
          </Button>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            handleClick={() => handleRefundLocal()}
            classes='bg-blue-500 py-2 pr-2'
          >
            <span className='text-white'>Refund</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RefundModal;
