import Parse from "parse";
import "../styles/styles.scss";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useEffect, useState } from "react";
import Logger from "../../../models/Logger";
import useUser from "../../../hooks/useUser";
import Loader from "../../../components/Loader";
import useToaster from "../../../hooks/useToaster";
import CarCareSettingsPageView from "../view/CarCareSettingsPageView";

const CarCareSettingsPageState = () => {
  const userHook = useUser();
  const toaster = useToaster();
  const [config, setConfig] = useState(null);
  const session = userHook.getCurrentSession();
  const [isLoading, setLoading] = useState(false);

  const validationSchema = object().shape({
    carServiceKMs: string().required("Car service periodicity in KMs is required"),
    carServiceMonths: string().required("Car service periodicity in Months is required"),
    tiresChangeKMs: string().required("Tires change periodicity in KMs is required"),
    tiresChangeMonths: string().required("Tires change periodicity in Months is required"),
    engineOilsChangeKMs: string().required("Engine oils change periodicity in Months is required"),
    engineOilsChangeMonths: string().required(
      "Engine oils change periodicity in Months is required"
    ),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      carServiceKMs: config?.get("carServiceKMs") || 0,
      carServiceMonths: config?.get("carServiceMonths") || 0,
      tiresChangeKMs: config?.get("tiresChangeKMs") || 0,
      tiresChangeMonths: config?.get("tiresChangeMonths") || 0,
      engineOilsChangeKMs: config?.get("engineOilsChangeKMs") || 0,
      engineOilsChangeMonths: config?.get("engineOilsChangeMonths") || 0,
    },
  });

  const getConfigs = async () => {
    setLoading(true);
    try {
      Parse.Config.get().then((config) => {
        setConfig(config);
        formik.setValues({
          carServiceKMs: config.get("carServiceKMs"),
          carServiceMonths: config.get("carServiceMonths"),
          tiresChangeKMs: config.get("tiresChangeKMs"),
          tiresChangeMonths: config.get("tiresChangeMonths"),
          engineOilsChangeKMs: config.get("engineOilsChangeKMs"),
          engineOilsChangeMonths: config.get("engineOilsChangeMonths"),
        });
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleChange = async (config, value) => {
    try {
      formik.setFieldTouched(config);
      formik.setFieldValue(config, value);
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleSubmit = async () => {
    try {
      if (window.confirm(`Are you sure you want to apply the changes?`)) {
        setLoading(true);
        Object.keys(formik.values).forEach(async (config) => {
          if (formik.values[config] !== formik.initialValues[config]) {
            await Parse.Config.save({ [config]: formik.values[config] }).then(async (r) => {
              await Logger.editCarCareSettings(
                session,
                "updated " + config + " with value '" + formik.values[config] + "'"
              );
            });
          }
        });
      }
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    } finally {
      setLoading(false);
      toaster.success("Changes applied successfully");
      getConfigs();
    }
  };

  useEffect(() => {
    getConfigs();
  }, []);

  return (
    <>
      {!isLoading ? (
        <>
          <Loader isLoading={isLoading} />
          <CarCareSettingsPageView
            form={formik}
            isLoading={isLoading}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        </>
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default CarCareSettingsPageState;
