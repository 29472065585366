const LoadingDots = () => {
  return (
    <div class='flex space-x-0.5 pb-0.5 justify-center items-center'>
      <span class='sr-only'>Loading...</span>
      <div class='h-[3px] w-[3px] bg-text-main rounded-full animate-bounce [animation-delay:-0.3s]'></div>
      <div class='h-[3px] w-[3px] bg-text-main rounded-full animate-bounce [animation-delay:-0.15s]'></div>
      <div class='h-[3px] w-[3px] bg-text-main rounded-full animate-bounce'></div>
    </div>
  );
};

export default LoadingDots;
