import Parse from "parse";
import dayjs from "dayjs";
import { json2excel } from "js2excel";
import User from "../../../models/User";
import Utils from "../../../utils/Utils";
import { useState, useEffect } from "react";
import useUser from "../../../hooks/useUser";
import Loader from "../../../components/Loader";
import UsersPageView from "../view/UsersPageView";
import { getViewportWidth } from "../../../utils/Viewport";
import { userSerializer } from "../../../models/serializers";

const UsersPageState = () => {
  let liveQ = null;
  const userHook = useUser();
  const windowSize = getViewportWidth();
  const [users, setUsers] = useState([]);
  const [totals, setTotals] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [totalElements, setTotalElements] = useState(0);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [isLoadingTotals, setLoadingTotals] = useState(true);
  const [isLoadingExport, setLoadingExport] = useState(false);
  const [isExportModalOpen, toggleExportModal] = useState(false);
  const [isLoadingMessages, setLoadingMessages] = useState(false);
  const [isLoadingDiscounts, setLoadingDiscounts] = useState(false);
  const [exportOptions, setExportOptions] = useState({ filters: false, tabs: false });
  const [sorting, setSorting] = useState([{ columnName: "updatedAt", direction: "desc" }]);
  const [filters, setFilters] = useState({
    status: User.STATUS.PENDING,
  });
  const [pagination, setPagination] = useState({
    page: 0,
    size: windowSize === "MOBILE" ? 10 : 25,
  });
  const [tabTotals, setTabTotals] = useState({
    unauth: null,
    pending: null,
    auth: null,
    reauth: null,
  });

  const startLive = async () => {
    const q = new Parse.Query("_User");
    liveQ = await q.subscribe();

    liveQ.on("update", async () => {
      setLoading(true);
      await getUsers();
    });
    liveQ.on("create", async () => {
      await getUsers();
    });
  };

  const getUsers = async (toExport = false) => {
    if (toExport) {
      const query = new Parse.Query("_User");
      query.include("country");

      if (exportOptions.filters) {
        if (Object.keys(filters).length > 0) {
          filters.username && query.contains("username", filters.username, "i");
          filters.phone && query.contains("phone", filters.phone, "i");
          filters.lastName && query.contains("lastName", filters.lastName, "i");
          filters.from && query.greaterThanOrEqualTo("createdAt", filters.from);
          filters.to && query.lessThanOrEqualTo("createdAt", filters.to);
          filters.fromAuth && query.greaterThanOrEqualTo("authorizedAt", filters.fromAuth);
          filters.toAuth && query.lessThanOrEqualTo("authorizedAt", filters.toAuth);
          filters.isLocked && query.equalTo("isLocked", filters.isLocked);
          filters.isDisabled && query.equalTo("isEnabled", !filters.isDisabled);
          filters.hasNotes && query.exists("notes");
          filters.gender && query.equalTo("gender", String(filters.gender).toLowerCase());
          filters.ageFrom && query.lessThanOrEqualTo("dateOfBirth", filters.ageFrom);
          filters.ageTo && query.greaterThanOrEqualTo("dateOfBirth", filters.ageTo);
          filters.hasPaymentMethod && query.equalTo("hasPaymentMethod", filters.hasPaymentMethod);
        }
      }
      if (exportOptions.tabs) {
        if (!Utils.isNull(filters.status)) {
          const status = filters.status;
          if (status === User.STATUS.UNAUTHORIZED) {
            query.equalTo("isRejected", true);
            query.equalTo("isAuthorized", false);
          } else if (status === User.STATUS.PENDING) {
            query.equalTo("isRejected", false);
            query.equalTo("isAuthorized", false);
          } else if (status === User.STATUS.AUTHORIZED) {
            query.equalTo("isRejected", false);
            query.equalTo("isAuthorized", true);
          } else if (status === User.STATUS.REAUTHORIZED) {
            query.equalTo("isRejected", true);
            query.equalTo("isAuthorized", true);
          }
        }
      }

      return await query.findAll({ useMasterKey: true });
    } else {
      const query = new Parse.Query("_User");
      query.include("country");

      if (Object.keys(filters).length > 0) {
        filters.username && query.contains("username", filters.username, "i");
        filters.phone && query.contains("phone", filters.phone, "i");
        filters.lastName && query.contains("lastName", filters.lastName, "i");
        filters.from && query.greaterThanOrEqualTo("createdAt", filters.from);
        filters.to && query.lessThanOrEqualTo("createdAt", filters.to);
        filters.fromAuth && query.greaterThanOrEqualTo("authorizedAt", filters.fromAuth);
        filters.toAuth && query.lessThanOrEqualTo("authorizedAt", filters.toAuth);
        filters.isLocked && query.equalTo("isLocked", filters.isLocked);
        filters.isDisabled && query.equalTo("isEnabled", !filters.isDisabled);
        filters.hasNotes && query.exists("notes");
        filters.gender && query.equalTo("gender", String(filters.gender).toLowerCase());
        filters.ageFrom && query.lessThanOrEqualTo("dateOfBirth", filters.ageFrom);
        filters.ageTo && query.greaterThanOrEqualTo("dateOfBirth", filters.ageTo);
        filters.hasPaymentMethod && query.equalTo("hasPaymentMethod", filters.hasPaymentMethod);

        if (!Utils.isNull(filters.status)) {
          const status = filters.status;
          if (status === User.STATUS.UNAUTHORIZED) {
            query.equalTo("isRejected", true);
            query.equalTo("isAuthorized", false);
          } else if (status === User.STATUS.PENDING) {
            query.equalTo("isRejected", false);
            query.equalTo("isAuthorized", false);
          } else if (status === User.STATUS.AUTHORIZED) {
            query.equalTo("isRejected", false);
            query.equalTo("isAuthorized", true);
          } else if (status === User.STATUS.REAUTHORIZED) {
            query.equalTo("isRejected", true);
            query.equalTo("isAuthorized", true);
          }
        }
      }

      if (sorting[0]?.direction === "asc") {
        query.ascending(sorting[0].columnName === "firstName" ? "name" : sorting[0].columnName);
      } else {
        query.descending(sorting[0].columnName === "firstName" ? "name" : sorting[0].columnName);
      }
      query.limit(pagination.size);
      query.skip(pagination.page * pagination.size);
      query.withCount(true);
      query
        .find({ useMasterKey: true })
        .then(async (r) => {
          const users = await Promise.all(
            r.results.map(async (u) => {
              const userId = u.id;

              return {
                ...u,
                attributes: u.attributes,
                messages: await new Parse.Query("UserMessage")
                  .equalTo("user", {
                    __type: "Pointer",
                    className: "_User",
                    objectId: userId,
                  })
                  .count({ useMasterKey: true }),
              };
            })
          );

          setUsers(users);
          setTotalElements(r.count);
          return users;
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getTotals = async () => {
    setLoadingTotals(true);

    const totalUsers = async () => {
      const query = new Parse.Query("_User");

      filters.from && query.greaterThanOrEqualTo("createdAt", filters.from);
      filters.to && query.lessThanOrEqualTo("createdAt", filters.to);

      return await query.count(null, {
        useMasterKey: true,
      });
    };

    const totalPending = async () => {
      const query = new Parse.Query("_User");
      query.equalTo("isAuthorized", false);
      query.equalTo("isRejected", false);

      filters.from && query.greaterThanOrEqualTo("createdAt", filters.from);
      filters.to && query.lessThanOrEqualTo("createdAt", filters.to);

      return await query.count(null, {
        useMasterKey: true,
      });
    };

    const totalAuthorized = async () => {
      const query = new Parse.Query("_User");
      query.equalTo("isAuthorized", true);
      query.equalTo("isRejected", false);

      filters.from && query.greaterThanOrEqualTo("createdAt", filters.from);
      filters.to && query.lessThanOrEqualTo("createdAt", filters.to);

      return await query.count(null, {
        useMasterKey: true,
      });
    };

    const totalUsersToday = async () => {
      const today = new Date(dayjs().startOf("day").toISOString());

      return await new Parse.Query("_User").greaterThanOrEqualTo("createdAt", today).count(null, {
        useMasterKey: true,
      });
    };

    Promise.all([totalUsers(), totalPending(), totalAuthorized(), totalUsersToday()])
      .then((r) => {
        setTotals({
          users: r[0],
          pending: r[1],
          authorized: r[2],
          registeredToday: r[3],
        });
      })
      .then(() => {
        setLoadingTotals(false);
      });
  };

  const getTabTotals = async (isClearFilters = false) => {
    setTabTotals({
      unauth: null,
      pending: null,
      auth: null,
      reauth: null,
    });
    const query = new Parse.Query("_User");

    if (!isClearFilters) {
      filters.username && query.contains("username", filters.username, "i");
      filters.phone && query.contains("phone", filters.phone, "i");
      filters.lastName && query.contains("lastName", filters.lastName, "i");
      filters.from && query.greaterThanOrEqualTo("createdAt", filters.from);
      filters.to && query.lessThanOrEqualTo("createdAt", filters.to);
      filters.fromAuth && query.greaterThanOrEqualTo("authorizedAt", filters.fromAuth);
      filters.toAuth && query.lessThanOrEqualTo("authorizedAt", filters.toAuth);
      filters.isLocked && query.equalTo("isLocked", filters.isLocked);
      filters.isDisabled && query.equalTo("isEnabled", !filters.isDisabled);
      filters.hasNotes && query.exists("notes");
      filters.gender && query.equalTo("gender", String(filters.gender).toLowerCase());
      filters.ageFrom && query.lessThanOrEqualTo("dateOfBirth", filters.ageFrom);
      filters.ageTo && query.greaterThanOrEqualTo("dateOfBirth", filters.ageTo);
      filters.hasPaymentMethod && query.equalTo("hasPaymentMethod", filters.hasPaymentMethod);
    }

    // UNAUTHORIZED
    query.equalTo("isRejected", true);
    query.equalTo("isAuthorized", false);

    const unauth = await query.count({ useMasterKey: true });
    // PENDING
    query.equalTo("isRejected", false);
    query.equalTo("isAuthorized", false);

    const pending = await query.count({ useMasterKey: true });

    // AUTHORIZED
    query.equalTo("isRejected", false);
    query.equalTo("isAuthorized", true);

    const auth = await query.count({ useMasterKey: true });

    // REAUTHORIZED
    query.equalTo("isRejected", true);
    query.equalTo("isAuthorized", true);

    const reauth = await query.count({ useMasterKey: true });

    setTabTotals({
      unauth: unauth,
      pending: pending,
      auth: auth,
      reauth: reauth,
    });
  };

  const handleFilterChange = (field, value, shouldUpdate) => {
    if (shouldUpdate) setLoading(true);
    setFilters((prev) => ({ ...prev, [field]: value?.value ?? value }));
    (Utils.isNull(value) || value === "") &&
      setFilters((prev) => {
        const obj = { ...prev };
        delete obj[field];
        return obj;
      });

    if (shouldUpdate) setShouldUpdate(true);
  };

  const handleClearFilters = (e) => {
    setLoading(true);
    e.stopPropagation();
    setFilters((prev) => ({ status: prev.status }));
    setShouldUpdate(true);
    getTabTotals(true);
  };

  const handleApplyFilters = () => {
    getTabTotals();
    setShouldUpdate(true);
    setLoading(true);
  };

  const handleNotesSubmit = async (value, userId) => {
    setLoading(true);
    await userHook.handleNotesSubmit(value, userId).then(() => setLoading(false));
  };

  const handleSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPagination({ ...pagination, page: page });
  };

  const handlePageSizeChange = (size) => {
    setLoading(true);
    setPagination({ ...pagination, size: size });
  };

  const handleOpenExportModal = (e) => {
    e.stopPropagation();
    toggleExportModal(!isExportModalOpen);
  };

  const handleSendMessages = async (users, form) => {
    setLoadingMessages(true);
    return await userHook
      .handleSendMessages(users, form)
      .then(() => {
        setLoadingMessages(false);
        return true;
      })
      .catch((e) => {
        console.error(e.message);
        return false;
      });
  };

  const handleAddDiscounts = async (users, form) => {
    setLoadingDiscounts(true);
    return await userHook
      .handleAddDiscounts(users, form)
      .then(() => {
        setLoadingDiscounts(false);
        return true;
      })
      .catch((e) => {
        console.error(e.message);
        return false;
      });
  };

  const handleExportData = async (e) => {
    e.stopPropagation();
    setLoadingExport(true);

    let excelUsers = [];
    let usersToDownload;

    const convertAndExport = async (users) => {
      for (let i = 0; i < users.length; i++) {
        excelUsers.push(await usersJsonToExcelFormat(users[i]));
      }
      json2excel({ data: excelUsers, name: "Exported Users", formatdate: "dd/mm/yyyy" });

      setLoadingExport(false);
      toggleExportModal(!isExportModalOpen);
    };

    getUsers(true).then(async (r) => {
      usersToDownload = r;

      await convertAndExport(usersToDownload);
    });
  };

  const usersJsonToExcelFormat = async (user) => {
    const usr = userSerializer(user);

    return {
      "User ID": usr.id,
      "Customer ID": usr.customerId,
      Email: usr.username,
      "First Name": usr.firstName ?? "",
      "Last Name": usr.lastName ?? "",
      Phone: usr.phone ?? "",
      "Date of Birth": usr.dateOfBirth ?? "",
      Address: usr.address ?? "",
      City: usr.city ?? "",
      "Post Code": usr.postalCode ?? "",
      Country: usr.country?.name ?? "",
      City: usr.city ?? "",
      "License ID": usr.license?.id ?? "",
      "License Issue Date": usr.license?.issue ?? "",
      "License Expiration Date": usr.license?.expire ?? "",
      "Email Verified": usr.emailVerified ? "Yes" : "No",
      "Phone Verified": usr.phoneVerified ? "Yes" : "No",
      Enabled: usr.isEnabled ? "Yes" : "No",
      Authorized: usr.isAuthorized ? "Yes" : "No",
      Rejected: usr.isRejected ? "Yes" : "No",
      Locked: usr.isLocked ? "Yes" : "No",
      Notes: usr.notes,
      "Registration Date": usr.createdAt,
    };
  };

  useEffect(() => {
    // startLive();
    getTotals();
    getTabTotals();
  }, []);

  useEffect(() => {
    if (shouldUpdate) {
      getTotals();
      getUsers();
      setPagination({ ...pagination, page: 0 });
      setShouldUpdate(false);
    }
  }, [shouldUpdate]);

  useEffect(() => {
    getUsers();
  }, [sorting, pagination]);

  return (
    <>
      {users ? (
        <UsersPageView
          users={users}
          totals={totals}
          sorting={sorting}
          filters={filters}
          isLoading={isLoading}
          tabTotals={tabTotals}
          pagination={pagination}
          totalElements={totalElements}
          handleSorting={handleSorting}
          exportOptions={exportOptions}
          isLoadingTotals={isLoadingTotals}
          isLoadingExport={isLoadingExport}
          setExportOptions={setExportOptions}
          handleExportData={handleExportData}
          handlePageChange={handlePageChange}
          isExportModalOpen={isExportModalOpen}
          toggleExportModal={toggleExportModal}
          isLoadingMessages={isLoadingMessages}
          isLoadingDiscounts={isLoadingDiscounts}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          handleAddDiscounts={handleAddDiscounts}
          handleSendMessages={handleSendMessages}
          handleApplyFilters={handleApplyFilters}
          handleNotesSubmit={handleNotesSubmit}
          handlePageSizeChange={handlePageSizeChange}
          handleOpenExportModal={handleOpenExportModal}
        />
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default UsersPageState;
