import Parse from "parse";
import Renting from "../models/Renting";
import { metersToKM } from "../utils/Units";
import { secondsToHoursMinutes } from "../utils/Dates";
import RobotoBold from "../assets/font/Roboto-Bold.ttf";
import { rentingSerializer } from "../models/serializers";
import RobotoRegular from "../assets/font/Roboto-Regular.ttf";
import CarooLogo from "../assets/images/caroo_logo_invoice.png";
import ImageNotFound from "../assets/images/image_not_found.jpg";
import { Document, Page, View, Text, StyleSheet, Image, Font } from "@react-pdf/renderer";

const useRentalReport = () => {
  Font.register({ family: "RobotoBold", src: RobotoBold });
  Font.register({ family: "RobotoRegular", src: RobotoRegular });

  const styles = StyleSheet.create({
    page: {
      width: "100%",
      padding: 30,
      margin: "auto",
      border: "1px solid #222",
    },
    header: {
      width: "100%",
      display: "flex",
      marginBottom: "30px",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    logo: {
      width: "150",
    },
    right: {
      flexDirection: "row-reverse",
    },
    companyName: {
      fontSize: "14px",
      fontFamily: "RobotoBold",
    },
    text: {
      fontSize: "10px",
      fontFamily: "RobotoRegular",
    },
    title: {
      fontSize: "24px",
      textAlign: "center",
      marginBottom: "20px",
      fontFamily: "RobotoBold",
    },
    sectionTitle: {
      fontSize: "13px",
      paddingBottom: "4px",
      marginBottom: "4px",
      fontFamily: "RobotoBold",
      borderBottom: "1px solid #000",
    },
    detail: {
      fontSize: "11px",
      display: "flex",
      flexDirection: "row",
      columnGap: "40px",
      marginBottom: "2px",
    },
    rentID: {
      fontSize: "12px",
      display: "flex",
      flexDirection: "row",
      columnGap: "40px",
      marginBottom: "10px",
    },
    detailFirst: {
      width: "120px",
    },
    detailSecond: {
      fontFamily: "RobotoRegular",
    },
    divider: {
      height: "10px",
    },
    carPhotos: {
      display: "flex",
      flexDirection: "row",
      columnGap: "10px",
      marginBottom: "10px",
    },
    photoWrapper: {
      width: "100%",
      height: "265px",
      position: "relative",
      padding: "4px",
      border: "1px solid #aaa",
      borderRadius: "10px",
    },
    photo: {
      width: "100%",
      height: "240px",
      objectFit: "contain",
    },
    photoLabel: {
      fontSize: "10px",
      marginBottom: "4px",
    },
    terms: {
      display: "flex",
      flexDirection: "col",
      rowGap: "4px",
      position: "absolute",
      bottom: 30,
      left: 30,
    },
    termsTitle: {
      fontSize: "11px",
      marginΒottom: "4px",
    },
    termsText: {
      fontSize: "8px",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 10,
      bottom: 15,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  const generate = async (rentingRaw = null, excludeImages = false) => {
    let totalBill = {};
    let carPhotosLocal = {};
    const renting = rentingSerializer(rentingRaw);

    const initializeBill = async () => {
      const billCalculated = Renting.provideTotalBill([rentingRaw])[0];
      totalBill = {
        bill: billCalculated.attributes.bill,
        discount: billCalculated.attributes.discount,
        totalBill: billCalculated.attributes.totalBill,
        reservationBill: !!billCalculated.attributes.reservation
          ? billCalculated.attributes.reservation.attributes.bill
          : 0,
      };
    };

    const createImageLocally = async (imgURL) => {
      const image = await Parse.Cloud.run("getImage", {
        fileUrl: imgURL,
      });

      const byteArray = Uint8Array.from(atob(image.base64), (c) => c.charCodeAt(0));
      const blob = new Blob([byteArray], { type: image.contentType });
      return URL.createObjectURL(blob);
    };

    const initializePhotos = async () => {
      let photos = {};

      if (renting.carPhotos.front) {
        try {
          photos["front"] = await createImageLocally(renting.carPhotos.front);
        } catch (e) {
          photos["front"] = ImageNotFound;
          console.error(e.message);
        }
      } else {
        photos["front"] = ImageNotFound;
      }

      if (renting.carPhotos.back) {
        try {
          photos["back"] = await createImageLocally(renting.carPhotos.back);
        } catch (e) {
          photos["back"] = ImageNotFound;
          console.error(e.message);
        }
      } else {
        photos["back"] = ImageNotFound;
      }

      if (renting.carPhotos.left) {
        try {
          photos["left"] = await createImageLocally(renting.carPhotos.left);
        } catch (e) {
          photos["left"] = ImageNotFound;
          console.error(e.message);
        }
      } else {
        photos["left"] = ImageNotFound;
      }

      if (renting.carPhotos.right) {
        try {
          photos["right"] = await createImageLocally(renting.carPhotos.right);
        } catch (e) {
          photos["right"] = ImageNotFound;
          console.error(e.message);
        }
      } else {
        photos["right"] = ImageNotFound;
      }

      carPhotosLocal = photos;
    };

    return initializeBill().then(async () => {
      if (!excludeImages) await initializePhotos();
      return (
        <Document id='PDF'>
          <Page size='A4' style={styles.page}>
            <View style={styles.header}>
              <View style={styles.logo}>
                <Image src={CarooLogo} />
              </View>
              <View>
                <View style={styles.right}>
                  <Text style={styles.companyName}>CAROO MOBILITY SERVICES IKE</Text>
                </View>
                <View style={styles.right}>
                  <Text style={styles.text}>ΕΝΟΙΚΙΑΣΕΙΣ & ΕΚΜΙΣΘΩΣΕΙΣ ΑΥΤΟΚΙΝΗΤΩΝ</Text>
                </View>
                <View style={styles.right}>
                  <Text style={styles.text}>ΦΙΛΟΚΛΕΟΥΣ 18 - ΑΧΑΡΝΑΙ 13671</Text>
                </View>
                <View style={styles.right}>
                  <Text style={styles.text}>ΔΟΥ: ΚΕΦΟΔΕ ΑΤΤΙΚΗΣ</Text>
                </View>
                <View style={styles.right}>
                  <Text style={styles.text}>ΑΦΜ: 802061598</Text>
                </View>
                <View style={styles.right}>
                  <Text style={styles.text}>ΤΗΛ: 210 2380803</Text>
                </View>
              </View>
            </View>
            <View style={styles.title}>
              <Text>Rental Agreement</Text>
            </View>
            <View style={styles.rentID}>
              <Text style={styles.detailFirst}>Renting ID:</Text>
              <Text style={styles.detailSecond}>{renting.id}</Text>
            </View>
            <View style={styles.sectionTitle}>
              <Text>Car Details</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Car Plate:</Text>
              <Text style={styles.detailSecond}>{renting.car.carPlate}</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Brand:</Text>
              <Text style={styles.detailSecond}>{renting.car.brand}</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Model:</Text>
              <Text style={styles.detailSecond}>{renting.car.model}</Text>
            </View>
            <View style={styles.divider}></View>
            <View style={styles.sectionTitle}>
              <Text>User Details</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Email:</Text>
              <Text style={styles.detailSecond}>{renting.driver.username}</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>First Name:</Text>
              <Text style={styles.detailSecond}>{renting.driver.firstName}</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Last Name:</Text>
              <Text style={styles.detailSecond}>{renting.driver.lastName}</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Country:</Text>
              <Text style={styles.detailSecond}>{renting.driver.country?.name}</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Address:</Text>
              <Text style={styles.detailSecond}>{renting.driver.address}</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>City:</Text>
              <Text style={styles.detailSecond}>{renting.driver.city}</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Postal Code:</Text>
              <Text style={styles.detailSecond}>{renting.driver.postalCode}</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Mobile Number:</Text>
              <Text style={styles.detailSecond}>{renting.driver.phone}</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Date of Birth:</Text>
              <Text style={styles.detailSecond}>{renting.driver.dateOfBirth}</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Driver's License ID No:</Text>
              <Text style={styles.detailSecond}>{renting.driver.license.id}</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>License Issue Date:</Text>
              <Text style={styles.detailSecond}>{renting.driver.license.issue}</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>License Expiry Date:</Text>
              <Text style={styles.detailSecond}>{renting.driver.license.expire}</Text>
            </View>
            <View style={styles.divider}></View>
            <View style={styles.sectionTitle}>
              <Text>Renting Details</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Start At:</Text>
              <Text style={styles.detailSecond}>{renting.address.startAddress}</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>End At:</Text>
              <Text style={styles.detailSecond}>{renting.address.endAddress}</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Start Coordinates:</Text>
              <Text style={styles.detailSecond}>
                {renting.location.start.latitude}, {renting.location.start.longitude}
              </Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>End Coordinates:</Text>
              <Text style={styles.detailSecond}>
                {renting.location.end.latitude}, {renting.location.end.longitude}
              </Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Time:</Text>
              <Text style={styles.detailSecond}>
                {secondsToHoursMinutes(renting.time.totalTime)}'
              </Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Distance:</Text>
              <Text style={styles.detailSecond}>{metersToKM(renting.distance)} km</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Start Time:</Text>
              <Text style={styles.detailSecond}>{renting.time.startTime}</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>End Time:</Text>
              <Text style={styles.detailSecond}>{renting.time.endTime}</Text>
            </View>
            <View style={styles.divider}></View>
            <View style={styles.sectionTitle}>
              <Text>Cost Details</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Unlock Cost:</Text>
              <Text style={styles.detailSecond}>1€</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Reservation Cost:</Text>
              <Text style={styles.detailSecond}>{totalBill.reservationBill ?? "0"}€</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Price Per Minute:</Text>
              <Text style={styles.detailSecond}>{renting.car.price}€</Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Discount:</Text>
              <Text style={styles.detailSecond}>
                {totalBill.discount?.amount ? totalBill.discount.amount : "0"}€
              </Text>
            </View>
            <View style={styles.detail}>
              <Text style={styles.detailFirst}>Total Cost:</Text>
              <Text style={styles.detailSecond}>{parseFloat(totalBill.totalBill).toFixed(2)}€</Text>
            </View>
            <View style={styles.terms}>
              <Text style={styles.termsTitle}>Terms & Conditions</Text>
              <Text style={styles.termsText}>
                By using the online car sharing application, Caroo App, the user declares that they
                fully and unreservedly accept the terms and conditions of use contained herein and
                commits to comply with them. These terms and conditions, as well as the Company’s
                privacy statement, constitute the agreement we have entered into for the provided
                services.
              </Text>
            </View>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />
          </Page>
          {!excludeImages && (
            <Page size='A4' style={styles.page}>
              <View style={styles.header}>
                <View style={styles.logo}>
                  <Image src={CarooLogo} />
                </View>
                <View>
                  <View style={styles.right}>
                    <Text style={styles.companyName}>CAROO MOBILITY SERVICES IKE</Text>
                  </View>
                  <View style={styles.right}>
                    <Text style={styles.text}>ΕΝΟΙΚΙΑΣΕΙΣ & ΕΚΜΙΣΘΩΣΕΙΣ ΑΥΤΟΚΙΝΗΤΩΝ</Text>
                  </View>
                  <View style={styles.right}>
                    <Text style={styles.text}>ΦΙΛΟΚΛΕΟΥΣ 18 - ΑΧΑΡΝΑΙ 13671</Text>
                  </View>
                  <View style={styles.right}>
                    <Text style={styles.text}>ΔΟΥ: ΚΕΦΟΔΕ ΑΤΤΙΚΗΣ</Text>
                  </View>
                  <View style={styles.right}>
                    <Text style={styles.text}>ΑΦΜ: 802061598</Text>
                  </View>
                  <View style={styles.right}>
                    <Text style={styles.text}>ΤΗΛ: 210 2380803</Text>
                  </View>
                </View>
              </View>
              <View style={styles.title}>
                <Text>Rental Agreement</Text>
              </View>
              <View style={styles.sectionTitle}>
                <Text>Car Photos at Checkout</Text>
              </View>
              <View style={styles.carPhotos}>
                <View style={styles.photoWrapper}>
                  <Text style={styles.photoLabel}>Front Side</Text>
                  <Image style={styles.photo} src={carPhotosLocal.front} />
                </View>
                <View style={styles.photoWrapper}>
                  <Text style={styles.photoLabel}>Back Side</Text>
                  <Image style={styles.photo} src={carPhotosLocal.back} />
                </View>
              </View>
              <View style={styles.carPhotos}>
                <View style={styles.photoWrapper}>
                  <Text style={styles.photoLabel}>Left Side</Text>
                  <Image style={styles.photo} src={carPhotosLocal.left} />
                </View>
                <View style={styles.photoWrapper}>
                  <Text style={styles.photoLabel}>Right Side</Text>
                  <Image style={styles.photo} src={carPhotosLocal.right} />
                </View>
              </View>
              <View style={styles.terms}>
                <Text style={styles.termsTitle}>Terms & Conditions</Text>
                <Text style={styles.termsText}>
                  By using the online car sharing application, Caroo App, the user declares that
                  they fully and unreservedly accept the terms and conditions of use contained
                  herein and commits to comply with them. These terms and conditions, as well as the
                  Company’s privacy statement, constitute the agreement we have entered into for the
                  provided services.
                </Text>
              </View>
              <Text
                style={styles.pageNumber}
                render={({ pageNumber: pageNumber_1, totalPages: totalPages_1 }) =>
                  `${pageNumber_1} / ${totalPages_1}`
                }
                fixed
              />
            </Page>
          )}
        </Document>
      );
    });
  };

  return { generate };
};

export default useRentalReport;
