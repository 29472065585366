import Parse from "parse";
import "../styles/styles.scss";
import Utils from "../../../utils/Utils";
import { useState, useEffect } from "react";
import Logger from "../../../models/Logger";
import useUser from "../../../hooks/useUser";
import Loader from "../../../components/Loader";
import useToaster from "../../../hooks/useToaster";
import InvoicesPageView from "../view/InvoicesPageView";
import { getViewportWidth } from "../../../utils/Viewport";
import { useConfirm } from "../../../components/ConfirmContext";

const InvoicesPageState = ({ userId = null, shouldRefresh, isFiltersOpened }) => {
  const userHook = useUser();
  const toaster = useToaster();
  const showConfirm = useConfirm();
  const windowSize = getViewportWidth();
  const [admins, setAdmins] = useState([]);
  const [filters, setFilters] = useState({});
  const session = userHook.getCurrentSession();
  const [invoices, setInvoices] = useState([]);
  const isExternalPage = !Utils.isNull(userId);
  const [isLoading, setLoading] = useState(true);
  const [totalElements, setTotalElements] = useState(0);
  const [sorting, setSorting] = useState([{ columnName: "createdAt", direction: "desc" }]);
  const [pagination, setPagination] = useState({
    page: 0,
    size: isExternalPage ? 10 : windowSize === "MOBILE" ? 10 : 25,
  });

  const getInvoices = async () => {
    const Maintenances = Parse.Object.extend("Invoice");
    const query = new Parse.Query(Maintenances);
    if (!!userId) {
      query.equalTo("user", {
        __type: "Pointer",
        className: "_User",
        objectId: userId,
      });
    }
    query.includeAll();

    try {
      if (Object.keys(filters).length > 0) {
        if (filters?.user) {
          let user = new Parse.Object("_User").set("objectId", filters.user);
          query.equalTo("user", user);
        }
        filters.type && query.equalTo("type", filters.type);
        filters.admin && query.equalTo("admin", filters.admin);
        filters.status && query.equalTo("status", filters.status);
        filters.collectionMethod && query.equalTo("collectionMethod", filters.collectionMethod);
        filters.from && query.greaterThanOrEqualTo("createdAt", filters.from);
        filters.to && query.lessThanOrEqualTo("createdAt", filters.to);
        filters.closedFrom && query.greaterThanOrEqualTo("closedAt", filters.closedFrom);
        filters.closedTo && query.lessThanOrEqualTo("closedAt", filters.closedTo);
        filters.dueFrom && query.greaterThanOrEqualTo("dueDate", filters.dueFrom);
        filters.dueTo && query.lessThanOrEqualTo("dueDate", filters.dueTo);
      }

      if (sorting[0]?.direction === "asc") {
        query.ascending(sorting[0].columnName);
      } else {
        query.descending(sorting[0].columnName);
      }
      query.limit(pagination.size);
      query.skip(pagination.page * pagination.size);
      query.withCount(true);
      await query
        .find({ useMasterKey: true })
        .then((r) => {
          setInvoices(r.results);
          setTotalElements(r.count);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const getAdmins = async () => {
    try {
      const Admins = await new Parse.Query("Invoice").distinct("admin");
      setAdmins(Admins);
    } catch (e) {
      console.error(e);
    }
  };

  const handleFilterChange = (field, value) => {
    setLoading(true);
    setFilters((prev) => ({ ...prev, [field]: value?.value ?? value }));
    (Utils.isNull(value) || value === "") &&
      setFilters((prev) => {
        const obj = { ...prev };
        delete obj[field];
        return obj;
      });
    setPagination({ ...pagination, page: 0 });
  };

  const handleClearFilters = (e) => {
    setLoading(true);
    e.stopPropagation();
    setFilters({});
  };

  const handleSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPagination({ ...pagination, page: page });
  };

  const handlePageSizeChange = (size) => {
    setLoading(true);
    setPagination({ ...pagination, size: size });
  };

  const handleMarkUncollectible = async (invoice) => {
    const confirmContent = (
      <div>
        This action is irreversible.
        <br /> Are you sure you want to mark the invoice as uncollectible?
      </div>
    );
    const confirmed = await showConfirm(confirmContent);

    if (confirmed) {
      setLoading(true);

      try {
        await Parse.Cloud.run("markInvoiceUncollectible", {
          invoiceId: invoice.stripeInvoiceId,
        }).then(async () => {
          await Logger.editInvoice(session, invoice, "MARK_UNCOLLECTIBLE");
          getInvoices();
        });
      } catch (e) {
        console.error(e.message);
        toaster.error(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleVoid = async (invoice) => {
    const confirmContent = (
      <div>
        This action is irreversible.
        <br /> Are you sure you want to mark the invoice as void?
      </div>
    );
    const confirmed = await showConfirm(confirmContent);

    if (confirmed) {
      setLoading(true);

      try {
        await Parse.Cloud.run("voidInvoice", {
          invoiceId: invoice.stripeInvoiceId,
        }).then(async () => {
          await Logger.editInvoice(session, invoice, "VOID");
          getInvoices();
        });
      } catch (e) {
        setLoading(false);
        console.error(e.message);
        toaster.error(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRetryInvoicePayment = async (invoice) => {
    const confirmContent = (
      <div>
        This action is irreversible.
        <br /> Are you sure you want to retry the invoice's payment?
      </div>
    );
    const confirmed = await showConfirm(confirmContent);

    if (confirmed) {
      setLoading(true);

      try {
        await Parse.Cloud.run("retryInvoicePayment", {
          invoiceId: invoice.stripeInvoiceId,
        }).then(async () => {
          await Logger.retryInvoicePayment(session, invoice);
          getInvoices();
        });
      } catch (e) {
        setLoading(false);
        console.error(e.message);
        toaster.error(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getAdmins();
  }, []);

  useEffect(() => {
    getInvoices();
  }, [shouldRefresh]);

  useEffect(() => {
    if (filters?.username?.length > 0) {
      const timer = setTimeout(() => {
        getInvoices();
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      getInvoices();
    }
  }, [filters, sorting, pagination]);

  return (
    <>
      {invoices ? (
        <InvoicesPageView
          userId={userId}
          admins={admins}
          sorting={sorting}
          filters={filters}
          invoices={invoices}
          isLoading={isLoading}
          pagination={pagination}
          handleVoid={handleVoid}
          getInvoices={getInvoices}
          isOpened={isFiltersOpened}
          totalElements={totalElements}
          handleSorting={handleSorting}
          isExternalPage={isExternalPage}
          handlePageChange={handlePageChange}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          handlePageSizeChange={handlePageSizeChange}
          handleRetryPayment={handleRetryInvoicePayment}
          handleMarkUncollectible={handleMarkUncollectible}
        />
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default InvoicesPageState;
