import tire from "../../../assets/icons/tire.svg";
import { findOption } from "../../../utils/Forms";
import Button from "../../../components/form/Button";
import FormSelect from "../../../components/form/FormSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faOilCan, faHandHoldingMedical } from "@fortawesome/free-solid-svg-icons";

const CarCareSettingsPageView = ({ form, handleChange, handleSubmit }) => {
  const canSubmit = JSON.stringify(form.values) !== JSON.stringify(form.initialValues);

  const KMOptions = [
    { value: 50000, label: "50.000 KMs" },
    { value: 40000, label: "40.000 KMs" },
    { value: 25000, label: "25.000 KMs" },
    { value: 20000, label: "20.000 KMs" },
    { value: 15000, label: "15.000 KMs" },
    { value: 10000, label: "10.000 KMs" },
    { value: 7500, label: "7.500 KMs" },
  ];
  const MonthOptions = [
    { value: 36, label: "3 years" },
    { value: 24, label: "2 years" },
    { value: 12, label: "1 year" },
    { value: 6, label: "6 months" },
  ];

  return (
    <>
      <div className='title'>
        <span>Car Care Settings</span>
      </div>
      <div className='settings-content'>
        <div className='row'>
          <div className='content'>
            <div className='title'>
              <FontAwesomeIcon icon={faHandHoldingMedical} />
              <span>Car Service</span>
            </div>
            <div className='description'>
              <span>Set the car service periodicity checks either to kilometers or months.</span>
            </div>
            <div className='form-fields'>
              <FormSelect
                options={KMOptions}
                label='Periodicity in KM'
                placeholder='Periodicity in KM'
                value={findOption(KMOptions, form.values.carServiceKMs)}
                handleChange={(value) => handleChange("carServiceKMs", value.value)}
              />
              <FormSelect
                options={MonthOptions}
                label='Periodicity in Months'
                placeholder='Periodicity in Months'
                value={findOption(MonthOptions, form.values.carServiceMonths)}
                handleChange={(value) => handleChange("carServiceMonths", value.value)}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='content'>
            <div className='title'>
              <img src={tire} className='w-6 h-6' />
              <span>Tires Change</span>
            </div>
            <div className='description'>
              <span>Set the car tires periodicity checks either to kilometers or months.</span>
            </div>
            <div className='form-fields'>
              <FormSelect
                options={KMOptions}
                label='Periodicity in KM'
                placeholder='Periodicity in KM'
                value={findOption(KMOptions, form.values.tiresChangeKMs)}
                handleChange={(value) => handleChange("tiresChangeKMs", value.value)}
              />
              <FormSelect
                options={MonthOptions}
                label='Periodicity in Months'
                placeholder='Periodicity in Months'
                value={findOption(MonthOptions, form.values.tiresChangeMonths)}
                handleChange={(value) => handleChange("tiresChangeMonths", value.value)}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='content'>
            <div className='title'>
              <FontAwesomeIcon icon={faOilCan} className='max-w-5' />
              <span>Engine Oils Change</span>
            </div>
            <div className='description'>
              <span>
                Set the car engine oils periodicity checks either to kilometers or months.
              </span>
            </div>
            <div className='form-fields'>
              <FormSelect
                options={KMOptions}
                label='Periodicity in KM'
                placeholder='Periodicity in KM'
                value={findOption(KMOptions, form.values.engineOilsChangeKMs)}
                handleChange={(value) => handleChange("engineOilsChangeKMs", value.value)}
              />
              <FormSelect
                options={MonthOptions}
                label='Periodicity in Months'
                placeholder='Periodicity in Months'
                value={findOption(MonthOptions, form.values.engineOilsChangeMonths)}
                handleChange={(value) => handleChange("engineOilsChangeMonths", value.value)}
              />
            </div>
          </div>
        </div>
        {canSubmit && (
          <div className='flex justify-end mt-4'>
            <div className='flex items-center gap-4'>
              <Button
                classes='bg-bg-btn-cancel px-4 py-2 text-gray-100'
                handleClick={() => {
                  form.resetForm();
                }}
              >
                Abort Changes
              </Button>
              <Button
                classes='bg-blue-500 px-4 py-2 text-gray-100'
                handleClick={() => handleSubmit()}
              >
                Apply Changes
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CarCareSettingsPageView;
