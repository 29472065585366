import dayjs from "dayjs";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";

const FormDatepicker = ({
  label,
  errors,
  value,
  withTime,
  onlyTime,
  disabled,
  isClearable,
  handleChange,
  disablePastDates,
}) => {
  const val = value?.$isDayjsObject ? value : dayjs(value);

  return (
    <div className='w-full h-full form-control'>
      {!!label && <label className='text-xs text-text-main pl-1 mb-1'>{label}</label>}
      {onlyTime ? (
        <TimePicker
          ampm={false}
          error={true}
          value={val}
          disabled={disabled}
          onChange={(value) => handleChange(value?.toDate() ?? null)}
          slotProps={{
            field: { clearable: isClearable },
            textField: {
              error: !!errors,
            },
          }}
        />
      ) : withTime ? (
        <DateTimePicker
          ampm={false}
          error={true}
          value={val}
          disabled={disabled}
          minDate={disablePastDates ? dayjs().startOf("day") : null}
          onChange={(value) => handleChange(value?.toDate() ?? null)}
          slotProps={{
            field: { clearable: isClearable },
            textField: {
              error: !!errors,
            },
          }}
        />
      ) : (
        <DatePicker
          error={true}
          value={val}
          disabled={disabled}
          minDate={disablePastDates ? dayjs().startOf("day") : null}
          onChange={(value) => handleChange(value?.toDate() ?? null)}
          slotProps={{
            field: { clearable: isClearable },
            textField: {
              error: !!errors,
            },
          }}
        />
      )}
      {errors && (
        <div className='error'>
          <span>{errors}</span>
        </div>
      )}
    </div>
  );
};

export default FormDatepicker;
