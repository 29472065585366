import { Parse } from "parse";
import RefundModal from "./RefundModal";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import Button from "../../../components/form/Button";
import RefundStamp from "../../../assets/icons/refunded-stamp.svg";

const PaymentDetails = ({ renting, handleRefund }) => {
  const payment = renting?.payment;
  const hasRefund = !!renting?.payment?.refund;
  const [paymentMethod, setPaymentMethod] = useState(null);
  const refund = hasRefund ? renting?.payment?.refund : null;
  const [isRefundModalOpen, setRefundModalOpen] = useState(false);
  const isPaid = renting?.isPaid && renting?.payment && renting?.isFinished;
  const isNotRefunded =
    !renting?.payment?.isRefunded && !renting?.payment?.refund && renting?.isFinished;

  const getPaymentMethod = async () => {
    if (!!payment.data.payment_method) {
      const pm = await new Parse.Query("PaymentMethod")
        .equalTo("stripeId", payment.data.payment_method)
        .first({ useMasterKey: true });
      setPaymentMethod(pm);
    } else {
      setPaymentMethod(null);
    }
  };

  const renderPaymentMethodDetails = () => {
    return (
      <>
        {!!paymentMethod
          ? `${String(paymentMethod?.attributes?.card?.brand).toUpperCase()} **** ${
              paymentMethod?.attributes?.card?.last4
            }`
          : "None"}
      </>
    );
  };

  const getDiscountSymbol = () => {
    switch (renting?.discount?.type) {
      case "FIX":
        return "€";
      case "PERCENTAGE":
        return "%";
      default:
        break;
    }
  };

  useEffect(() => {
    getPaymentMethod();
  }, []);

  return (
    <div>
      {isNotRefunded && isPaid && (
        <div className='w-full flex justify-end mb-5 sm:mb-0'>
          <Button
            classes={"bg-orange-500 px-4 py-1.5"}
            handleClick={() => setRefundModalOpen(!isRefundModalOpen)}
          >
            <span className='text-white font-bold'>Refund</span>
          </Button>
        </div>
      )}
      <div className='flex flex-row items-start justify-center gap-4'>
        <div
          className='w-80 bg-white p-6 rounded-lg shadow-md font-mono text-center relative'
          style={{
            clipPath:
              "polygon(0% 12px, 5% 0%, 10% 12px, 15% 0%, 20% 12px, 25% 0%, 30% 12px, 35% 0%, 40% 12px, 45% 0%, 50% 12px, 55% 0%, 60% 12px, 65% 0%, 70% 12px, 75% 0%, 80% 12px, 85% 0%, 90% 12px, 95% 0%, 100% 12px, 100% 100%, 95% calc(100% - 12px), 90% 100%, 85% calc(100% - 12px), 80% 100%, 75% calc(100% - 12px), 70% 100%, 65% calc(100% - 12px), 60% 100%, 55% calc(100% - 12px), 50% 100%, 45% calc(100% - 12px), 40% 100%, 35% calc(100% - 12px), 30% 100%, 25% calc(100% - 12px), 20% 100%, 15% calc(100% - 12px), 10% 100%, 5% calc(100% - 12px), 0% 100%)",
          }}
        >
          {/* Receipt Header */}
          <h2 className='text-xl font-bold'>Payment Receipt</h2>
          <div className='border-t border-gray-300 my-4'></div>

          {/* Transaction Details */}
          <div className='text-left text-sm'>
            <p className='flex justify-between'>
              <span className='font-semibold'>Date:</span> {payment.createdAt}
            </p>
            <p className='flex justify-between'>
              <span className='font-semibold'>Renting ID:</span> {renting.id}
            </p>
            <p className='flex justify-between'>
              <span className='font-semibold'>Payment ID:</span> {renting.payment.id}
            </p>
            <p className='flex justify-between'>
              <span className='font-semibold'>Payment Method:</span> {renderPaymentMethodDetails()}
            </p>
          </div>

          <div className='border-t border-gray-300 my-4'></div>

          {/* Items List */}
          <div className='text-left text-sm'>
            {!!renting.reservationBill && (
              <p className='flex justify-between gap-5 mb-2'>
                <span>Reservation Cost</span>{" "}
                <span>{parseFloat(renting.reservationBill).toFixed(2)}€</span>
              </p>
            )}
            <p className='flex justify-between gap-5 mb-2'>
              <span>Unlock Cost</span> <span>{parseFloat(1).toFixed(2)}€</span>
            </p>
            <p className='flex justify-between gap-5'>
              <span>{payment.data.description}</span>{" "}
              <span>{parseFloat(renting.bill - 1).toFixed(2)}€</span>
            </p>
            {!!renting.discount && (
              <p className='flex justify-between gap-5'>
                <span>Discount - {renting.discount.type}</span>{" "}
                <span>
                  -{Number(renting.discount.applies)}
                  {getDiscountSymbol()}
                </span>
              </p>
            )}
            {!!renting.billKm && (
              <p className='flex justify-between gap-5 mt-2'>
                <span>Mileage Cost</span>
                <span>{parseFloat(renting.billKm).toFixed(2)}€</span>
              </p>
            )}
          </div>

          <div className='border-t border-gray-300 my-4'></div>

          {/* Total */}
          <p className='flex justify-between text-lg font-bold'>
            <span>Total:</span>
            <span>{parseFloat(renting.totalBill).toFixed(2)}€</span>
          </p>

          {hasRefund && (
            <img
              src={RefundStamp}
              className='absolute bottom-10 left-1/2 -translate-x-1/2 w-48 h-14 bg-gray-100 bg-opacity-40 rounded'
            />
          )}
        </div>
        {hasRefund && (
          <div className='w-max p-4 rounded-lg shadow-md bg-bg-card-main text-text-main border border-solid border-border-card-main'>
            <div className='mb-4'>
              <span className='text-xl font-bold'>Refund Details</span>
            </div>
            <div className='flex flex-col gap-2'>
              <p>
                <span className='font-semibold'>Amount:</span> {refund.amount}€
              </p>
              <p>
                <span className='font-semibold'>Status:</span>{" "}
                <span
                  className={`ml-2 px-2 py-1 rounded text-white text-sm
              ${
                String(refund.status).toUpperCase() === "SUCCEEDED"
                  ? "bg-green-500"
                  : refund.status === "REJECTED"
                  ? "bg-red-500"
                  : "bg-yellow-500"
              }
            `}
                >
                  {String(refund.status).toUpperCase()}
                </span>
              </p>
              <p>
                <span className='font-semibold'>Reason:</span>{" "}
                {Utils.textFirstOnlyUpper(Utils.underscoreToSpace(refund.reason))}
              </p>
              <p>
                <span className='font-semibold'>Admin Email:</span> {refund.admin}
              </p>
              <p>
                <span className='font-semibold'>Refund Date:</span> {refund.createdAt}
              </p>
              <p>
                <span className='font-semibold'>Refund ID:</span> {refund.stripeRefundId}
              </p>
              {!!refund.details && (
                <p className='col-span-2'>
                  <span className='font-semibold'>Details:</span> {refund.details}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      <RefundModal
        isOpen={isRefundModalOpen}
        handleRefund={handleRefund}
        toggleModal={setRefundModalOpen}
      />
    </div>
  );
};

export default PaymentDetails;
