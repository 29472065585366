import Renting from "../../../models/Renting";

const ReservationTag = ({ status, isCompact }) => {
  const getStatusColor = () => {
    switch (String(status).toUpperCase()) {
      case Renting.RESERVATION_STATUS.EXPIRED:
        return "bg-yellow-500";
      case Renting.RESERVATION_STATUS.CANCELED:
        return "bg-red-500";
      case Renting.RESERVATION_STATUS.COMPLETED:
        return "bg-green-500";
      default:
        return "bg-gray-400";
    }
  };

  return (
    <>
      {isCompact ? (
        <div className={`px-1 py-0 bg-opacity-50 rounded ${getStatusColor()}`}>
          <span className='text-xs text-white font-bold tracking-tighter'>RSV</span>
        </div>
      ) : (
        <div className='px-2 py-1 bg-gray-400 bg-opacity-50 rounded text-text-main'>
          <span className='text-white font-bold'>Reservation</span>
        </div>
      )}
    </>
  );
};

export default ReservationTag;
