import Parse from "parse";
import Loader from "./Loader";
import Button from "./form/Button";
import useUser from "../hooks/useUser";
import { Tooltip } from "@mui/material";
import useToaster from "../hooks/useToaster";
import { useNavigate } from "react-router-dom";
import { dateFormatter } from "../utils/Dates";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faCircle, faUser } from "@fortawesome/free-solid-svg-icons";

const NotificationsPanel = ({ countUnseenNotifs, setNotifsPanelOpen }) => {
  const pageSize = 25;
  const timer = useRef(null);
  const userHook = useUser();
  const toaster = useToaster();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const session = userHook.getCurrentSession();
  const [isLoading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationType, setNotificationType] = useState(null);

  const initNotifications = async () => {
    const notifications = await getNotifications();

    if (notifications?.length > 0) setNotifications(notifications);
  };

  const getNotifications = async () => {
    try {
      setLoading(true);

      const userId = session.user.objectId;
      const query = new Parse.Query("Notifications");
      !!notificationType && query.equalTo("type", notificationType);
      query.descending("createdAt");
      query.limit(pageSize);
      query.skip(pageSize * page);
      const data = await query.find({ useMasterKey: true });

      const formattedData = data?.map((n) => {
        return {
          id: n.id,
          type: n.attributes.type,
          action: n.attributes.action,
          content: n.attributes.content,
          entityID: n.attributes.entityID,
          createdAt: n.attributes.createdAt,
          isSeen: Array.isArray(n.attributes.isSeen) ? n.attributes.isSeen.includes(userId) : false,
        };
      });
      setLoading(false);
      return formattedData;
    } catch (e) {
      console.error(e.message);
      setLoading(false);
    }
  };

  const loadMore = async () => {
    setPage((prevPage) => prevPage + 1);
  };

  const markSeen = async (e, notifId) => {
    try {
      setLoading(true);
      if (!!e) e.preventDefault();

      const userId = session.user.objectId;
      const Notif = new Parse.Object("Notifications").set("objectId", notifId);
      Notif.addUnique("isSeen", userId);
      await Notif.save(null, { useMasterKey: true });

      const newNotifs = notifications.map((n) => {
        if (n.id === notifId) {
          return { ...n, isSeen: true };
        } else {
          return n;
        }
      });
      setNotifications(newNotifs);
      countUnseenNotifs();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const markSeenAll = async () => {
    try {
      setLoading(true);

      const userId = session.user.objectId;
      const notifications = await new Parse.Query("Notifications")
        .notContainedIn("isSeen", [userId])
        .findAll({ useMasterKey: true });

      const allNotifications = notifications.map((n) =>
        new Parse.Object("Notifications").set("objectId", n.id).addUnique("isSeen", userId)
      );

      await Parse.Object.saveAll(allNotifications).then(async () => {
        await countUnseenNotifs();
        await initNotifications();
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const getIcon = (type) => {
    if (type === "USER") {
      return faUser;
    } else if (type === "CAR") {
      return faCar;
    }
  };

  const handleSetNotificationType = async (type) => {
    if (notificationType === type) setNotificationType(null);
    else setNotificationType(type);
  };

  const handleMouseLeave = () => {
    timer.current = setTimeout(() => {
      setNotifsPanelOpen(false);
    }, 1000);

    return () => {
      clearTimeout(timer.current);
    };
  };

  const handleMouseEnter = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  };

  const handleRedirect = (notification) => {
    markSeen(null, notification.id);
    const path =
      notification.type === "USER"
        ? "/users/" + notification.entityID
        : "/cars/" + notification.entityID;

    navigate(path);
  };

  useEffect(() => {
    initNotifications();
  }, []);

  useEffect(() => {
    setPage(0);
    setNotifications([]);
    initNotifications();
  }, [notificationType]);

  useEffect(() => {
    if (page > 0) {
      const loadMoreNotifications = async () => {
        const notifications = await getNotifications();

        if (notifications?.length > 0)
          setNotifications((prev) => {
            const newNotifs = [...prev];
            newNotifs.push(...notifications);
            return newNotifs;
          });
      };

      loadMoreNotifications();
    }
  }, [page]);

  return (
    <div
      className='notifications-panel'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='title'>
        <span>Notifications</span>
        <div className=''>
          <div>
            <div className='flex justify-end items-cener gap-1'>
              <Button
                handleClick={() => handleSetNotificationType("CAR")}
                classes={`px-1 py-0 ${
                  notificationType === "CAR" ? "bg-bg-btn-primary" : "bg-bg-btn-cancel"
                }`}
              >
                <span className='cursor-pointer'>Car</span>
              </Button>
              <Button
                handleClick={() => handleSetNotificationType("USER")}
                classes={`px-1 py-0 ${
                  notificationType === "USER" ? "bg-bg-btn-primary" : "bg-bg-btn-cancel"
                }`}
              >
                <span className='cursor-pointer'>User</span>
              </Button>
            </div>
          </div>
          <div>
            <span className='cursor-pointer select-none' onClick={markSeenAll}>
              Mark all seen
            </span>
          </div>
        </div>
      </div>
      <div className='list'>
        <Loader isLoading={isLoading} />
        {notifications?.map((n, i) => {
          return (
            <div key={i} className='item'>
              <div className='flex items-center gap-3'>
                <div
                  key={n.id}
                  onClick={() => handleRedirect(n)}
                  className='flex items-center gap-3'
                >
                  <FontAwesomeIcon icon={getIcon(n.type)} />
                  <div className='flex'>
                    <span
                      className={`leading-5 text-base w-70 break-words ${!n.isSeen && "font-bold"}`}
                    >
                      {n.content}
                    </span>
                  </div>
                </div>

                {!n.isSeen && (
                  <Tooltip title='Click to mark as seen'>
                    <FontAwesomeIcon
                      size='sm'
                      icon={faCircle}
                      className='text-blue-400'
                      onClick={(e) => markSeen(e, n.id)}
                    />
                  </Tooltip>
                )}
              </div>
              <div className='flex justify-end -mt-1 mr-8 opacity-70 italic'>
                <span className='text-sm'>{dateFormatter(n.createdAt, true)}</span>
              </div>
            </div>
          );
        })}
      </div>
      <div className='mt-2'>
        <div className='text-center'>
          <span className='text-lg font-bold cursor-pointer' onClick={loadMore}>
            Load more
          </span>
        </div>
      </div>
    </div>
  );
};

export default NotificationsPanel;
