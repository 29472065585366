import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Utils from "../../../utils/Utils";
import Loader from "../../../components/Loader";
import { isBelow } from "../../../utils/Viewport";
import { dateFormatter } from "../../../utils/Dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormCheckbox from "../../../components/form/FormCheckbox";
import { faEnvelope, faNoteSticky } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Grid, Table, PagingPanel, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import {
  SortingState,
  PagingState,
  CustomPaging,
  TableSelection,
  SelectionState,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";

const UsersTable = ({
  users,
  sorting,
  selection,
  isLoading,
  pagination,
  setSelection,
  handleSorting,
  totalElements,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const sortableColumns = ["email", "updatedAt"];

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props} className='sortable-column-label'>
          {props.children}
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold'>{props.column.title}</span>;
    }
  };

  const resolveUsername = (data) => {
    const isLicenseExpired = data.attributes.licenseExpiryDate
      ? dayjs().isAfter(dayjs(data.attributes.licenseExpiryDate))
      : false;

    return (
      <div className='flex items-center'>
        {!!data?.attributes?.country?.attributes?.emoji && (
          <span className='text-xl mr-1 cursor-pointer'>
            <Tooltip title={data?.attributes?.country?.attributes?.name} placement='top'>
              {data?.attributes?.country?.attributes?.emoji}
            </Tooltip>
          </span>
        )}
        <Link className='link' to={`/users/${data?.id}`}>
          <span className='font-semibold truncate'>{data?.attributes?.username}</span>
        </Link>
        {isLicenseExpired && (
          <Tooltip title="User's driving license has expired!" placement='top'>
            <FontAwesomeIcon
              size='lg'
              icon={faExclamationTriangle}
              className='text-red-400 ml-1 cursor-pointer'
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const resolveMessage = (data) => {
    const hasMessages = data.messages > 0;
    return (
      <>
        {hasMessages && (
          <FontAwesomeIcon
            className='text-text-main'
            size={isBelow("SM") ? "lg" : "xl"}
            icon={isBelow("SM") ? faEnvelope : faCheck}
          />
        )}
      </>
    );
  };

  const resolveNote = (data) => {
    const note = data.attributes.notes;
    const hasNotes = !Utils.isEmpty(data.attributes.notes);

    return (
      <>
        {hasNotes && (
          <Tooltip title={note} placement='top'>
            <FontAwesomeIcon
              className='text-text-main  cursor-pointer'
              size={isBelow("SM") ? "lg" : "xl"}
              icon={isBelow("SM") ? faNoteSticky : faCheck}
            />
          </Tooltip>
        )}
      </>
    );
  };

  const resolveUpdatedAt = (data) => {
    return dateFormatter(data.attributes.updatedAt, true);
  };

  const resolveColumns = () => {
    if (isBelow("SM")) {
      return [
        {
          name: "email",
          title: "User",
          getCellValue: resolveUsername,
        },
      ];
    } else {
      return [
        {
          name: "email",
          title: "User",
          getCellValue: resolveUsername,
        },
        {
          name: "message",
          title: "Message",
          getCellValue: resolveMessage,
        },
        {
          name: "note",
          title: "Note",
          getCellValue: resolveNote,
        },
        {
          name: "updatedAt",
          title: "Date Updated",
          getCellValue: resolveUpdatedAt,
        },
      ];
    }
  };

  const columns = resolveColumns();

  const cellComponent = (props) => {
    if (isBelow("SM")) {
      return (
        <Table.Cell {...props}>
          <div className='relative'>
            <div>{columns.find((c) => c.name === "email").getCellValue(props.row)}</div>
            <div className='flex items-center gap-2'>
              {resolveMessage(props.row)} {resolveNote(props.row)} {resolveUpdatedAt(props.row)}
            </div>
          </div>
        </Table.Cell>
      );
    } else {
      return <Table.Cell {...props} />;
    }
  };

  const SelectHeaderCheckbox = ({ allSelected, someSelected, onToggle }) => {
    return (
      <th className='MuiTableCell head w-6'>
        <div className='flex items-center justify-center'>
          <FormCheckbox
            value={allSelected}
            indeterminate={someSelected}
            handleChange={(value) => onToggle(value)}
          />
        </div>
      </th>
    );
  };

  const SelectRowCheckbox = ({ selected, onToggle }) => {
    return (
      <td className='MuiTableCell body w-6'>
        <div className='flex items-center justify-center'>
          <FormCheckbox value={selected} handleChange={(value) => onToggle(value)} />
        </div>
      </td>
    );
  };

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <div className='z-10'>
        <div className='fixed-table'>
          <Grid rows={users} columns={columns} getRowId={(row) => row.id}>
            <SortingState sorting={sorting} onSortingChange={handleSorting} />
            <PagingState
              currentPage={pagination.page}
              pageSize={pagination.size}
              onCurrentPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
            <CustomPaging totalCount={totalElements} />
            <SelectionState selection={selection} onSelectionChange={setSelection} />
            <IntegratedSelection />
            <Table
              columnExtensions={[
                {
                  columnName: "email",
                  wordWrapEnabled: true,
                  width: "auto",
                },
                {
                  columnName: "message",
                  wordWrapEnabled: true,
                  width: "80",
                  align: "center",
                },
                {
                  columnName: "note",
                  wordWrapEnabled: true,
                  width: "60",
                  align: "center",
                },
                {
                  columnName: "updatedAt",
                  wordWrapEnabled: true,
                  width: "150",
                  align: "center",
                },
              ]}
              cellComponent={cellComponent}
            />
            <TableHeaderRow
              sortLabelComponent={TableHeaderRowSortLabelComponent}
              showSortingControls={true}
            />
            <TableSelection
              showSelectAll={true}
              cellComponent={SelectRowCheckbox}
              headerCellComponent={SelectHeaderCheckbox}
            />
            <PagingPanel pageSizes={[10, 25, 50, 100]} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default UsersTable;
