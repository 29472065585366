import { useState } from "react";
import { Tooltip } from "@mui/material";
import User from "../../../models/User";
import Status from "../components/Status";
import useUser from "../../../hooks/useUser";
import CarNotes from "../components/CarNotes";
import { useNavigate } from "react-router-dom";
import CarStatus from "../components/CarStatus";
import CarDetails from "../components/CarDetails";
import MapTracker from "../components/MapTracker";
import PageMenu from "../../../components/PageMenu";
import CarAmenities from "../components/CarAmenities";
import MaintenanceForm from "../components/MaintenanceForm";
import CarInversStatus from "../components/CarInversStatus";
import CaresPageState from "../../cares/state/CaresPageState";
import CarAdditionalInfo from "../components/CarAdditionalInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import DamagesPageState from "../../damages/state/DamagesPageState";
import RentingsPageState from "../../rentings/state/RentingsPageState";
import MaintenancesPageState from "../../maintenance/state/MaintenancesPageState";
import {
  faLock,
  faEuro,
  faEdit,
  faMinus,
  faXmark,
  faLockOpen,
  faFloppyDisk,
  faCircleNotch,
  faArrowsRotate,
  faScrewdriverWrench,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

const CarPageView = ({
  car,
  jobs,
  totals,
  editForm,
  hasCares,
  carStatus,
  isLoading,
  isEditing,
  handleEdit,
  handleSave,
  handleLock,
  modemStatus,
  selectedJobs,
  isLoadingModem,
  setSelectedJobs,
  isLoadingUpdate,
  isLoadingTotals,
  isLoadingInvers,
  isLoadingEditForm,
  handleNotesSubmit,
  handleToggleRiding,
  handleRefreshStatus,
  getInversModemStatus,
  handleEditFormChange,
  handleToggleAvailable,
  handleToggleBluetooth,
  handleToggleChildSeat,
  handleTogglePetFriendly,
  switchInversModemNetwork,
  handleToggleUnderMaintenance,
  // ------ Maintenance logic ------ //
  activeMaintenance,
  refreshMaintenances,
  isLoadingMaintenance,
  handleStartMaintenance,
  handleCloseMaintenance,
  handleChangeMaintainer,
  // ------ Maintenance logic ------ //
}) => {
  const userHook = useUser();
  const navigate = useNavigate();
  const [openTab, setTab] = useState(0);
  const [isInfoOpen, setInfoOpen] = useState(false);
  const allowEdit = userHook.getUserRoles()?.includes(User.ROLES.ADMIN);

  const handleMarkerClick = () => {
    setInfoOpen(true);
  };

  return (
    <div className='page-wrapper relative'>
      <div className='page-content'>
        <div className='page-title'>
          <div className='flex flex-col sm:flex-row gap-0 sm:gap-4 items-start sm:items-center'>
            <div className='flex items-center gap-1 sm:gap-4'>
              <img className='w-36' src={car.photo} />
              <div className='truncate'>
                <div className='title'>{car.carPlate}</div>
                <div className='title'>
                  {car.brand} {car.model}
                </div>
              </div>
            </div>
            <div className='flex flex-row sm:flex-col items-center sm:items-start'>
              <CarInversStatus
                car={car}
                modemStatus={modemStatus}
                isLoadingModem={isLoadingModem}
                getInversModemStatus={getInversModemStatus}
                switchInversModemNetwork={switchInversModemNetwork}
              />
              <Status car={car} />
            </div>
          </div>
          <div className='flex flex-col sm:flex-row items-center gap-2'>
            {hasCares && (
              <Tooltip
                placement='top'
                title='The vehicle has some pending cares! Review them at the Cares tab!'
              >
                <div
                  onClick={() => setTab(4)}
                  className='flex items-center gap-1 border-2 border-solid border-[#FCB03F] text-[#FCB03F] px-2 py-0.5 rounded-lg cursor-pointer'
                >
                  <FontAwesomeIcon icon={faScrewdriverWrench} />
                  <span>Care</span>
                </div>
              </Tooltip>
            )}
            <div className='flex items-center gap-2'>
              {allowEdit && (
                <div className='flex items-center gap-2'>
                  <div
                    className={`flex items-center justify-center w-8 h-8 bg-white border border-solid border-gray-200 rounded ${
                      isLoadingEditForm ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    onClick={allowEdit && !isLoadingEditForm ? handleEdit : null}
                  >
                    <FontAwesomeIcon
                      size='lg'
                      icon={isEditing ? faXmark : faEdit}
                      className={`${isEditing && "text-red-500"}`}
                    />
                  </div>
                  {isEditing && (
                    <div
                      className={`flex items-center justify-center w-8 h-8 bg-white border border-solid border-gray-200 rounded ${
                        isLoadingEditForm ? "cursor-not-allowed" : "cursor-pointer"
                      }`}
                      onClick={!isLoadingEditForm ? handleSave : null}
                    >
                      <FontAwesomeIcon
                        size='lg'
                        className={`${
                          isLoadingEditForm ? "animate-spin text-gray-400" : "text-green-600"
                        }`}
                        icon={isLoadingEditForm ? faCircleNotch : faFloppyDisk}
                      />
                    </div>
                  )}
                </div>
              )}
              <PageMenu>
                <div
                  className='flex items-center cursor-pointer'
                  onClick={() => navigate(`/cars/${car.id}/docs`)}
                >
                  <FontAwesomeIcon icon={faFileLines} />
                  <span className='ml-2 text-sm'>Car Documents</span>
                </div>
                <div
                  className={`flex items-center ${
                    isLoading ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  onClick={() => (!isLoadingUpdate ? handleRefreshStatus() : null)}
                >
                  <FontAwesomeIcon
                    icon={faArrowsRotate}
                    className={`${isLoadingUpdate && "animate-spin"}`}
                  />
                  <span className='ml-2 text-sm'>{isLoadingUpdate ? "Refreshing" : "Refresh"}</span>
                </div>
                <div
                  onClick={!isLoading ? handleLock : null}
                  className={`flex items-center ${
                    isLoading ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                >
                  <FontAwesomeIcon
                    icon={carStatus?.invers?.central_lock === "locked" ? faLockOpen : faLock}
                  />
                  <span className='ml-2 text-sm'>
                    {carStatus?.invers?.central_lock === "locked" ? "Unlock" : "Lock"}
                  </span>
                </div>
              </PageMenu>
            </div>
          </div>
        </div>
        <div className='page-totals'>
          <div>
            <span>Rentings</span>
            <span>
              {isLoadingTotals ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  className='animate-spin mt-4 opacity-50'
                  size='2xs'
                />
              ) : !!totals.rentings ? (
                totals.rentings
              ) : (
                <FontAwesomeIcon icon={faMinus} />
              )}
            </span>
          </div>
          <div>
            <span>Points</span>
            <span>1000</span>
          </div>
          <div>
            <span>Revenue</span>
            <>
              {isLoadingTotals ? (
                <FontAwesomeIcon
                  size='xl'
                  icon={faCircleNotch}
                  className='animate-spin mt-4 opacity-50'
                />
              ) : !!totals.revenue.discounted ? (
                <>
                  <span>
                    {parseFloat(totals.revenue.discounted).toFixed(2)}
                    <FontAwesomeIcon icon={faEuro} className='ml-1' />
                  </span>
                  <Tooltip
                    title={`Discounted amount: ${parseFloat(
                      totals?.revenue?.raw - totals?.revenue?.discounted
                    ).toFixed(2)}€`}
                  >
                    <span>
                      {!isLoadingTotals && !!totals.revenue.raw && (
                        <span>
                          Raw: {parseFloat(totals.revenue.raw).toFixed(2)}
                          <FontAwesomeIcon icon={faEuro} className='ml-1' />
                        </span>
                      )}
                    </span>
                  </Tooltip>
                </>
              ) : (
                <FontAwesomeIcon icon={faMinus} />
              )}
            </>
          </div>
          <div>
            <span>Total OPEX</span>
            <span>
              <FontAwesomeIcon icon={faMinus} />
            </span>
          </div>
        </div>
        <div className='tabs grid-cols-5'>
          <div onClick={() => setTab(0)} className={`tab ${openTab === 0 ? "active" : "inactive"}`}>
            <span>Car Info</span>
          </div>
          <div onClick={() => setTab(1)} className={`tab ${openTab === 1 ? "active" : "inactive"}`}>
            <span>Rentings</span>
          </div>
          <div onClick={() => setTab(2)} className={`tab ${openTab === 2 ? "active" : "inactive"}`}>
            <span>Maintenances</span>
          </div>
          <div onClick={() => setTab(3)} className={`tab ${openTab === 3 ? "active" : "inactive"}`}>
            <span>Damages</span>
          </div>
          <div onClick={() => setTab(4)} className={`tab ${openTab === 4 ? "active" : "inactive"}`}>
            <span>Cares</span>
            {hasCares && (
              <FontAwesomeIcon icon={faExclamationTriangle} className='text-[#FCB03F] text-xl' />
            )}
          </div>
        </div>
        <div className='py-4'>
          {openTab === 0 ? (
            <>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4'>
                <div className='box-border-rounded with-bg'>
                  <CarDetails
                    car={car}
                    editForm={editForm}
                    carStatus={carStatus}
                    isEditing={isEditing}
                    isLoadingInvers={isLoadingInvers}
                    isLoadingForm={isLoadingEditForm}
                    handleEditFormChange={handleEditFormChange}
                  />
                </div>
                <div className='box-border-rounded with-bg'>
                  <CarStatus
                    car={car}
                    carStatus={carStatus}
                    isLoadingInvers={isLoadingInvers}
                    handleToggleRiding={handleToggleRiding}
                    isLoading={isLoading || isLoadingInvers}
                    handleToggleAvailable={handleToggleAvailable}
                    handleToggleUnderMaintenance={handleToggleUnderMaintenance}
                  />
                </div>
                <div className='box-border-rounded with-bg'>
                  <CarAdditionalInfo
                    car={car}
                    editForm={editForm}
                    isEditing={isEditing}
                    isLoadingForm={isLoadingEditForm}
                    handleEditFormChange={handleEditFormChange}
                  />
                </div>
                <div className='box-border-rounded with-bg'>
                  <CarNotes car={car} handleNotesSubmit={handleNotesSubmit} />
                </div>
                <div className='box-border-rounded with-bg'>
                  <CarAmenities
                    car={car}
                    isLoading={isLoading}
                    handleToggleBluetooth={handleToggleBluetooth}
                    handleToggleChildSeat={handleToggleChildSeat}
                    handleTogglePetFriendly={handleTogglePetFriendly}
                  />
                </div>
                <div className='h-80 box-border-rounded with-bg relative'>
                  <MapTracker
                    cars={[car]}
                    singleCar={true}
                    isInfoOpen={isInfoOpen}
                    handleMarkerClick={handleMarkerClick}
                  />
                </div>
              </div>
            </>
          ) : openTab === 1 ? (
            <RentingsPageState carId={car.id} />
          ) : openTab === 2 ? (
            <div>
              <MaintenanceForm
                car={car}
                jobs={jobs}
                selectedJobs={selectedJobs}
                setSelectedJobs={setSelectedJobs}
                activeMaintenance={activeMaintenance}
                isLoadingMaintenance={isLoadingMaintenance}
                handleStartMaintenance={handleStartMaintenance}
                handleCloseMaintenance={handleCloseMaintenance}
                handleChangeMaintainer={handleChangeMaintainer}
              />
              <div>
                <MaintenancesPageState
                  carId={car.id}
                  isFiltersOpened={false}
                  shouldRefresh={refreshMaintenances}
                />
              </div>
            </div>
          ) : openTab === 3 ? (
            <DamagesPageState carId={car.id} />
          ) : (
            <CaresPageState carId={car.id} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CarPageView;
