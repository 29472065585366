import { Parse } from "parse";
import { useEffect } from "react";
import User from "../models/User";
import Utils from "../utils/Utils";
import CarPage from "./cars/CarPage";
import LogsPage from "./logs/LogsPage";
import CarsPage from "./cars/CarsPage";
import UserPage from "./users/UserPage";
import CaresPage from "./cares/CaresPage";
import Header from "../components/Header";
import Footer from "../components/Footer";
import UsersPage from "./users/UsersPage";
import NotFoundPage from "./NotFoundPage";
import { useSelector } from "react-redux";
import CommsPage from "./comms/CommsPage";
import LoginPage from "./session/LoginPage";
import CarDocsPage from "./cars/CarDocsPage";
import DamagesPage from "./damages/DamagesPage";
import SupportPage from "./support/SupportPage";
import Authorizer from "../components/Authorizer";
import InvoicesPage from "./invoice/InvoicesPage";
import DiscountsPage from "./discounts/DiscountsPage";
import IncidentsPage from "./incidents/IncidentsPage";
import UserMessagesPage from "./users/UserMessagesPage";
import RentingPage from "../pages/rentings/RentingPage";
import ForbiddenAccessPage from "./ForbiddenAccessPage";
import RentingsPage from "../pages/rentings/RentingsPage";
import DashboardPage from "../pages/dashboard/DashboardPage";
import MaintenancesPage from "./maintenance/MaintenancesPage";
import { Route, Routes, useLocation } from "react-router-dom";
import { ConfirmProvider } from "../components/ConfirmContext";
import ConfigurationsLayout from "../layouts/ConfigurationsLayout";
// import Playground from "./Playground";

const Root = () => {
  const location = useLocation();
  const theme = useSelector((state) => state?.theme);

  // Set usernames or carplates instead of ids
  useEffect(() => {
    const constructPageTitle = async () => {
      let pageTitle = "";
      const pageTitleParts = location.pathname.substring(1).split("/");

      if (pageTitleParts.length > 1 && pageTitleParts[0] === "users") {
        const user = await new Parse.Query("_User")
          .equalTo("objectId", pageTitleParts[1])
          .first({ useMasterKey: true });
        pageTitleParts[1] = user.attributes.username;
      }
      if (pageTitleParts.length > 1 && pageTitleParts[0] === "cars") {
        const car = await new Parse.Query("Car")
          .equalTo("objectId", pageTitleParts[1])
          .first({ useMasterKey: true });
        pageTitleParts[1] = car.attributes.carPlate;
      }

      // Construct the final page title
      pageTitleParts.forEach((p, i) => {
        if (pageTitleParts.length === 1) {
          pageTitle = Utils.textFirstOnlyUpper(p.replace(/-/g, " "));
        } else {
          if (i + 1 < pageTitleParts.length) {
            if (i === 0) {
              pageTitle += Utils.textFirstOnlyUpper(p) + " / ";
            } else {
              pageTitle += p + " / ";
            }
          } else {
            pageTitle += p;
          }
        }
      });

      if (Utils.isEmpty(pageTitle)) {
        pageTitle = "Caroo Dashboard";
      }

      document.title = pageTitle;
    };

    constructPageTitle();
  }, [location]);

  return (
    <div className={`main-app ${String(theme).toLowerCase()}`}>
      <ConfirmProvider>
        <Header />
        <div className='content'>
          <Routes>
            <Route path='*' element={<NotFoundPage />} />
            <Route path='login' element={<LoginPage />} />
            <Route path='not-permitted' element={<ForbiddenAccessPage />} />
            <Route
              path='/'
              element={
                <Authorizer>
                  <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                    <div className='containerized'>
                      <DashboardPage />
                    </div>
                  </Authorizer>
                </Authorizer>
              }
            />
            <Route
              path='comms'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <CommsPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='users'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <UsersPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='users/:userId'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <UserPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='user-messages'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <UserMessagesPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='communication-history'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <CommsPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='rentings'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <RentingsPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='rentings/:rentingId'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <RentingPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='incidents'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <IncidentsPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='cars'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <CarsPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='cars/:carId'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <CarPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='cars/:carId/docs'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <CarDocsPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='cares'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <CaresPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='discounts'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <DiscountsPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='damages'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <DamagesPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='maintenances'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <MaintenancesPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='invoices'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <InvoicesPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='logs'
              element={
                <Authorizer access={[User.ROLES.ADMIN, User.ROLES.VIEWER]}>
                  <div className='containerized'>
                    <LogsPage />
                  </div>
                </Authorizer>
              }
            />
            <Route
              path='configurations'
              element={
                <Authorizer access={[User.ROLES.ADMIN]}>
                  <ConfigurationsLayout />
                </Authorizer>
              }
            />
            <Route
              path='configurations/general'
              element={
                <Authorizer access={[User.ROLES.ADMIN]}>
                  <ConfigurationsLayout />
                </Authorizer>
              }
            />
            <Route
              path='configurations/campaigns'
              element={
                <Authorizer access={[User.ROLES.ADMIN]}>
                  <ConfigurationsLayout />
                </Authorizer>
              }
            />
            <Route
              path='configurations/happy-hour'
              element={
                <Authorizer access={[User.ROLES.ADMIN]}>
                  <ConfigurationsLayout />
                </Authorizer>
              }
            />
            <Route
              path='configurations/car-care'
              element={
                <Authorizer access={[User.ROLES.ADMIN]}>
                  <ConfigurationsLayout />
                </Authorizer>
              }
            />
            <Route
              path='support'
              element={
                <Authorizer>
                  <SupportPage />
                </Authorizer>
              }
            />
            {/* <Route
              path='playground'
              element={
                <Authorizer>
                  <Playground />
                </Authorizer>
              }
            /> */}
          </Routes>
        </div>
        <Footer />
      </ConfirmProvider>
    </div>
  );
};

export default Root;
