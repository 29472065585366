import FormInput from "../../../components/form/FormInput";

const CarDetails = ({
  car,
  editForm,
  carStatus,
  isEditing,
  isLoadingForm,
  isLoadingInvers,
  handleEditFormChange,
}) => {
  return (
    <>
      <div className='mb-2'>
        <span className='text-lg font-bold'>Car Details</span>
      </div>
      <div className='car-details'>
        <span>Plate</span>
        <span>{car.carPlate}</span>
        <span>Brand</span>
        <span>{car.brand}</span>
        <span>Model</span>
        <span>{car.model}</span>
        <span>Price</span>
        {isEditing ? (
          <div>
            <FormInput
              type='text'
              disabled={isLoadingForm}
              placeholder='Price per Minute'
              value={editForm.values.price}
              errors={editForm.touched.price && editForm.errors.price}
              handleChange={(e) => handleEditFormChange("price", e.target.value)}
            />
          </div>
        ) : (
          <span>€{car.price}/m</span>
        )}
        <span>Price per Hour</span>
        {isEditing ? (
          <div>
            <FormInput
              type='text'
              disabled={isLoadingForm}
              placeholder='Price per Hour'
              value={editForm.values.priceHourly}
              errors={editForm.touched.priceHourly && editForm.errors.priceHourly}
              handleChange={(e) => handleEditFormChange("priceHourly", e.target.value)}
            />
          </div>
        ) : (
          <span>€{car.priceHourly}/h</span>
        )}
        <span>Location</span>
        {!carStatus || isLoadingInvers ? (
          <div className='w-20 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
        ) : (
          <span>{carStatus?.address}</span>
        )}
        <span>Coordinates</span>
        {!carStatus || isLoadingInvers ? (
          <div className='w-20 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
        ) : (
          <span>
            {carStatus?.invers?.position.lat}, {carStatus?.invers?.position.lon}
          </span>
        )}
        <span>Updated:</span>
        <span>{car.updatedAt}</span>
      </div>
    </>
  );
};

export default CarDetails;
