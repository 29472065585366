import Parse from "parse";
import { useFormik } from "formik";
import { object, string } from "yup";
import User from "../../../models/User";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { findOption } from "../../../utils/Forms";
import Button from "../../../components/form/Button";
import FormRadio from "../../../components/form/FormRadio";
import FormInput from "../../../components/form/FormInput";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FormSelect from "../../../components/form/FormSelect";
import FormTextarea from "../../../components/form/FormTextarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import AddNewMessageTemplateModal from "../../../components/AddNewMessageTemplateModal";
import { userMessageTemplateSerializer, userSerializer } from "../../../models/serializers";

const SendUserMessagesModal = ({
  users,
  isOpen,
  isLoading,
  toggleModal,
  setSelection,
  handleSendMessages,
  handleRemoveRecipient,
}) => {
  const [templates, setTemplates] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [submitMsgesSuccess, setSubmitMsgesSucces] = useState(false);
  const [selectedTemplateLanguage, setSelectedTemplateLanguage] = useState("EL");
  const [isAddMessageTemplateModalOpen, toggleAddMessageTemplateModal] = useState(false);
  const [responseState, setResponseState] = useState({
    type: null,
    message: null,
  });

  const validationSchema = object().shape({
    type: string().required("Type is required"),
    title: string().required("Title is required"),
    message: string().required("Message is required"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      type: "INFO",
      title: "",
      message: "",
    },
  });

  const userMessageTypeOptions = Object.keys(User.MESSAGE_TYPES).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(t),
  }));

  const handleDelete = (value) => {
    if (
      window.confirm(
        `Are you sure you want to delete the template ${
          templateOptions.find((o) => o.value === value).label
        } ?`
      )
    ) {
      new Parse.Object("UserMessageTemplates")
        .set("objectId", value)
        .destroy({ useMasterKey: true })
        .then(() => {
          formik.resetForm();
          getMessagesTemplates();
          setSelectedTemplate(null);
        });
    }
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className='flex items-center justify-between cursor-pointer p-2 hover:bg-[rgba(var(--bg-select-option-hover))]'
      >
        <span>{data.label}</span>
        <FontAwesomeIcon
          icon={faTrashAlt}
          className='cursor-pointer text-text-main hover:text-red-500 transition-all ease-in-out duration-100'
          onClick={(e) => {
            e.stopPropagation(); // Prevents Select from opening
            handleDelete(data.value);
          }}
        />
      </div>
    );
  };

  const getUserData = (userId) => {
    return userSerializer(new Parse.Object("_User").set("objectId", userId));
  };

  const getMessagesTemplates = async () => {
    const MessageTemplates = await new Parse.Query("UserMessageTemplates")
      .equalTo("category", "IN_APP_MSG")
      .findAll({
        useMasterKey: true,
      });
    const serialized = MessageTemplates.map((t) => userMessageTemplateSerializer(t));
    setTemplates(serialized);
  };

  const setTemplate = async (template) => {
    setSelectedTemplate(template);
  };

  const templateLanguageChange = (value) => {
    setSelectedTemplateLanguage(value);
  };

  const handleSubmit = async () => {
    await handleSendMessages(users, formik.values).then((r) => {
      if (r) {
        setSubmitMsgesSucces(true);
        setResponseState({
          type: true,
          message: "Message has been sent successfully.",
        });
        setTimeout(() => {
          formik.resetForm();
          toggleModal(false);
          setSelectedTemplate(null);
          setSubmitMsgesSucces(false);
          setSelection && setSelection([]);
        }, 1500);
      } else {
        setSubmitMsgesSucces(false);
        setResponseState({
          type: false,
          message: "Something went wrong. Try again.",
        });
      }
    });
  };

  const handleCancel = () => {
    toggleModal(false);
    formik.resetForm();
    setSelectedTemplate(null);
    setSubmitMsgesSucces(false);
    setResponseState({ type: null, message: null });
  };

  useEffect(() => {
    getMessagesTemplates();
  }, []);

  useEffect(() => {
    const tmpl = templates?.find((t) => t.id === selectedTemplate?.value);

    if (selectedTemplateLanguage === "EN") {
      setTemplateOptions(templates.map((t) => ({ value: t.id, label: t.en.title })));
      formik.setValues({ type: tmpl?.type, title: tmpl?.en.title, message: tmpl?.en.message });
    } else {
      setTemplateOptions(templates.map((t) => ({ value: t.id, label: t.el.title })));
      formik.setValues({ type: tmpl?.type, title: tmpl?.el.title, message: tmpl?.el.message });
    }
  }, [templates, selectedTemplate, selectedTemplateLanguage]);

  return (
    <>
      <Modal
        open={isOpen}
        title='Send user messages'
        classes={"w-4/5 lg:w-1/2 2xl:w-1/3"}
        handleToggle={() => toggleModal(!isOpen)}
      >
        <div>
          {!!users && (
            <div className='mb-4'>
              <div className='mb-1'>
                <span className='text-text-main'>Recepients:</span>
              </div>
              <div className='flex flex-wrap items-center gap-1 mb-1 p-1 max-h-20 overflow-x-hidden overflow-y-scroll bg-bg-input border border-solid border-border-input rounded'>
                {users.map((id) => {
                  const user = getUserData(id);
                  return (
                    <div
                      key={id}
                      className='flex items-center rounded-full bg-gray-100 bg-opacity-70 px-2 py-1 transition-all ease-in-out duration-200 hover:bg-blue-100 hover:bg-opacity-50'
                    >
                      <span className='text-sm'>{user.username}</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={`ml-1 w-[10px] h-[10px] ${
                          handleRemoveRecipient ? "cursor-pointer" : "cursor-not-allowed"
                        } p-1 rounded-full hover:bg-white`}
                        onClick={() => (handleRemoveRecipient ? handleRemoveRecipient(id) : null)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className='mb-1 relative'>
            <FormSelect
              label='Template'
              options={templateOptions}
              handleChange={setTemplate}
              components={{ Option: CustomOption }}
              value={
                !Utils.isNull(selectedTemplate)
                  ? findOption(templateOptions, selectedTemplate.value)
                  : null
              }
            />
            <div className='absolute left-20 top-0 flex items-center gap-2'>
              <FormRadio
                label='EL'
                value='EL'
                name='language'
                handleChange={templateLanguageChange}
                checked={selectedTemplateLanguage === "EL"}
              />
              <FormRadio
                label='EN'
                value='EN'
                name='language'
                handleChange={templateLanguageChange}
                checked={selectedTemplateLanguage === "EN"}
              />
            </div>
            <div className='absolute right-0 top-0'>
              <div
                className='flex items-center gap-1 text-text-main cursor-pointer'
                onClick={() => toggleAddMessageTemplateModal(true)}
              >
                <FontAwesomeIcon icon={faSquarePlus} />
                <span className='text-sm sm:text-base'>Add Template</span>
              </div>
            </div>
          </div>
          <div className='mb-2'>
            <FormSelect
              label='Type'
              options={userMessageTypeOptions}
              errors={formik.touched.type && formik.errors.type}
              handleChange={(value) => {
                formik.setFieldTouched("type");
                formik.setFieldValue("type", value.value);
              }}
              value={
                !Utils.isNull(formik.values.type)
                  ? findOption(userMessageTypeOptions, formik.values.type)
                  : null
              }
            />
          </div>
          <div className='mb-2'>
            <FormInput
              type='text'
              label='Title'
              value={formik.values.title}
              placeholder='Title'
              errors={formik.touched.title && formik.errors.title}
              handleChange={(e) => {
                formik.setFieldTouched("title");
                formik.setFieldValue("title", e.target.value);
              }}
            />
          </div>
          <div className='mb-2'>
            <FormTextarea
              rows={4}
              label='Message'
              value={formik.values.message}
              errors={formik.touched.message && formik.errors.message}
              placeholder='Type the message to be sent to the users here..'
              handleChange={(value) => {
                formik.setFieldTouched("message");
                formik.setFieldValue("message", value);
              }}
            />
          </div>
          <div className='grid grid-cols-2 gap-2 mt-4'>
            <Button classes='bg-gray-200 py-2' disabled={isLoading} handleClick={handleCancel}>
              <span>Cancel</span>
            </Button>
            <Button
              isLoading={isLoading}
              handleClick={handleSubmit}
              classes={`bg-blue-500 py-2`}
              success={submitMsgesSuccess}
              disabled={isLoading || submitMsgesSuccess}
            >
              <span className='text-white'>Send</span>
            </Button>
          </div>
          {!Utils.isNull(responseState.type) && (
            <div className='mt-1'>
              <span
                className={`text-sm font-bold ${
                  responseState.type ? "text-green-400" : "text-red-400"
                }`}
              >
                {responseState.message}
              </span>
            </div>
          )}
        </div>
      </Modal>
      <AddNewMessageTemplateModal
        isOpen={isAddMessageTemplateModalOpen}
        toggleModal={toggleAddMessageTemplateModal}
        emitTemplate={(tmpl) => {
          const tmplS = userMessageTemplateSerializer(tmpl);
          setTemplates((prev) => [...prev, tmplS]);
        }}
      />
    </>
  );
};

export default SendUserMessagesModal;
