import Utils from "../../../utils/Utils";
import Invoice from "../../../models/Invoice";

const Status = ({ status }) => {
  const getColor = () => {
    switch (status) {
      case Invoice.STATUS.PENDING:
        return "bg-blue-500";
      case Invoice.STATUS.FAILED:
        return "bg-red-500";
      case Invoice.STATUS.CANCELLED:
        return "bg-orange-500";
      case Invoice.STATUS.PAID:
        return "bg-green-500";
      case Invoice.STATUS.UNCOLLECTIBLE:
        return "bg-gray-500";
      default:
        return "bg-cyan-500";
    }
  };

  return (
    <>
      <div className='block'>
        <span
          className={`rounded text-white font-bold px-1 py-0.5 sm:px-1.5 sm:py-1 ${getColor()} flex items-center gap-0.5 w-max mx-auto`}
        >
          {Utils.textFirstOnlyUpper(status)}
        </span>
      </div>
    </>
  );
};

export default Status;
